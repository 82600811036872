import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import _ from 'lodash'

import { cardLoadingPlaceholder } from '../utils'

export default function ImageWithPlaceholder({ alt, ...props }) {
  const [isFetching, setIsFetching] = React.useState(true)
  const [isError, setIsError] = React.useState(false)

  function handleLoad(e) {
    setIsFetching(false)
  }

  function handleLoadError() {
    setIsError(true)
    setIsFetching(false)
  }

  React.useEffect(() => {
    if (_.isUndefined(props.src)) {
      handleLoadError()
    }
  }, [props.src])

  return (
    <ImagePlaceholderStyle>
      <ImageBackgroundStyle>
        <LazyLoadImage
          {...props}
          alt={alt}
          afterLoad={handleLoad}
          onError={handleLoadError}
          effect="opacity"
        />
      </ImageBackgroundStyle>
      {isFetching && (
        <div className="__image-placeholder">
          <div className="__animated-background" />
        </div>
      )}
      {isError && <ImageDefaultStyle />}
    </ImagePlaceholderStyle>
  )
}

const ImagePlaceholderStyle = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 10;
  ${cardLoadingPlaceholder}
`

const ImageBackgroundStyle = styled.div`
  /* background: red; */
  height: 100%;
  width: 100%;
  span {
    height: 100%;
    width: 100%;
  }
  img {
    height: 100%;
    width: 100%;
  }
`

const ImageDefaultStyle = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #eee;
`
