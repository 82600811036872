import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  getPortalList,
  getPortalListNearByWithOutRange,
  getPortalListNearBy
} from "../services/elasticService";
import CardWithPlaceholder from "../views/elements/CardWithPlaceholder";
import Meta from "../views/widgets/Meta";
import PortalListCard from "../templates/portal-list/PortalListCard";
import PortalSearchPanel from "../templates/portal-search/PortalSearchPanel";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import logger from "../services/logger";
import { tranformToImageURL } from "../services/remoteUtils";
import { translate } from "../services/lang";
import { getParams } from "../modules/utils";
import { toast } from "react-toastify";
import useDebounce from "../hooks/useDebounce";

export const LOCATION_STATUS = {
  PENDING: "PENDING",
  COMPLATED: "COMPALTED",
  ERROR: "ERROR"
};

export default function PortalListNearByPage({ match, location, ...props }) {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [range, setRange] = React.useState(10);
  const [portals, setPortals] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  const [coords, setCoords] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [startAt, setStartAt] = React.useState(0);
  const [isFetching, setIsFetching, isMore, setIsMore] = useInfiniteScroll(
    fethMore
  );
  const debouncedSearchTerm = useDebounce(range, 1000);

  React.useEffect(() => {
    setPortals([]);
    setIsMore(true);
    setIsFetching(true);
    setPage(0);
    setStartAt(0);
    window.scroll(0, 0);
  }, [language, debouncedSearchTerm]);

  function fethMore() {
    logger.debug("fetch more...");
    const limit = page === 0 ? 11 : 8;
    getLocationNow().then(coords => {
      setCoords(coords);
      getPortalList(
        {
          location: {
            coords: coords,
            range: range == 101 ? null : `${range}km`
          }
        },
        { startAt, limit }
      ).then(_portals => {
        if (!_.isEmpty(_portals)) {
          const portalMerged = [...portals, ..._portals];
          setPage(page + 1);
          setPortals(tranformToImageURL(translate(portalMerged, language)));
          setStartAt(_.size(portalMerged));
        } else setIsMore(false);
        setIsFetching(false);
      });
    });
  }

  let renderPlaceholder;

  if (isFetching && isMore) {
    renderPlaceholder = _.isEmpty(portals) ? (
      <>
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
      </>
    ) : (
      <CardWithPlaceholder />
    );
  }

  return (
    <div className="container" style={{ marginTop: "4rem" }}>
      <Meta
        title={"Glog"}
        imageURL={`/__images/logo.png`}
        url={window.location.href}
      />
      <div className="is-centered">
        <SliderLayOut>
          <div className="title">
            {range <= 100
              ? `${t("field.location.distance.label")}: ${range} ${t(
                  "unit.kilometers.label"
                )} `
              : `${t("field.location.searchAll.label")}`}
          </div>
          <input
            type="range"
            min="0"
            max="101"
            step="1"
            className="slider"
            value={range}
            onChange={e => setRange(e.target.value)}
          />
        </SliderLayOut>
        <div className="title">สถานที่ใกล้เคียง</div>
        <hr />
        <ListingLayout>
          {_.map(portals, portal => (
            <React.Fragment key={portal._id}>
              <PortalListCard portal={portal} coords={coords} />
              <br />
            </React.Fragment>
          ))}

          {renderPlaceholder}
        </ListingLayout>
      </div>
    </div>
  );
}

async function getLocationNow() {
  try {
    const location = await getLocation();
    const latLng = getLatLng(location);
    return { lat: latLng.latitude, lon: latLng.longitude };
  } catch (error) {
    toast.error(LOCATION_STATUS.ERROR);
  }
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          resolve(location);
        },
        error => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

const getLatLng = location => {
  return _.get(location, "coords");
};

const ListingLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SliderLayOut = styled.div`
  padding-bottom: 2em;

  .slidecontainer {
    width: 100%;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #0f8fff;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #0f8fff;
    cursor: pointer;
  }
`;
