import React from 'react'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'

import PortalContactPhoneFloatButton from '../portal-contacts/PortalContactPhoneFloatButton'
import EditorCoverInfoBox from '../editor-modal/EditorCoverInfoBox'
import EditorCoverImageBox from '../editor-modal/EditorCoverImageBox'
import EditorThumbnailBox from '../editor-modal/EditorThumbnailBox'
import EditorWithAuthHandler from '../editor/EditorWithAuthHandler'
import EditorPortalActionBox from '../editor-modal/EditorPortalActionBox'

export default function PortalHeaderImage(props) {
  const {
    imageURL,
    title,
    shortDescription,
    highlightSentence,
    contacts,
  } = props
  return (
    <HeaderImageStyle imageURL={imageURL}>
      <TextOverlayStyle>
        <EditorWithAuthHandler editorComponent={EditorCoverInfoBox}>
          <div className="title is-1 has-text-white-ter has-text-centered">
            {title}
          </div>
          <div className="subtitle is-4 has-text-centered has-text-white-ter">
            {shortDescription}
          </div>
          <br />
          <div className="title is-3 has-text-centered has-text-white-ter">
            {highlightSentence}
          </div>
        </EditorWithAuthHandler>
      </TextOverlayStyle>

      <ImageBackgroundStyle>
        <LazyLoadImage
          src={imageURL}
          effect="blur"
          alt={title}
          threshold={1000}
        />
      </ImageBackgroundStyle>
      <BackgroundOverlay />
      <PortalContactPhoneFloatButton
        phoneContact={_.get(contacts, 'phone')}
        isHavePageID={_.get(contacts, 'fbPageId')}
      />
      <EditorWithAuthHandler editorComponent={EditorThumbnailBox} />
      <EditorWithAuthHandler editorComponent={EditorCoverImageBox} />
      <EditorWithAuthHandler editorComponent={EditorPortalActionBox} />
    </HeaderImageStyle>
  )
}

const BackgroundOverlay = styled.div.attrs({
  className: '__background-overlay',
})`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: 2;
`

const TextOverlayStyle = styled.div.attrs({ className: '__text-overlay' })`
  width: 90%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`

const ImageBackgroundStyle = styled.div.attrs({
  className: '__image-background',
})`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100vw;

  img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
`

const HeaderImageStyle = styled.div.attrs({ className: '__header-image' })`
  @media only screen and (max-width: 768px) {
    height: 100vh;
    width: 100vw;
    /* background-image: url(${props => `${props.imageURL}?hrs=true`}); */
    background-position: center;
    background-size: cover;
  }

  @media only screen and (min-width: 768px) {
    /* background-image: url(${props => `${props.imageURL}?hrs=true`}); */
    width: 100vw;
    /* height: 66.67vh; */
    height: 100vh;
    background-size: cover;
    background-position: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title,
  .subtitle {
    text-shadow: 0px 2px 15px #0a0a0a;
  }
`
