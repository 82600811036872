var isDisabled = false

export default {
  setDisable(value) {
    isDisabled = value
  },
  debug() {
    if (!isDisabled) {
      console.log(...arguments)
    }
  },
  info() {
    if (!isDisabled) {
      console.info(...arguments)
    }
  },
}
