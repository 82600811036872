import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';

export default function ListingListTagGroup({
  tags,
  listing,
  language = 'th',
}) {
  return (
    <ListingListTagGroupLayout>
      {_.map(tags, (tag, key) => (
        <Link
          key={key}
          className="button"
          style={{ marginLeft: 6, marginBottom: 6 }}
          to={`/portals?tags=${window.encodeURIComponent(
            tag,
          )}&listing.title=${_.get(listing, `title.${language}`)}&listing.id=${
            listing.id
          }`}
        >
          {tag}
        </Link>
      ))}
    </ListingListTagGroupLayout>
  );
}

const ListingListTagGroupLayout = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding-top: 6px;
  width: 100vw;
  overflow-x: scroll;
`;
