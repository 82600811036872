import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import PortalContext from '../../contexts/PortalContext'
import { deletePortal } from '../../services/remoteService'

export default function EditorPortalActionBox({ children, params }) {
  const { t } = useTranslation()
  const { portal } = React.useContext(PortalContext)
  const [isRedirect, setIsRedirect] = React.useState(false)

  function handleDeletePortal() {
    if (
      window.confirm(
        t('actions.confirm.delete.label', {
          name: 'Glog',
          defaultValue: 'Are you sure delete {{name}}?',
        }),
      )
    )
      deletePortal(portal)
        .then(() => {
          window.alert(
            t('actions.delete.completed.label', {
              defaultValue: 'Delete Completed',
            }),
          )
          setIsRedirect(true)
        })
        .catch(error => window.alert(error.message))
  }

  if (isRedirect) {
    return <Redirect to="/creator" />
  }

  return (
    <EditorPortalActionStyle>
      <div
        className="button is-danger is-outlined"
        onClick={handleDeletePortal}
      >
        <Trans
          i18nKey="actions.delete.label"
          values={{ name: 'Glog' }}
          defaults="Delete {{name}}"
        />
      </div>
    </EditorPortalActionStyle>
  )
}

const EditorPortalActionStyle = styled.div.attrs({
  className: '__editor-cover-image-box __has-navbar',
})`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 20;

  .button {
    min-width: 100px;
  }
`
