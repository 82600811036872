import i18next from 'i18next'

export function getSuggestStatus(currentLength, suggestMinLength) {
  if (currentLength < suggestMinLength && currentLength > 0) {
    return 'warning'
  }
  if (currentLength >= suggestMinLength) {
    return 'success'
  }
}

export function getSuggestStatusText(status, currentLength, suggestMinLength) {
  const letterText = i18next.t('default.characters.label', {
    defaultValue: 'characters',
  })

  const charAtLeast = i18next.t('default.charAtLeast.label', {
    suggestMinLength,
    defaultValue: 'prefer at least {{suggestMinLength}} characters.',
  })

  const charMoreThan = i18next.t('default.charMoreThan.label', {
    suggestMinLength,
    defaultValue: 'prefer more than {{suggestMinLength}} characters',
  })

  return status === 'warning'
    ? `${currentLength} ${letterText} (${charMoreThan})`
    : `${currentLength} ${letterText} (${charAtLeast})`
}
