import _ from 'lodash';

export function getIconLink(key, contact, language = 'th') {
  const iconName = getIconName(key);
  const contacts = getContacts(key, contact, language);
  return { iconName, contacts };
}

function getContacts(key, contact, language) {
  const contacts = [];
  if (key === 'phone') {
    return getPhoneContacts(contact);
  } else {
    const name = getContactName(key, contact, language);
    if (!_.isEmpty(name)) {
      contacts.push({
        name,
        url: getContactURL(key, contact, language),
      });
    }
  }
  return contacts;
}

function getPhoneContacts(contact) {
  return _.chain(contact)
    .trim()
    .split(',')
    .reject(_.isEmpty)
    .map(_contact => {
      return {
        name: _contact,
        url: getContactURL('phone', _contact),
      };
    })
    .value();
}

function getContactName(key, contact, language = 'th') {
  if (key === 'location') {
    contact = _.get(contact, `streetAddress`);
  }
  return !_.isEmpty(contact) ? contact.trim() : '';
}

function getContactURL(key, contact, language = 'th') {
  switch (key) {
    case 'email':
      return `mailto:${contact}`;
    case 'phone':
      return `tel:${contact}`;
    case 'fanpage':
      return `https://www.facebook.com/${contact}`;
    case 'instragramId':
      return `https://www.instagram.com/${contact}`;
    case 'lineId':
      return `http://line.me/ti/p/~${contact}`;
    case 'webpage':
      return `${contact}`;
    case 'others':
      return ``;
    case 'location':
      return `https://www.google.com/maps/search/?api=1&query=${
        contact.latitude
      },${contact.longitude}`;
    default:
      break;
  }
}

function getIconName(iconName) {
  switch (iconName) {
    case 'email':
      return 'fas fa-envelope';
    case 'phone':
      return 'fas fa-phone';
    case 'fanpage':
      return 'fab fa-facebook-square';
    case 'instragramId':
      return 'fab fa-instagram';
    case 'lineId':
      return 'fab fa-line';
    case 'webpage':
      return 'fas fa-globe-asia';
    case 'others':
      return 'fas fa-ellipsis-h';
    case 'location':
      return 'fas fa-map-marker-alt';
    default:
      break;
  }
}
