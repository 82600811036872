import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import { getDefaultLangText } from '../../constants/utils'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'
import InputTags from '../../views/widgets/InputTags'

export function handleFullSocialLink(link) {
  return _.chain(link)
    .split('.com/')
    .last()
    .value()
}

export default function EditorContactBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  return (
    <EditorContactBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('actions.edit.label', {
              title: t('app.contacts.label', {
                defaultValue: 'Contacts',
              }),
              defaultValue: 'Edit {{title}}',
            }),
          },
          modalRef: 'EDITOR_CONTACT_MODAL',
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorContactBoxStyle>
  )
}

const EditorContactBoxStyle = styled.div.attrs({
  className: '__editor-box __highlight-editor-box __editor-contact-box',
})``

export function EditorContactModal({ onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, errors, setFieldValue, handleChange, handleSubmit } = useForm(
    {
      initialState: {
        _id: portal._id,
        contacts: {
          email: '',
          phone: '',
          fanpage: '',
          fbPageId: '',
          instragramId: '',
          lineId: '',
          webpage: '',
          others: getDefaultLangText(),
          ..._.get(portal, 'contacts'),
        },
      },
      onValidate: () => {
        const errors = {}
        return errors
      },
      onSubmit: values => {
        updatePortal(values)
          .then(portal => {
            updateLocalData(portal)
            handleClose()
          })
          .catch(error => window.alert(error.message))
      },
      reinitialState: true,
    },
  )

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <Field label={t('field.contacts.email.label', {defaultValue: "Email"})}>
          <InputText
            name={`contacts.email`}
            type="email"
            placeholder={t('field.contacts.email.placeholder', {defaultValue: "Enter your email."})}
            onChange={handleChange}
            value={values.contacts.email}
            error={errors.email && errors.email[language]}
          />
        </Field>
        <Field label={t('field.contacts.phone.label', {defaultValue: "Phone"})}>
          {/* <InputText
            name={`contacts.phone`}
            placeholder={t('field.contacts.phone.placeholder', {defaultValue: "Enter your phone."})}
            onChange={handleChange}
            value={values.contacts.phone}
          /> */}
          <InputTags
            textAction={t('actions.add.label', {defaultValue: "Add"})}
            name={`contacts.phone`}
            placeholder={t('field.contacts.phone.placeholder', {defaultValue: "Enter your phone and click 'add'"})}
            onChange={value => {
              setFieldValue('contacts.phone', value.join(','))
            }}
            values={values.contacts.phone.split(',')}
            error={errors.tags && errors.tags[language]}
          />
        </Field>
        <Field label={t('field.contacts.fanpage.label', {defaultValue: ""})}>
          <div className="field has-addons">
            <div className="control">
              <div className="button is-static">https://www.facebook.com/</div>
            </div>
            <InputText
              name={`contacts.fanpage`}
              placeholder={t('field.contacts.fanpage.placeholder', {defaultValue: "Enter your fanpage."})}
              onChange={e => {
                const value = handleFullSocialLink(e.target.value)
                setFieldValue('contacts.fanpage', value)
              }}
              value={values.contacts.fanpage}
            />
          </div>
        </Field>
        <Field label={t('field.contacts.fbPageId.label', {defaultValue: "FB Page ID"})}>
          <InputText
            name={`contacts.fbPageId`}
            placeholder={t('field.contacts.fbPageId.placeholder', {defaultValue: "Enter your facebook page id."})}
            onChange={handleChange}
            value={values.contacts.fbPageId}
          />
        </Field>
        <Field label={t('field.contacts.instragramId.label', {defaultValue: "Instragram ID"})}>
          <div className="field has-addons">
            <div className="control">
              <div className="button is-static">https://www.instagram.com/</div>
            </div>
            <InputText
              name={`contacts.instragramId`}
              placeholder={t('field.contacts.instragramId.placeholder', {defaultValue: "Enter your Instragram ID"})}
              onChange={e => {
                const value = handleFullSocialLink(e.target.value)
                setFieldValue('contacts.instragramId', value)
              }}
              value={values.contacts.instragramId}
            />
          </div>
        </Field>
        <Field label={t('field.contacts.lineId.label', {defaultValue: "Line ID"})}>
          <InputText
            name={`contacts.lineId`}
            placeholder={t('field.contacts.lineId.placeholder', {defaultValue: "Enter your line id."})}
            onChange={handleChange}
            value={values.contacts.lineId}
          />
        </Field>
        <Field label={t('field.contacts.webpage.label', {defaultValue: "Web Page"})}>
          <InputText
            name={`contacts.webpage`}
            placeholder={t('field.contacts.webpage.placeholder', {defaultValue: "Enter your web page."})}
            onChange={handleChange}
            value={values.contacts.webpage}
          />
        </Field>
        <Field label={t('field.contacts.others.label', {defaultValue: "Others"})}>
          <InputText
            name={`contacts.others.${language}`}
            placeholder={t('field.contacts.others.placeholder', {defaultValue: "Enter your others."})}
            onChange={handleChange}
            value={values.contacts.others[language]}
          />
        </Field>
      </section>
      <FooterConfirmModal onClose={handleClose} onSubmit={handleSubmit} />
    </>
  )
}
