import React from 'react'
import styled from 'styled-components'
import { cardMediaReponsive, cardLoadingPlaceholder } from '../utils'

export default function CardWithPlaceholder({
  mediaResponsive = cardMediaReponsive,
}) {
  return (
    <CardWithPlaceholderStyle mediaResponsive={mediaResponsive}>
      <div className="card-image">
        <figure className="image is-square">
          <div className="__image-placeholder">
            <div className="__animated-background" />
          </div>
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <div className="__tags-placeholder">
              <div className="__animated-background" />
            </div>
            <div className="__title-placeholder">
              <div className="__animated-background" />
            </div>
            <div className="__subtitle-placeholder">
              <div className="__animated-background" />
            </div>
            <div className="__subtitle-placeholder">
              <div className="__animated-background" />
            </div>
          </div>
        </div>
      </div>
    </CardWithPlaceholderStyle>
  )
}

const CardWithPlaceholderStyle = styled.div.attrs({})`
  ${props => props.mediaResponsive}
  ${cardLoadingPlaceholder}
`
