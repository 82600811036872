import React from 'react'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { useTranslation, Trans } from 'react-i18next'
import { fetchPortals } from '../../services/remoteService'
import Loading from '../../views/elements/Loading'
import FormDataContext from '../../contexts/FormDataContext'

import usePromise from 'react-use-promise'
import PortalListCardEditor from '../../templates/portal-list/PortalListCardEditor'
import { getHashByStepIndex } from './WizardPage'
import { translate } from '../../services/lang'
import { stepContentPagePropTypes } from '../../constants/prop-types'
import { tranformToImageURL } from '../../services/remoteUtils'
import config from '../../config'
import InfoContext from '../../contexts/InfoContext'

export default function MainListPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { isContactWithMe } = React.useContext(InfoContext)
  const { clearData } = React.useContext(FormDataContext)
  const [items, , state] = usePromise(
    () =>
      fetchPortals().then(portals =>
        tranformToImageURL(translate(portals, language)),
      ),
    [],
  )

  React.useEffect(() => {
    clearData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isContactWithMe) {
    return <Redirect to="/contacts" />
  } else if (
    !isContactWithMe &&
    !_.includes(config.adminTowns, config.providerContent)
  ) {
    window.location.href = 'https://glog.allly.co/creator'
    return null
  }

  if (!_.includes(config.supportedLanguages, language)) {
    return <Redirect to="/creator/choose-lang" replace />
  }

  if (state === 'pending') {
    return <Loading />
  }

  return (
    <MainListPageStyle className="__has-navbar">
      <div className="title">
        <Trans
          i18nKey="list.title.label"
          values={{ title: t('app.title.label', { defaultValue: 'Glog' }) }}
          defaults="{{title}} List"
        />
      </div>
      <div className="__portal-list">
        <PortalListCardEditor linkTo={`/creator/wizard#introduction`} />
        {_.map(items, item => (
          <React.Fragment key={item._id}>
            <PortalListCardEditor
              portal={item}
              linkTo={{
                pathname: item.stepIndex
                  ? `/creator/wizard`
                  : `/portals/${item._id}`,
                hash: item.stepIndex
                  ? `#${getHashByStepIndex(item.stepIndex)}`
                  : '',
                state: { id: item._id },
              }}
              isActionPanel
            />
          </React.Fragment>
        ))}
      </div>
    </MainListPageStyle>
  )
}

MainListPage.propTypes = {
  ...stepContentPagePropTypes,
}

const MainListPageStyle = styled.div`
  .__portal-list {
    display: flex;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 768px) {
    padding: 8px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 36px;
  }

  @media only screen and (min-width: 1023px) {
    padding: 36px;
  }
`
