import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as TYPE from "../../constants/typeAction";
import * as COLOR from "../../constants/colorAction";
import useForm from "../../hooks/useForm";
import Field from "../../views/widgets/Field";
import InputText from "../../views/widgets/InputText";
import { getDefaultLangText } from "../../constants/utils";
import ConfirmModalContext from "../../contexts/ConfirmModalContext";
import PortalContext from "../../contexts/PortalContext";
import { FooterConfirmModal } from "../../hooks/useConfirmModal";
import { updatePortal } from "../../services/remoteService";
import InputTags from "../../views/widgets/InputTags";
import SelectItem from "../../views/elements/SelectItem";

export function handleFullSocialLink(link) {
  return _.chain(link)
    .split(".com/")
    .last()
    .value();
}

export default function EditorActionBox({ children }) {
  const { t } = useTranslation();
  const { showModal } = React.useContext(ConfirmModalContext);

  return (
    <EditorContactBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t("app.action.label", {
              defaultValue: "Action"
            })
          },
          modalRef: "EDITOR_ACTION_MODAL"
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorContactBoxStyle>
  );
}

const EditorContactBoxStyle = styled.div.attrs({
  className: "__editor-box __highlight-editor-box __editor-contact-box"
})`
 @media only screen and (max-width: 1500px) {
  margin-top: 8em;
 }
`;

export function EditorActionModal({ onClose }) {
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const { portal, updateLocalData } = React.useContext(PortalContext);
  const [types, setTypes] = React.useState(TYPE.typeActionOptions);
  const [colors, setColors] = React.useState(COLOR.colorActionOptions);
  const { values, errors, setFieldValue, handleChange, handleSubmit } = useForm(
    {
      initialState: {
        _id: portal._id,
        action: {
          type: _.get(portal, "action.type")
            ? _.get(portal, "action.type")
            : "none",
          contact: {
            phone: "",
            externalUrl: "",
            linePersonal: "",
            lineAds: "",
            messengerPersonal: "",
            messengerPage: "",
            ..._.get(portal, "action.contact")
          },
          text: _.get(portal, "action.text")
            ? _.get(portal, "action.text")
            : getDefaultLangText(),
          color: {
            background: "",
            text: "",
            ..._.get(portal, "action.color")
          }
        }
      },
      onValidate: ({ values }) => {
        const errors = {};
        if (
          !_.isEqual(values.action.type, "none") &&
          _.isEmpty(values.action.contact[values.action.type])
        ) {
          errors.contact = "require";
        }

        if (_.isEmpty(values.action.text[language])) {
          errors.text = "require";
        }
        return errors;
      },
      onSubmit: values => {
        updatePortal(values)
          .then(portal => {
            updateLocalData(portal);
            handleClose();
          })
          .catch(error => window.alert(error.message));
      },
      reinitialState: true
    }
  );

  function handleClose() {
    if (onClose) {
      onClose(null);
    }
  }
  return (
    <>
      <section className="modal-card-body">
        <Field label={t("action.type.label")}>
          <SelectItem
            name="action.type"
            onChange={e => setFieldValue("action.type", e.target.value)}
            value={values.action.type}
            options={types}
          />
        </Field>
        {values.action.type !== "none" && (
          <React.Fragment>
            <Field label={t(`action.` + `${values.action.type}.` + `label`)}>
              <InputText
                name={`action.contact[${values.action.type}]`}
                placeholder={t(
                  "action." + `${values.action.type}.` + `placeholder`
                )}
                onChange={e => {
                  setFieldValue(
                    `action.contact[${values.action.type}]`,
                    e.target.value
                  );
                }}
                value={values.action.contact[values.action.type]}
                error={errors.contact}
              />
            </Field>
            <Field label={t(`action.text.label`) + `[${language}]`}>
              <InputText
                name={`action.text.${language}`}
                placeholder={t("action.text.placeholder")}
                onChange={e => {
                  setFieldValue(
                    `action.text[${values.action.type}]`,
                    e.target.value
                  );
                  values.action.text[language] = e.target.value;
                  // setOnActionChange(values.action);
                }}
                value={values.action.text[language]}
                error={errors.text}
              />
            </Field>
            <Field label={t(`action.color.label`)}>
              <div className="select">
                <select
                  style={{
                    color: values.action.color.text,
                    background: values.action.color.background
                  }}
                  onChange={e => {
                    const result = _.trim(e.target.value, "_");
                    setFieldValue("action.color.background", result[1]);
                    setFieldValue("action.color.text", result[0]);
                  }}
                  onBlur={e => {
                    const result = _.split(e.target.value, "_");
                    setFieldValue("action.color.background", result[1]);
                    setFieldValue("action.color.text", result[0]);
                    // setOnActionChange(action);
                  }}
                >
                  {_.map(colors, (color, index) => (
                    <option
                      key={index}
                      value={
                        `${color.value.text}` +
                        "_" +
                        `${color.value.background}`
                      }
                      style={{
                        color: color.value.text,
                        background: color.value.background
                      }}
                    >
                      {`Text Color: ${_.capitalize(
                        color.value.text
                      )} / BackGround Color: ${_.capitalize(color.text)}`}
                    </option>
                  ))}
                </select>
              </div>
            </Field>
          </React.Fragment>
        )}
      </section>
      <FooterConfirmModal onClose={handleClose} onSubmit={handleSubmit} />
    </>
  );
}
