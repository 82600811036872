import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import { getDefaultLangText } from '../../constants/utils'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'
import InputTextarea from '../../views/widgets/InputTextarea'

export default function EditorOpeningHoursBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  return (
    <EditorOpeningHoursBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('app.information.label', {
              defaultValue: 'Edit Information',
            }),
          },
          modalRef: 'EDITOR_OPENING_HOURS_MODAL',
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorOpeningHoursBoxStyle>
  )
}

const EditorOpeningHoursBoxStyle = styled.div.attrs({
  className: '__editor-box __editor-opening-hours-box __highlight-editor-box',
})``

export function EditorOpeningHoursModal({ onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, handleChange, handleSubmit } = useForm({
    initialState: {
      openingHours: getDefaultLangText(),
      ..._.pick(portal, ['_id', 'openingHours']),
    },
    onValidate: () => {
      const errors = {}
      return errors
    },
    onSubmit: values => {
      updatePortal(values)
        .then(portal => {
          updateLocalData(portal)
          handleClose()
        })
        .catch(error => window.alert(error.message))
    },
    reinitialState: true,
  })

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <Field label={`${t('field.openingHours.label', {defaultValue: "Opening Hours"})} [${language}]`}>
          <InputTextarea
            name={`openingHours.${language}`}
            placeholder={t('field.openingHours.placeholder', { defaultValue: "Enter your opening hours."})}
            onChange={handleChange}
            value={values.openingHours[language]}
          />
        </Field>
      </section>
      <FooterConfirmModal onSubmit={handleSubmit} onClose={handleClose} />
    </>
  )
}
