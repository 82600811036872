import React from "react";
import _ from "lodash";
import styled from "styled-components";

import { getIconLink } from "./contactUtils";
import Truncate from "../../views/widgets/Truncate";
import PortalContactMessengerFloatButton from "./PortalContactMessengerFloatButton";
import { Trans } from "react-i18next";

const CONTACT_KEYS = [
  "email",
  "phone",
  "fanpage",
  "instragramId",
  "lineId",
  "webpage",
  "location",
  "others"
];

export default function PortalContacts({ contacts }) {
  const fbPageId = _.get(contacts, "fbPageId");

  if (
    _.chain(contacts)
      .omit(["fbPageId"])
      .reduce((prev, v, k) => {
        return (
          (k === "location" ? _.isEmpty(v && v.streetAddress) : _.isEmpty(v)) &&
          prev
        );
      }, true)
      .value()
  ) {
    return (
      <>
        <div className="title is-4">
          <Trans i18nKey="app.contacts.label" defaults="Contacts" />
        </div>
        <span className="__content">
          <Trans
            i18nKey="actions.noInformation.label"
            defaults="No Information"
          />
        </span>
      </>
    );
  }

  return (
    <>
      <div className="">
        <PortalContactMessengerFloatButton fbPageId={fbPageId} />
        <div className="title is-4">
          <Trans i18nKey="app.contacts.label" defaults="Contacts" />
        </div>
        <div>
          {_.map(CONTACT_KEYS, contactKey => {
            const contact = _.get(contacts, contactKey);
            const iconLink = getIconLink(contactKey, contact);
            if (_.isEmpty(iconLink.contacts)) {
              return null;
            }

            return (
              <ContactRowLayout key={contactKey}>
                <div>
                  <span className="icon is-meduim">
                    <i className={`${iconLink.iconName} fa-lg`} />
                  </span>
                </div>
                <div className="row-contact-text">
                  {_.map(iconLink.contacts, (contact, index) => {
                    return (
                      <div key={index}>
                        <ContactItem
                          key={contact.name}
                          contactKey={contactKey}
                          contact={contact}
                        />
                      </div>
                    );
                  })}
                </div>
              </ContactRowLayout>
            );
          })}
        </div>
      </div>
    </>
  );
}

function ContactItem({ contactKey, contact: { name, url } }) {
  if (_.includes(["location", "others"], contactKey)) {
    return (
      <ContactLink
        href={window.decodeURIComponent(url)}
        rel="nofollow"
        target="_blank"
      >
        {window.decodeURIComponent(name)}
      </ContactLink>
    );
  }

  return (
    <Truncate size={1} key={name}>
      <ContactLink
        href={window.decodeURIComponent(url)}
        rel="nofollow"
        target="_blank"
      >
        {window.decodeURIComponent(name)}
      </ContactLink>
    </Truncate>
  );
}

const ContactLink = styled.a`
  color: #337ab8;

  :hover {
    color: #337ab8;
    text-decoration: underline;
  }

  :focus {
    text-decoration: underline;
  }
  @media only screen and (max-width: 768px) {
    color: #337ab8;
    :hover {
      color: lightgray;
      text-decoration: none;
    }
    :focus {
      color: lightgray;
    }
  }
`;
const ContactRowLayout = styled.div`
  display: flex;
  .row-contact-text {
    width: 80%;
    padding-left: 8px;
  }
`;
