import React from 'react'
import classNames from 'classnames'

const InputTextarea = ({ error, status, statusMessage, ...props }) => (
  <div className="control">
    <textarea
      className={classNames('textarea', {
        'is-danger': error,
        'is-success': status === 'success',
        'is-warning': status === 'warning',
      })}
      type="textarea"
      {...props}
    />
    {statusMessage && (
      <p
        className={classNames('help has-text-right', {
          'has-text-weight-bold': status === 'warning',
        })}
      >
        {statusMessage}
      </p>
    )}
    <p className={classNames('help', { 'is-danger': error })}> {error} </p>
  </div>
)

export default InputTextarea
