import React from "react";
import { Redirect } from "react-router-dom";
import { FacebookLoginButton } from "react-social-login-buttons";
import styled from "styled-components";

import firebase from "firebase/app";
import AuthContext from "../contexts/AuthContext";
import logger from "../services/logger";
import Loading from "../views/elements/Loading";
import { createRemoteURL } from "../config";
import SignInBox from "./SignInBox";

export default function LoginPage() {
  const { loginWithRemote, isAuthenticated } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUser, SetIsUser] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    firebase.auth().onAuthStateChanged(async result => {
      if (result) {
        const token = await result.getIdToken();
        loginWithRemote({
          provider: "facebook",
          token
        });
        setIsLoading(false);
      } else setIsLoading(false);
    });
  }, []);

  function handleLoginInWithFacebook(response) {
    logger.debug("res", response);

    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "redirect"
    });
    setIsLoading(true);
    firebase.auth().signInWithRedirect(provider)
    // .then(result => {
    //   var token = result.credential.accessToken;
    //   logger.debug(token);
    //   return firebase.auth().currentUser.getIdToken();
    // })
    // .then(token => {
    //   loginWithRemote({
    //     provider: "facebook",
    //     token
    //   });
    //   setIsLoading(false);
    // })
    .catch(error => {
      setIsLoading(false);
      alert("Error:" + JSON.stringify(error));

      switch (error.code) {
        case "INVALID_EMAIL":
          alert("The specified user account email is invalid.");
          break;
        case "INVALID_PASSWORD":
          alert("The specified user account password is incorrect.");
          break;
        case "INVALID_USER":
          alert("The specified user account does not exist.");
          break;
        case "auth/popup-blocked":
          alert("Please change browser, this browser is block pop-up");
          break;
        default:
          alert("Error logging user in:" + JSON.stringify(error.message));
      }
      logger.debug(error);
      // Handle Errors here.
      // var errorCode = error.code
      // var errorMessage = error.message
      // // The email of the user's account used.
      // var email = error.email
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential
      // toast.error(error.message)
      // ...
    });
  }

  const handleSignin = info => {
    loginWithRemote(info);
  };

  const toggleUser = () => {
    SetIsUser(true);
  };

  if (isAuthenticated) {
    return <Redirect to="/creator" replace />;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <LoginPageStyle>
      <div className="hero is-success is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <h3 className="title has-text-grey">Login</h3>
              <p className="subtitle has-text-grey">create content with us.</p>
              <div className="box">
                <figure className="avatar image is-128x128">
                  <img src="./__images/logo.png" alt="logo" />
                </figure>
                <div className="login-section">
                  <FacebookLoginButton onClick={handleLoginInWithFacebook} />
                  <hr />
                  {isUser && <SignInBox onSignin={handleSignin} />}
                  {!isUser && (
                    <div
                      className="button has-pulled-right heading"
                      onClick={toggleUser}
                    >
                      User Login
                    </div>
                  )}
                </div>
              </div>
              <p className="has-text-grey">
                <a href="/signUp">Sign Up</a> {/*  &nbsp;·&nbsp; */}
                {/* <a href="/">Forgot Password</a> &nbsp;·&nbsp; */}
                {/* <a href="/contact">Need Help?</a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </LoginPageStyle>
  );
}

const LoginPageStyle = styled.div`
  .hero.is-success {
    background: #f2f6fa;
  }
  .hero .nav,
  .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .box {
    margin-top: 5rem;
  }
  .avatar {
    margin: 0 auto;
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .login-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .avatar img {
    padding: 18px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
`;
