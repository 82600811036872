import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import useImageCropEditModal from '../../modules/image-crop/useImageCropEditModal'
import { uploadPortalImage } from '../../services/remoteImageService'
import PortalContext from '../../contexts/PortalContext'
import {
  cardMediaReponsive,
  cardLoadingPlaceholder,
  imageOverlay,
} from '../../views/utils'
import config from '../../config'

export default function EditorThumbnailBox({ children }) {
  const { portal } = React.useContext(PortalContext)
  const mainImageId = _.get(portal, 'mainImageId')

  const { showModal } = useImageCropEditModal({
    onImageCrop: result => {
      const croppedImageFile = _.get(result, 'croppedImageFile')

      if (croppedImageFile) {
        //logic repleace sameImageId so portal just same.
        //refresh picture will check lastChecked from image.
        uploadPortalImage(portal._id, croppedImageFile, mainImageId).catch(
          error => window.alert(error.message),
        )
        setImageURL(URL.createObjectURL(croppedImageFile))
      }
    },
  })

  const [imageURL, setImageURL] = React.useState()

  React.useEffect(() => {
    setImageURL(mainImageId ? config.imageURLPrefix + '/' + mainImageId : null)
  }, [mainImageId])

  function handleUploadImage(e) {
    const file = _.get(e, 'target.files[0]')

    if (file) {
      showModal(file)
    }
    e.target.value = '' //Fixed Can't upload same file twice
  }

  return (
    <EditorThumbnailImageStyle>
      {children}
      <CardWithFileUploadFile
        imageURL={imageURL}
        onChange={handleUploadImage}
      />
    </EditorThumbnailImageStyle>
  )
}

const EditorThumbnailImageStyle = styled.div.attrs({
  className: '__editor-thumbnail-image-box',
})`
  @media only screen and (max-width: 768px) {
    position: absolute;
    margin: 0 auto;
    z-index: 21;
    bottom: -115px;
  }

  @media only screen and (min-width: 768px) {
    position: absolute;
    left: 40px;

    bottom: -150px;
    z-index: 21;
  }
`

const CardWithFileUploadFile = ({ onChange, imageURL }) => (
  <CardWithPlaceholderStyle
    mediaResponsive={cardMediaReponsive}
    imageURL={imageURL}
  >
    <label className="card-image" htmlFor="__thumbnail-photo">
      <div className="image is-square">
        <div className="__image-placeholder">
          <div className="icon __highlight-editor">
            <i className="fas fa-camera fa-3x is-large" />
          </div>
          <input
            id="__thumbnail-photo"
            name="file"
            type="file"
            accept="image/*"
            onChange={onChange}
          />
        </div>
      </div>
    </label>
    {/* <div className="card-content">
      <div className="media">
        <div className="media-content">
          <div className="__tags-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__title-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__subtitle-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__subtitle-placeholder">
            <div className="__not-animated-background" />
          </div>
        </div>
      </div>
    </div> */}
  </CardWithPlaceholderStyle>
)

const CardWithPlaceholderStyle = styled.div.attrs({ className: 'card' })`
  ${props => props.mediaResponsive}
  ${cardLoadingPlaceholder}
  

  @media only screen and (max-width: 768px) {
    width: 180px !important;
  }

  @media only screen and (min-width: 768px) {
    width: 220px !important;
  }

  label {
    cursor: pointer;
  }

  #__thumbnail-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .__image-placeholder {
    ${imageOverlay}
    border-radius: 8px;
    border-radius: 8px;

    ::before {
      border-radius: 8px;
      border-top-right-radius: 8px;
    }

    ::after {
      border-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
`
