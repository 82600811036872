import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import InputTextWithSuggestion from '../../views/widgets/InputTextWithSuggestion'
import { getDefaultLangText } from '../../constants/utils'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'

export default function EditorCoverInfoBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  return (
    <EditorCoverInfoBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('app.information.label', {
              defaultValue: 'Edit Information',
            }),
          },
          modalRef: 'EDITOR_COVER_INFO_MODAL',
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorCoverInfoBoxStyle>
  )
}

const EditorCoverInfoBoxStyle = styled.div.attrs({
  className: '__highlight-editor-box __editor-box  __editor-cover-info-box',
})`
  display: inline-block;
  position: absolute;
  margin-left: unset;
  margin-right: unset;
  min-width: 320px;

  border: unset;

  .icon {
    color: white;
    opacity: 0.8;
  }
`

export function EditorCoverInfoModal({ onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, errors, handleChange, handleSubmit } = useForm({
    initialState: {
      title: getDefaultLangText(),
      highlightSentence: getDefaultLangText(),
      ..._.pick(portal, ['_id', 'title', 'highlightSentence']),
    },
    onValidate: () => {
      const errors = {}
      return errors
    },
    onSubmit: values => {
      updatePortal(values)
        .then(portal => {
          updateLocalData(portal)
          handleClose()
        })
        .catch(error => window.alert(error.message))
    },
    reinitialState: true,
  })

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <Field
          label={`${t('field.title.label', {
            defaultValue: 'Title',
          })} [${language}]`}
        >
          <InputText
            name={`title.${language}`}
            placeholder={t('field.title.placeholder', {
              defaultValue: 'Enter your title.',
            })}
            onChange={handleChange}
            value={values.title[language]}
            error={errors.title && errors.title[language]}
          />
        </Field>
        <Field
          label={`${t('field.highlightSentence.label', {
            defaultValue: 'Highlight Sentence',
          })} [${language}]`}
        >
          <InputTextWithSuggestion
            suggestMinLength={60}
            name={`highlightSentence.${language}`}
            placeholder={t('field.highlightSentence.placeholder', {
              defaultValue: 'Enter your highlight sentence.',
            })}
            onChange={handleChange}
            value={values.highlightSentence[language]}
          />
        </Field>
      </section>
      <FooterConfirmModal onSubmit={handleSubmit} onClose={handleClose} />
    </>
  )
}
