import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

export default function SliderWithLayout({
  children,
  settings: _settings,
  mSettings,
}) {
  var settings = {
    dots: false,
    infinite: false,
    touchThreshold: 5,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    // className: 'slider variable-width',
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          speed: 100,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          arrows: true,
          ...mSettings,
        },
      },
    ],
    ..._settings,
  };

  return (
    <SlideLayout>
      <SlideContent>
        <Slider {...settings}>{children}</Slider>
      </SlideContent>
    </SlideLayout>
  );
}

const SlideLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SlideContent = styled.div`
  width: 100%;
  /* background: red; */

  @media only screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .slick-prev,
  .slick-next {
    width: 35px;
    height: 35px;
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #4a4a4a;
    font-size: 35px;
    opacity: 0.9;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
`;

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;

//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'black' }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;

//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'green' }}
//       onClick={onClick}
//     />
//   );
// }
