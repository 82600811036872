import React from 'react'
import { fetchPortal } from '../services/remoteService'
import logger from '../services/logger'

const PortalContext = React.createContext()

export function PortalContextProvider({ children }) {
  const [portal, setPortal] = React.useState({})

  function updateLocalData(portal) {
    setPortal(portal)
  }

  function loadPortalByID(portalID, provider) {
    return fetchPortal(portalID, provider)
      .then(portal => {
        setPortal(portal)
        return portal
      })
      .catch(error => logger.debug(error))
  }

  return (
    <PortalContext.Provider
      value={{
        portal,
        updateLocalData,
        loadPortalByID,
      }}
    >
      {children}
    </PortalContext.Provider>
  )
}

export default PortalContext
