import React from 'react'
import styled, { css } from 'styled-components'

import ImageWithPlaceholder from '../../views/elements/ImageWithPlaceholder'
import Truncate from '../../views/widgets/Truncate'
import { cardMediaReponsive } from '../../views/utils'
import EditorExternalLinkBox from '../editor-modal/EditorExternalLinkBox'
import EditorWithAuthHandler from '../editor/EditorWithAuthHandler'

export default function PortalListLinkCard({ isCreate, link, index }) {
  function handleOpenLink() {
    window.open(link.externalUrl, '_blank')
  }

  return (
    <LinkCardStyle onClick={handleOpenLink}>
      <EditorWithAuthHandler
        renderCustomEditor={() => (
          <PortalListLinkCardWithEditor index={index} link={link} />
        )}
      >
        <PortalListLinkCardInfo {...link} />
      </EditorWithAuthHandler>
    </LinkCardStyle>
  )
}

function PortalListLinkCardWithEditor({ index, link, onClick }) {
  return (
    <EditorExternalLinkBox params={{ index }}>
      <PortalListLinkCardInfo {...link} />
    </EditorExternalLinkBox>
  )
}

function PortalListLinkCardInfo({
  title,
  description,
  imageURL,
  externalUrl,
}) {
  return (
    <>
      <div className="card-image">
        <div className="image is-4by3">
          <ImageWithPlaceholder src={imageURL} alt={title} />
        </div>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-5">{title}</p>
            {description && (
              <p className="subtitle is-6 is-hidden-mobile">
                <Truncate size={4}>{description}</Truncate>
              </p>
            )}
          </div>
        </div>
        <div className="button is-fullwidth is-danger">ดูรายละเอียด</div>
      </div>
    </>
  )
}

const linkCard = css`
  cursor: pointer;
  ${cardMediaReponsive}

  display: flex;
  flex-direction: column;

  .card-image {
    flex: 1;
  }

  .card-content {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const LinkCardStyle = styled.div.attrs({
  className: 'card __link-card',
})`
  ${linkCard}
`
