import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import PortalSearchAutoComplete from '../../templates/portal-search/PortalSearchAutocomplete';
import { searchBarMediaResponsive } from '../../views/utils';
import config from '../../config';

export default function ListingListImageHeaderWithSearchBar({
  listingID,
  imageUrl,
  title,
  description,
}) {
  return (
    <HeaderImageStyle imageUrl={imageUrl}>
      <div className="search-panel">
        <div className="title has-text-white">{title}</div>
        <div className="subtitle has-text-white">{description}</div>
        <PortalSearchAutoComplete
          config={{
            index: config.elasticIndexes.portalIndex,
            queries: [{ term: { listingRefs: listingID } }],
            nextQueries: {
              'listing.id': listingID,
              'listing.title': title,
            },
          }}
          placeholder={`Search ${title || ''} :`}
        />
      </div>
      <ImageBackgroundStyle>
        <LazyLoadImage src={imageUrl} effect="blur" alt={title} />
      </ImageBackgroundStyle>
      <BackgroundOverlay />
    </HeaderImageStyle>
  );
}

const BackgroundOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: 2;
`;

const ImageBackgroundStyle = styled.div`
  position: absolute;
  z-index: 1;
  width: 100vw;

  img {
    width: 100vw;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    height: 42.85vw;

    img {
      height: 42.85vw;
    }
  }

  @media only screen and (min-width: 768px) {
    height: 25vw;
    img {
      height: 25vw;
    }
  }
`;

const HeaderImageStyle = styled.div`
  ${searchBarMediaResponsive}

  .search-panel {
    z-index: 11;
    /* margin-top: 3.5rem; */
  }

  @media only screen and (max-width: 768px) {
    height: 42.85vw;
    width: 100vw;
    background-position: center;
    background-size: cover;
  }

  @media only screen and (min-width: 768px) {
    width: 100vw;
    height: 25vw;
    background-size: cover;
    background-position: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title,
  .subtitle {
    text-shadow: 0px 2px 15px #0a0a0a;
  }
`;
