import React from 'react'
import { isEmpty } from 'lodash'
import { compose } from 'recompose'
import Field from '../views/widgets/Field'
import InputText from '../views/widgets/InputText'
import { withFormik } from 'formik'
 

const SigninBox = ({
  values,
  errors,
  isValid,
  onSubmit,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <div>
    <form
      onSubmit={e => {
        handleSubmit(e)
        e.preventDefault()
      }}
    >
      <Field isHorizontal={false} label="Username">
        <InputText
          name="username"
          type="text"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.username}
        />
      </Field>
      <Field isHorizontal={false} label="Password">
        <InputText
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
        />
      </Field>
      <input
        type="submit"
        disabled={!isValid}
        className="button is-info is-fullwidth"
        value="SIGN IN"
      />
    </form>
  </div>
)

export default compose(
  withFormik({
    mapPropsToValues: props => ({ username: '', password: '' }),
    handleSubmit: (values, { props }) => {
      props.onSignin(values)
    },
    validate: values => {
      const errors = {}
      if (isEmpty(values.password)) errors.passcode = 'Required'
      return errors
    },
    isInitialValid: false,
  }),
)(SigninBox)
