import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'querystring'

import PortalListItem from '../portal-list/PortalListItem'
import TownListItem from '../town-list/TownListItem'
import { searchSuggestion } from '../../services/elasticService'
import config from '../../config';

const Autocomplete = ({
  hits,
  currentRefinement,
  refine,
  nextQueries = {},
  ...props
}) => {
  const [value, setValue] = React.useState(currentRefinement)
  const [isDropdown, setIsDropdown] = React.useState(false)

  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      setIsDropdown(true)
    } else {
      setIsDropdown(false)
    }
    refine(value)
  }, [refine, value])

  function handleChange(event) {
    setValue(event.currentTarget.value)
  }

  function getHitsByIndex(index) {
    return (
      _.chain(hits)
        .filter({ _index: index })
        .value() || []
    )
  }

  const towns = getHitsByIndex(config.elasticIndexes.townIndex)
  const portals = getHitsByIndex(config.elasticIndexes.portalIndex)

  return (
    <div
      className={classNames('search-bar dropdown', { 'is-active': isDropdown })}
      style={{ width: '100%' }}
    >
      <div className="dropdown-trigger" style={{ width: '100%' }}>
        <div className="control has-icons-left">
          <Input
            className="input is-meduim"
            type="search"
            value={currentRefinement}
            onChange={handleChange}
            placeholder="Search Anything..."
            {...props}
          />
          <span className="icon is-large is-left">
            <i className="fas fa-search" aria-hidden="true" />
          </span>
        </div>
      </div>
      {isDropdown && (
        <div className="dropdown-menu" style={{ width: '100%' }}>
          <div className="dropdown-content">
            {/* TOWN SECTION */}
            {!_.isEmpty(towns) && (
              <div className="dropdown-item heading has-background-white-ter">
                towns
              </div>
            )}
            {towns.map(hit => (
              <Link
                to={`/towns/${hit._id}`}
                className="dropdown-item"
                key={hit._id}
              >
                <TownListItem town={hit} searchText={value}/>
              </Link>
            ))}
            {/* PORTALS SECTION */}
            {!_.isEmpty(portals) && (
              <div className="dropdown-item heading has-background-white-ter">
                portals
              </div>
            )}
            {portals.map(hit => (
              <Link
                to={`/portals/${hit._id}?provider=${hit.provider}`}
                className="dropdown-item"
                key={hit._id}
              >
                <PortalListItem portal={hit} searchText={value}/>
              </Link>
            ))}

            {_.isEmpty([...towns, ...portals]) ? (
              <div className="dropdown-item heading"> - empty -</div>
            ) : (
              <Link
                // style={{textDecoration: 'underline'}}
                className="dropdown-item heading has-background-white-ter has-text-centered has-text-weight-bold"
                to={`/portals?${queryString.stringify({
                  ...nextQueries,
                  title: value,
                })}`}
              >
                show all about "{value}"
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const Input = styled.input`
  box-shadow: 0 10px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0);
`

export default function PortalSearchAutoComplete({ config, ...props }) {
  const [value, setValue] = React.useState('')
  const [hits, setHits] = React.useState([])
  const debouncedSearchTerm = useDebounce(value, 500)

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      searchSuggestion(config, value).then(hits => setHits(hits))
    } else {
      setHits(hits)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  function onRefine(value) {
    setValue(value)
  }

  return (
    <Autocomplete
      currentRefinement={value}
      refine={onRefine}
      hits={hits}
      nextQueries={_.get(config, 'nextQueries')}
      {...props}
    />
  )
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}
