import React from 'react'
import _ from 'lodash'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { compose, withProps } from 'recompose'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'
import config from '../../config'

const googleApi = config.googleApi.mapKey

const MapView = ({
  defaultCenter = { lat: -34.397, lng: 150.644 },
  defaultZoom = 15,
  markers,
  center,
  isMarkerShown = true,
  onMarkerDragEnd,
  onMapClick,
  bounds,
  onPlacesChange,
  onSearchBoxMounted,
}) => (
  <GoogleMap
    defaultZoom={defaultZoom}
    defaultCenter={defaultCenter}
    center={center || defaultCenter}
    onClick={onMapClick}
  >
    <SearchBox
      ref={onSearchBoxMounted}
      bounds={bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={onPlacesChange}
    >
      <input
        type="text"
        placeholder="ค้นหาสถานที่"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          marginTop: `27px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </SearchBox>
    {isMarkerShown &&
      _.map(markers, ({ position }) => (
        <Marker
          key={position.lat + position.lng}
          position={position}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
        />
      ))}
  </GoogleMap>
)

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApi}&libraries=places&language=th&region=TH`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(MapView)
