import React from 'react';
import styled from 'styled-components';

export default function Truncate({ children, size }) {
  return <FixedHeight size={size}>{children}</FixedHeight>;
}

Truncate.defaultPropTypes = {
  size: 2,
  disableBtn: false,
};

const FixedHeight = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.size || 2};
  -webkit-box-orient: vertical;
`;
