import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import useImageCropEditModal from '../../modules/image-crop/useImageCropEditModal'
import { uploadPortalImage } from '../../services/remoteImageService'
import PortalContext from '../../contexts/PortalContext'

export default function EditorCoverImageBox({ children }) {
  const { portal, updateLocalData } = React.useContext(PortalContext)
  const mainImageId = _.get(portal, 'galleryImages[0].mainImageId')
  const { showModal } = useImageCropEditModal({
    config: {
      imageAspectRatio: 4 / 3,
    },
    onImageCrop: result => {
      const croppedImageFile = _.get(result, 'croppedImageFile')

      if (croppedImageFile) {
        uploadPortalImage(portal._id, croppedImageFile, mainImageId || '').then(
          result => {
            //logic repleace sameImageId so portal just same.
            updateLocalData({
              ...portal,
              imageCoverModifiedDate: result.lastChecked,
            }) // for refresh?
          },
        )
      }
    },
  })

  function handleUploadImage(e) {
    const file = _.get(e, 'target.files[0]')

    if (file) {
      showModal(file)
    }
    e.target.value = '' //Fixed Can't upload same file twice 
  }

  return (
    <EditorCoverImageStyle>
      {children}
      <CoverImageInputFileStyle>
        <label className="__cover-placeholder" htmlFor="__upload-cover-image">
          <div className="icon __highlight-editor">
            <i className="fas fa-camera fa-3x is-large" />
          </div>
          <input
            id="__upload-cover-image"
            name="file"
            type="file"
            accept="image/*"
            onChange={handleUploadImage}
          />
        </label>
      </CoverImageInputFileStyle>
    </EditorCoverImageStyle>
  )
}

const EditorCoverImageStyle = styled.div.attrs({
  className: '__editor-cover-image-box',
})`
  position: absolute;
  right: 40px;
  bottom: 25px;
  z-index: 20;
`

const CoverImageInputFileStyle = styled.div.attrs({
  className: '__cover-image-input-file',
})`
  label {
    cursor: pointer;
  }

  #__upload-cover-image {
    display: none;
    z-index: -1;
  }

  .icon {
    color: white;
    opacity: 0.8;
  }
`
