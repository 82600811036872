import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ImageCrop from '../image-crop/ImageCrop'
import { Trans } from 'react-i18next'

class ImageEditModal extends Component {
  static propTypes = {
    params: PropTypes.shape({
      title: PropTypes.any,
      imageFile: PropTypes.any,
      imageAspectRatio: PropTypes.number,
    }),
    onRequestClose: PropTypes.func,
  }

  state = {
    imageFile: undefined,
    imageAspectRatio: undefined,
    croppedImageFile: undefined,
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.params.imageFile !== state.imageFile ||
      props.params.imageAspectRatio !== state.imageAspectRatio
    ) {
      return {
        ...state,
        imageFile: props.params.imageFile,
        imageAspectRatio: props.params.imageAspectRatio,
      }
    }
    return null
  }

  handleImageChange = file => {
    this.setState({ imageFile: file })
  }

  handleImageCrop = file => {
    this.setState({ croppedImageFile: file })
  }

  handleImageCropDefault = callback => {
    this.setState(
      {
        imageFile: undefined,
        croppedImageFile: undefined,
      },
      callback,
    )
  }

  handleRequestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose(null)
    }
  }

  isUploadable = () => this.state.imageFile !== undefined

  handleUpload = async () => {
    if (!this.isUploadable()) return

    if (this.props.onRequestClose) {
      this.props.onRequestClose({
        croppedImageFile: this.state.croppedImageFile,
        action: _.get(this.props, 'params.action'),
      })
    }
  }
  render() {
    return (
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Trans
              i18nKey="actions.edit.label"
              values={{ title: _.get(this.props.params, 'title') || 'Image' }}
              defaults="Edit {{title}}"
            />
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={this.handleRequestClose}
          />
        </header>
        <section className="modal-card-body">
          {this.state.imageFile && (
            <ImageCrop
              imageAspectRatio={this.state.imageAspectRatio || 1}
              imageFile={this.state.imageFile}
              onImageCrop={this.handleImageCrop}
            />
          )}
        </section>
        <footer className="modal-card-foot">
          {this.state.croppedImageFile && (
            <button className="button is-success" onClick={this.handleUpload}>
              <Trans i18nKey="actions.save.label" defaults="Save" />
            </button>
          )}
          <button className="button" onClick={this.handleRequestClose}>
            <Trans i18nKey="actions.cancel.label" defaults="Cancel" />
          </button>
        </footer>
      </div>
    )
  }
}

export default ImageEditModal
