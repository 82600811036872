import React from 'react'
import {
  createPortalWizard,
  updatePortalWizard,
} from '../services/remoteService'
import logger from '../services/logger';
import config from '../config';
import _ from 'lodash'

const FormDataContext = React.createContext()

export function FormDataContextProvider({ children }) {
  const [data, setData] = React.useState({})

  function handleUpdateRemoteData(data, currentStep) {
    logger.debug('handle update remote ', data)
    data.stepIndex = currentStep

    if (!data.id && currentStep < 1) {
      throw new Error('not found last item.')
    }

    if (!data._id) {
      logger.info('[FormDataContext] create wizard')
      _.set(data, 'provider', config.providerContent)
      return createPortalWizard(data).then(result => {
        setData(values => ({ ...values, ...result }))
        return result
      })
    }

    logger.info('[FormDataContext] update wizard')
    return updatePortalWizard(data).then(result => {
      setData(values => ({ ...values, ...result }))
      return result
    })
  }

  function clearData() {
    logger.info('[FormDataContext] clear data')
    setData({})
  }

  // React.useEffect(() => {
  //   logger.info('[FormDataContext] id', data._id)
  // }, [data._id])

  return (
    <FormDataContext.Provider
      value={{
        data,
        clearData,
        updateLocalData: setData,
        updateRemoteData: handleUpdateRemoteData,
      }}
    >
      {children}
    </FormDataContext.Provider>
  )
}

export default FormDataContext
