import PropTypes from 'prop-types'

export const stepContentPagePropTypes = {
  initialValues: PropTypes.object,
  onAfterValid: PropTypes.func,
  onPrev: PropTypes.func,
}

export const ColorTypes = [
  'is-primary',
  'is-link',
  'is-info',
  'is-success',
  'is-warning',
  'is-danger',
]
