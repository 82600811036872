import React from 'react'
import styled from 'styled-components'
import PortalSearchAutoComplete from '../portal-search/PortalSearchAutocomplete'
import { searchBarMediaResponsive } from '../../views/utils'
import InfoContext from '../../contexts/InfoContext'

export default function HomeBannerWithSearchBar() {
  const { remoteWebConfig } = React.useContext(InfoContext)

  return (
    <BannerLayout>
      <div className="search-panel">
        <div className="title has-text-white">
          {remoteWebConfig.title || 'Glog'}
        </div>
        <div className="subtitle">
          {remoteWebConfig.description
            ? remoteWebConfig.description
            : 'create content by your self.'}
        </div>
        <PortalSearchAutoComplete />
      </div>
      <img
        className="banner"
        src={remoteWebConfig.homeImageURL || '/__images/banner.png'}
        alt="banner"
      />
    </BannerLayout>
  )
}

const BannerLayout = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .title .subtitle {
    text-shadow: 0 10px 18px rgba(10, 10, 10, 0.1);
  }

  .subtitle {
    color: rgba(256, 256, 256, 0.8);
  }

  img.banner {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    object-position: bottom;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    height: 100vh;

    img.banner {
      height: 100vh;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    height: 100vh;
    img.banner {
      height: 100vh;
    }
  }

  @media only screen and (min-width: 1023px) {
    height: 100vh;
    img.banner {
      height: 100vh;
    }
  }

  ${searchBarMediaResponsive}
`
