import React from 'react'
import { Redirect } from 'react-router-dom'

export default function CustomLink({ children, ...props }) {
  const [isRedirect, setIsRedirect] = React.useState(false)

  function handleLinkClick() {
    setIsRedirect(true)
  }

  if (isRedirect) {
    return <Redirect {...props} />
  }
  return (
    <div {...props} onClick={handleLinkClick}>
      {children}
    </div>
  )
}
