import React from 'react'
import _ from 'lodash'
import { fetchWebConfig } from '../services/remoteService'
import logger from '../services/logger'
import config from '../config'

const InfoContext = React.createContext()

export function InfoContextProvider({ children }) {
  const [remoteWebConfig, setConfig] = React.useState({})

  React.useEffect(() => {
    fetchWebConfig()
      .then(result => {
        const _config = _.pick(result, ['title', 'isContactWithMe', 'contacts', 'tags', 'description']) || {}

        if (result.logoImageId) {
          _config.logoImageURL =
            config.imageURLPrefix + '/' + result.logoImageId
        }

        if (result.homeImageId) {
          _config.homeImageURL =
            config.imageURLPrefix + '/' + result.homeImageId
        }

        setConfig(_config)
      })
      .catch(error => logger.debug(error))
  }, [])

  return (
    <InfoContext.Provider
      value={{
        remoteWebConfig,
      }}
    >
      {children}
    </InfoContext.Provider>
  )
}

export default InfoContext
