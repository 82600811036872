import en from './en.json'
import th from './th.json'
import zh from './zh.json'

export default {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
  zh: {
    translation: zh,
  },
}
