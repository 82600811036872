import React from "react";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import classNames from "classnames";
import useForm from "../../hooks/useForm";
import useImageCropEditModal from "../../modules/image-crop/useImageCropEditModal";
import { uploadPortalImage } from "../../services/remoteImageService";
import config from "../../config";
import ActionPanelLayout from "../../views/widgets/ActionPanelLayout";
import { imageOverlay } from "../../views/utils";
import FlashMessage from "../../views/widgets/FlashMessage";
import { getDefaultGalleryImage } from "../../templates/editor-modal/EditorGalleryImageBox";

export default function UploadCoverPage({
  initialValues,
  onPrev,
  onAfterValid
}) {
  const { t } = useTranslation();

  const { values, errors, handleSubmit, setFieldValue, resetErrors } = useForm({
    initialState: { galleryImages: [], ...initialValues },
    onValidate: ({ values }) => {
      const errors = {};

      if (_.isEmpty(values.galleryImages)) {
        errors.galleryImages = t("form.require.label", {
          title: t("field.imageCover.label", {
            defaultValue: "Cover Image"
          }),
          defaultValue: "Require {{title}} field"
        });
      }

      return errors;
    },
    onSubmit: onAfterValid,
    reinitialState: true
  });

  const imageId = _.get(values, "galleryImages[0].mainImageId");

  const [imageURL, setImageURL] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setImageURL(imageId ? config.imageURLPrefix + "/" + imageId : null);
  }, [imageId]);

  const { showModal } = useImageCropEditModal({
    config: {
      imageAspectRatio: 4 / 3
    },
    onImageCrop: result => {
      const croppedImageFile = _.get(result, "croppedImageFile");

      if (croppedImageFile) {
        setIsLoading(true);
        uploadPortalImage(values._id, croppedImageFile, imageId || "").then(
          result => {
            setFieldValue("galleryImages", [
              { ...getDefaultGalleryImage(), mainImageId: result.ref }
            ]);
            setIsLoading(false);
          }
        );
        setImageURL(URL.createObjectURL(croppedImageFile));
      }
    }
  });

  function handleUploadImage(e) {
    const file = _.get(e, "target.files[0]");

    if (file) {
      showModal(file);
    }
    e.target.value = ""; //Fixed Can't upload same file twice
  }
  return (
    <UploadCoverPageStyle className="has-text-centered">
      <h3 className="title is-3">
        <Trans
          i18nKey="actions.upload.label"
          values={{
            title: t("field.imageCover.label", {
              defaultValue: "Cover Image"
            })
          }}
          defaults="Upload {{title}}"
        />
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="__content-layout">
          <FlashMessage
            type="is-warning"
            isShow={!_.isEmpty(errors.galleryImages)}
            onClose={resetErrors}
          >
            {errors.galleryImages}
          </FlashMessage>
          <CoverImageInputFile
            onChange={handleUploadImage}
            imageURL={imageURL}
          />
        </div>
        <ActionPanelLayout>
          <div className="is-hidden-mobile">
            <div className="buttons">
              <div className="button is-default" onClick={onPrev}>
                <Trans i18nKey="actions.back.label" defaults="Back" />
              </div>
              <button
                className={classNames(
                  "button is-primary",
                  { "is-loading": isLoading }
                )}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {t("actions.next.label", { defaultValue: "Next" })}
              </button>
            </div>
          </div>
          <div className="is-hidden-tablet">
            <div className="columns is-gapless is-mobile">
              <div className="column">
                <div
                  className="button is-medium is-default is-fullwidth"
                  onClick={onPrev}
                >
                  <Trans i18nKey="actions.back.label" defaults="Back" />
                </div>
              </div>
              <div className="column">
                <button
                  className={classNames(
                    "button is-medium is-primary is-fullwidth",
                    { "is-loading": isLoading }
                  )}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {t("actions.next.label", { defaultValue: "Next" })}
                </button>
              </div>
            </div>
          </div>
        </ActionPanelLayout>
      </form>
    </UploadCoverPageStyle>
  );
}

const UploadCoverPageStyle = styled.div.attrs({ className: "" })`
  .__content-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const CoverImageInputFile = ({ imageURL, onChange }) => (
  <CoverImageInputFileStyle imageURL={imageURL}>
    <label className="__cover-placeholder" htmlFor="__upload-cover-image">
      <img
        className="is-hidden-mobile"
        src={require("../../assets/images/cover-placeholder.png")}
        alt="cover-placeholder"
      />
      <img
        className="is-hidden-tablet"
        src={require("../../assets/images/cover-placeholder-mobile.svg")}
        alt="cover-placeholder"
      />
      <div className="__image-placeholder">
        <i className="fas fa-camera fa-3x is-large" />
      </div>
      <input
        id="__upload-cover-image"
        name="file"
        type="file"
        accept="image/*"
        onChange={onChange}
      />
    </label>
  </CoverImageInputFileStyle>
);

const CoverImageInputFileStyle = styled.div.attrs({
  className: "__cover-image-input-file"
})`
  display: flex;
  justify-content: center;
  label {
    cursor: pointer;
  }

  #__upload-cover-image {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  @media only screen and (max-width: 768px) {
    .__cover-placeholder {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 260px;
      position: relative;
    }

    .__cover-placeholder img {
      width: 100%;
    }

    .__image-placeholder {
      display: flex;
      position: absolute;
      background: #eeee;
      width: 100%;
      margin: 0px;
      margin-top: 72px;
      height: 160px;
      width: 204px;
      ${imageOverlay}
    }
  }

  @media only screen and (min-width: 768px) {
    .__cover-placeholder {
      display: flex;
      justify-content: center;
      height: 320px;
      position: relative;
    }

    .__cover-placeholder img {
      height: 100%;
    }

    .__image-placeholder {
      display: flex;
      position: absolute;
      background: #eeee;
      height: 145px;
      width: 448px;
      margin: 0px;
      top: 16px;
      ${imageOverlay}
    }
  }
`;
