import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import styled from 'styled-components'

import ImageWithPlaceholder from '../../views/elements/ImageWithPlaceholder'
import CardWithLink from '../../views/elements/CardWithLink'
import { Trans } from 'react-i18next'
import Card from '../../views/elements/Card'
import { cardLoadingPlaceholder } from '../../views/utils'

export default function PortalListCardEditor({
  portal = {},
  linkTo,
  isActionPanel,
  cardComponent = CardWithLink,
}) {
  const CardComponent = linkTo ? cardComponent : Card
  const title = _.get(portal, 'title')
  const imageUrl = _.get(portal, `imageURL`)

  if (!portal._id) {
    return (
      <CardComponent to={linkTo}>
        <div
          className="card-image has-text-centered"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: 'gray',
          }}
        >
          <i className="fas fa-plus-circle fa-4x is-large" />
        </div>
      </CardComponent>
    )
  }

  return (
    <CardComponent to={linkTo}>
      <div className="card-image">
        <figure className="image is-square">
          <ImageWithPlaceholder src={imageUrl} alt={title} />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-5">{title || 'No Name'}</p>
            {isActionPanel ? (
              <ActionPanel portal={portal} linkTo={linkTo} />
            ) : (
              <DescriptionPlaceholder />
            )}
          </div>
        </div>
      </div>
    </CardComponent>
  )
}

const ActionPanel = ({ portal, linkTo }) => (
  <div className="columns is-gapless is-desktop">
    <div className="column">
      <Link className="button heading is-fullwidth" to={linkTo}>
        {portal.stepIndex ? (
          <Trans i18nKey="actions.continue.label" defaults="Continue" />
        ) : (
          <Trans i18nKey="actions.edit.label" defaults="Edit" />
        )}
      </Link>
    </div>
    <div className="column">
      <div
        className={classNames('button  heading is-fullwidth', {
          'is-success': portal.status === 'RELEASE',
          'is-warning': portal.status === 'USER_DRAFT',
          'is-danger': portal.status === 'REJECT',
        })}
      >
        <Trans i18nKey={`glog.status.${_.camelCase(portal.status)}.label`}>
          {_.startCase(portal.status)}
        </Trans>
      </div>
    </div>
  </div>
)

const DescriptionPlaceholder = () => (
  <DescriptionPlaceholderStyle>
    <div className="__subtitle-placeholder">
      <div className="__not-animated-background" />
    </div>
    <div className="__subtitle-placeholder">
      <div className="__not-animated-background" />
    </div>
  </DescriptionPlaceholderStyle>
)

const DescriptionPlaceholderStyle = styled.div`
  ${cardLoadingPlaceholder}
`
