import React from "react";
import styled from "styled-components";
import { platform } from "../../../services/view";
import { Trans } from "react-i18next";

const SubmitContainerStyled = styled.a`
  margin-right: 10px;
  margin-left: 10px;
`;

const SubmitButton = ({ onFilterApply, onFilterReset, type }) => (
  <>
    <hr style={{margin: '0'}}/>
    <div
      style={{
        padding: '10px 10px 0 10px',
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <SubmitContainerStyled className="has-text-danger" onClick={() => onFilterReset(type)}>
        <Trans i18nKey="actions.reset.label" defaults="Reset" />
      </SubmitContainerStyled>

      <SubmitContainerStyled className="has-text-primary" onClick={() => onFilterApply(type)}>
        <Trans i18nKey="actions.accept.label" defaults="Accept" />
      </SubmitContainerStyled>
    </div>
  </>
);

export default SubmitButton;
