import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

export default function PortalAction({ action }) {
  if (action.type === 'none') return null
  return (
    <PortalActionLayoutStyle action={action} className="has-text-centered">
      {_.isEqual(action.type, 'phone') && (
        <a className="button is-fullwidth" href={`tel:${action.contact.phone}`}>
          {action.text}
        </a>
      )}
      {_.isEqual(action.type, 'externalUrl') && (
        <a
          className="button is-fullwidth"
          onClick={e => {
            e.stopPropagation()
            window.open(`${action.contact.externalUrl}`, '_blank')
          }}
        >
          {action.text}
        </a>
      )}
      {_.isEqual(action.type, 'lineAds') && (
        <a
          className="button is-fullwidth"
          onClick={e => {
            e.stopPropagation()
            window.open(`${formatLineAds(action.contact.lineAds)}`, '_blank')
          }}
        >
          {action.text}
        </a>
      )}
      {_.isEqual(action.type, 'linePersonal') && (
        <a
          className="button is-fullwidth"
          onClick={e => {
            e.stopPropagation()
            window.open(`${formatLine(action.contact.linePersonal)}`, '_blank')
          }}
        >
          {action.text}
        </a>
      )}
      {_.isEqual(action.type, 'messengerPage') && (
        <a
          className="button is-fullwidth"
          onClick={e => {
            e.stopPropagation()
            window.open(
              `${formatFacebook(action.contact.messengerPage)}`,
              '_blank',
            )
          }}
        >
          {action.text}
        </a>
      )}
      {_.isEqual(action.type, 'messengerPersonal') && (
        <a
          className="button is-fullwidth"
          onClick={e => {
            e.stopPropagation()
            window.open(
              `${formatFacebook(action.contact.messengerPersonal)}`,
              '_blank',
            )
          }}
        >
          {action.text}
        </a>
      )}
    </PortalActionLayoutStyle>
  )
}

const formatLineAds = id => {
  const idLine = _.startsWith(id, '@')
    ? '%40' + _.trimStart(_.trim(id), '@')
    : '%40' + id
  return 'http://line.me/ti/p/' + idLine
}

const formatLine = id => {
  return 'http://line.me/ti/p/~' + _.trim(id)
}

const formatFacebook = id => {
  return 'https://www.facebook.com/messages/t/' + _.trim(id)
}

const PortalActionLayoutStyle = styled.div`
  padding: 0 3rem;

  @media only screen and (max-width: 480px) {
    padding: 0;
  }
  > a {
    font-size: 24px;
    color: ${props => props.action.color.text};
    background: ${props => props.action.color.background};
  }
`
