import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import InputTextarea from '../../views/widgets/InputTextarea'
import { LOCATION_STATUS } from './FormLocationContainer'
import { useTranslation } from 'react-i18next'
import MapComponent from '../../modules/map/MapComponent'

const LocationForm = ({
  language = 'th',
  isShowMap = true,
  status,
  center,
  values,
  onBlur,
  onChange,
  onMarkerChange,
  onDefaultLocationClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className="columns">
      <div className="column">
        <Field label={t('field.location.latitude.label', { defaultValue: "Latitude"})} isVerticle>
          <InputText
            name={`latitude`}
            type="number"
            placeholder={t('field.location.latitude.placeholder', { defaultValue: "Enter your latitude."})}
            onChange={onChange}
            onBlur={onBlur}
            value={values.latitude}
          />
        </Field>
        <Field label={t('field.location.longitude.label', { defaultValue: "Longitude"})} isVerticle>
          <InputText
            name={`longitude`}
            type="number"
            placeholder={t('field.location.longitude.placeholder', { defaultValue: "Enter your longitude."})}
            onChange={onChange}
            onBlur={onBlur}
            value={values.longitude}
          />
        </Field>
        <Field
          label={`${t('field.location.streetAddress.placeholder', { defaultValue: "Street Address"})} [${language}]`}
          isVerticle
        >
          <InputTextarea
            name={`streetAddress.${language}`}
            placeholder={t('field.location.streetAddress.placeholder', { defaultValue: "Enter your street address."})}
            onChange={onChange}
            onBlur={onBlur}
            value={values.streetAddress[language]}
          />
        </Field>
        <div className="has-text-right">
          <button
            className={classNames('button is-primary', {
              'is-loading': status === LOCATION_STATUS.PENDING,
            })}
            onClick={onDefaultLocationClick}
          >
            <span className="icon">
              <i className="fas fa-compass" />
            </span>
            <span>{t('field.location.currentPosition.label', { defaultValue: "Current Position"})}</span>
          </button>
        </div>
      </div>
      <div className="column">
        {isShowMap && _.isNumber(values.latitude) && (
          <MapComponent
            center={center}
            isMarkerShown
            onMarkerChange={onMarkerChange}
            onTextSearchChange={text => {
              if (_.isEmpty(values.streetAddress[language])) {
                onChange({
                  target: { name: `streetAddress.${language}`, value: text },
                })
              }
            }}
            defaultCenter={{ lat: values.latitude, lng: values.longitude }}
            markers={[
              { position: { lat: values.latitude, lng: values.longitude } },
            ]}
          />
        )}
      </div>
    </div>
  )
}

export default LocationForm
