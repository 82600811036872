import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import { transformSocialLink } from '../../modules/utils'
import { getDefaultLangText } from '../../constants/utils'
import ActionPanelLayout from '../../views/widgets/ActionPanelLayout'
import { stepContentPagePropTypes } from '../../constants/prop-types'
import InputTags from '../../views/widgets/InputTags'

const initialState = values => ({
  contacts: {
    email: '',
    phone: '',
    fanpage: '',
    fbPageId: '',
    instragramId: '',
    lineId: '',
    webpage: '',
    others: getDefaultLangText(),
  },
  ...values,
})

export default function ContactPage({ initialValues, onPrev, onAfterValid }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  //can skip
  const { isValues, bindInput, values, setFieldValue, handleSubmit } = useForm({
    initialState: initialState(initialValues),
    onSubmit: onAfterValid,
    reinitialState: true,
  })

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="title is-3 has-text-centered">
        <Trans i18nKey="app.contacts.label" defaults="Contacts" />
      </h3>
      <div className="form-group">
        <Field label={t('field.contacts.phone.label', { defaultValue: '' })}>
          <InputTags
            {...bindInput(`contacts.phone`)}
            placeholder={t('field.contacts.phone.placeholder', {
              defaultValue: '',
            })}
            onChange={value => {
              setFieldValue('contacts.phone', value.join(','))
            }}
            values={values.contacts.phone.split(',')}
          />
        </Field>
        <Field
          label={t('field.contacts.email.label', { defaultValue: 'Email' })}
        >
          <InputText
            {...bindInput(`contacts.email`)}
            type="email"
            placeholder={t('field.contacts.email.placeholder', {
              defaultValue: 'Enter you email.',
            })}
          />
        </Field>
        <Field
          label={t('field.contacts.fanpage.label', { defaultValue: 'Fanpage' })}
        >
          <div className="field has-addons">
            {/* <div className="control">
              <div className="button is-static">https://www.facebook.com/</div>
            </div> */}
            <InputText
              {...bindInput(`contacts.fanpage`)}
              placeholder={t('field.contacts.fanpage.placeholder', {
                defaultValue: 'Enter your fanpage.',
              })}
              onChange={e => {
                const value = transformSocialLink(e.target.value)
                setFieldValue('contacts.fanpage', value)
              }}
            />
          </div>
        </Field>
        <Field
          label={t('field.contacts.fbPageId.label', {
            defaultValue: 'FB Page ID',
          })}
        >
          <InputText
            {...bindInput(`contacts.fbPageId`)}
            placeholder={t('field.contacts.fbPageId.placeholder', {
              defaultValue: 'Enter your facebook page id',
            })}
          />
        </Field>
        <Field
          label={t('field.contacts.instragramId.label', {
            defaultValue: 'Instragram ID',
          })}
        >
          <div className="field has-addons">
            {/* <div className="control">
              <div className="button is-static">https://www.instagram.com/</div>
            </div> */}
            <InputText
              {...bindInput(`contacts.instragramId`)}
              placeholder={t('field.contacts.instragramId.placeholder', {
                defaultValue: 'Enter your instragram id.',
              })}
              onChange={e => {
                const value = transformSocialLink(e.target.value)
                setFieldValue('contacts.instragramId', value)
              }}
              value={values.contacts.instragramId}
            />
          </div>
        </Field>
        <Field
          label={t('field.contacts.lineId.label', { defaultValue: 'Line ID' })}
        >
          <InputText
            {...bindInput(`contacts.lineId`)}
            placeholder={t('field.contacts.lineId.placeholder', {
              defaultValue: 'Enter your line id.',
            })}
          />
        </Field>
        <Field
          label={t('field.contacts.webpage.label', { defaultValue: 'Webpage' })}
        >
          <InputText
            {...bindInput(`contacts.webpage`)}
            placeholder={t('field.contacts.webpage.placeholder', {
              defaultValue: 'Enter your webpage',
            })}
          />
        </Field>
        <Field
          label={t('field.contacts.others.label', { defaultValue: 'Others' })}
        >
          <InputText
            {...bindInput(`contacts.others.${language}`)}
            placeholder={t('field.contacts.others.placeholder', {
              defaultValue: 'Enter your others',
            })}
          />
        </Field>
      </div>

      <ActionPanelLayout>
        <div className="buttons is-hidden-mobile">
          <div className="button is-default" onClick={onPrev}>
            <Trans i18nKey="actions.back.label" defaults="Back" />
          </div>
          <input
            className="button is-primary"
            type="submit"
            value={t('actions.next.label', { defaultValue: 'Next' })}
          />
          {isValues && (
            <input
              className="button is-link"
              type="submit"
              value={t('actions.skip.label', { defaultValue: 'Skip' })}
            />
          )}
        </div>

        <div className="is-hidden-tablet">
          <div className="columns is-gapless is-mobile">
            <div className="column">
              <div
                className="button is-medium is-default is-fullwidth"
                onClick={onPrev}
              >
                <Trans i18nKey="actions.back.label" defaults="Back" />
              </div>
            </div>
            <div className="column">
              <input
                className="button is-medium is-primary is-fullwidth"
                type="submit"
                value={t('actions.next.label', { defaultValue: 'Next' })}
              />
            </div>
            {isValues && (
              <div className="column">
                <input
                  className="button is-medium is-link is-fullwidth"
                  type="submit"
                  value={t('actions.skip.label', { defaultValue: 'Skip' })}
                />
              </div>
            )}
          </div>
        </div>
      </ActionPanelLayout>
    </form>
  )
}

ContactPage.propTypes = {
  ...stepContentPagePropTypes,
}
