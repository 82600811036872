import logger from './services/logger'
import _ from 'lodash'

let isProd = process.env.NODE_ENV === 'production'
var providerContent = process.env.REACT_APP_PROVIDER_NAME || 'dev'
export const SERVER_PORT = process.env.REACT_APP_SERVER_PORT || '8000'

export const serverURL = createRemoteURL()

const config = {
  providerContent,
  isMobile: isMobile(),
  imageURLPrefix: `${serverURL}/images`,
  elasticSearchEndpoint: isProd
    ? 'https://elasticsearch.allly.co'
    : 'http://localhost:9200',
  apiEndpoint: `${serverURL}/api`,
  supportedLanguages: ['th', 'en', 'zh'],
  isProd,
  firebaseApi: {
    apiKey: 'AIzaSyDRgcLv6tbMHDdV5Wyo1i-EJshNPSrTzmA',
    authDomain: 'town-portal-5c0fc.firebaseapp.com',
    databaseURL: 'https://town-portal-5c0fc.firebaseio.com',
    projectId: 'town-portal-5c0fc',
    storageBucket: 'town-portal-5c0fc.appspot.com',
    messagingSenderId: '899041326187',
  },
  elasticIndexes: {
    portalIndex:
      providerContent === 'glog' ? 'portal' : `portal_${providerContent}`,
    listingIndex:
      providerContent === 'glog' ? 'listing' : `listing_${providerContent}`,
    townIndex: providerContent === 'glog' ? 'town' : `town_${providerContent}`,
  },
  fbApi: {
    appID: '1602653736451375',
  },
  googleApi: {
    ga: 'UA-144253841-1',
    mapKey: 'AIzaSyDSxQItpddB64QqM3i47x-C3nOJ9OUvlAk',
  },
  adminTowns: ['glog', 'draft', 'dev', 'agrifair'],
}

logger.setDisable(true)
console.info('server provider content :' + providerContent)
console.info('server url :' + serverURL)

export default config

export function setIsProd(_isProd) {
  isProd = _isProd
}

export function setProviderContent(_providerContent) {
  providerContent = _providerContent
  config.providerContent = _providerContent
}

export function createRemoteURL(url = '', provider = providerContent) {
  const _provider =
    _.isEmpty(provider) || provider === 'undefined' ? providerContent : provider
  logger.debug(`https://${_provider}.allly.co${url}`)
  return isProd
    ? `https://${_provider}.allly.co${url}`
    : `http://localhost:${SERVER_PORT}${url}`
}

export function isMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}
