import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DistanceView = ({ onInputChange, value }) => {
  const { t } = useTranslation();

  const renderDisplayDistance = range => {
    if (range == 0) return `0 ${t("unit.kilometers.label")}`
    else if (range <= 100) return `${range} ${t("unit.kilometers.label")}`;
    else return `${t("field.location.searchAll.label")}`;
  };
  return (
    <SliderLayOut>
      <span className="title is-3">{t("filter.distance.label")}</span>
      <input
        className="slider is-fullwidth"
        value={value}
        onChange={e => {
          onInputChange(e);
        }}
        step={10}
        min="0"
        max="110"
        type="range"
      />
      <BoxLayOut>
        <BoxDistance>
          <FilterDetail>
            <p>{t("filter.distance.label")}</p>
            <p style={{paddingLeft: '1em'}}>{renderDisplayDistance(value)}</p>
          </FilterDetail>
        </BoxDistance>
      </BoxLayOut>
    </SliderLayOut>
  );
};

const BoxLayOut = styled.div`
  -webkit-box-pack: center !important;
  -webkit-box-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 28px !important;
`;

const BoxDistance = styled.div`
  position: relative !important;
  display: flex !important;
  height: 56px !important;
  width: 100% !important;
  color: rgb(34, 34, 34) !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(176, 176, 176) 0px 0px 0px 1px inset !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  margin: 0px !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  border-image: initial !important;
  border-radius: 8px !important;
`;

const FilterDetail = styled.div`
  padding: 8px;
`;

const SliderLayOut = styled.div`
  padding: 1em;
  width: 100%;
  .slider {
    width: 100%;
    -webkit-appearance: none;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
      overflow: hidden;
      /* width: 80px; */
      -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
      height: 10px;
      cursor: pointer;
      background: black;
      box-shadow: -100vw 0 0 100vw #0f8fff;
    }
  }
`;

export default DistanceView;
