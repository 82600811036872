import React from 'react'

export default function InputFileBox({ onChange, inputProps = {} }) {
  return (
    <div className="__input-file-box file is-boxed">
      <label className="file-label">
        <input
          className="file-input"
          id="__upload-photo"
          type="file"
          name="file"
          onChange={onChange}
          {...inputProps}
        />
        <span className="file-cta">
          <span className="file-icon">
            <i className="fas fa-upload" />
          </span>
          <span className="file-label">Choose a file…</span>
        </span>
      </label>
    </div>
  )
}
