import queryString from 'querystring'
import _ from 'lodash'
import { clone, setWith, curry } from 'lodash/fp'

export function getParams(q) {
  return queryString.parse(_.replace(q, '?', ''))
}

export function transformSocialLink(link) {
  return _.chain(link)
    .split('.com/')
    .last()
    .value()
}

export const setIn = curry((path, value, obj) =>
  setWith(clone, path, value, clone(obj)),
)

if (typeof window !== 'undefined') {
  window.setIn = setIn
}
