import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default function TownListCard({ town }) {
  return (
    <TownListCardLayout to={`/towns/${town._id}`}>
      <div className="card">
        <div className="card-image">
          <figure className="image is-16by9">
            <img src={town.imageURL} alt={town.title} />
          </figure>
        </div>
        <div className="card-tag-layout">
          <div className="card-tag-content heading">{town.title}</div>
        </div>
        {/* <div className="card-content">
      <div className="media">
        <div className="media-content">
          <p className="title is-4">John({index})</p>
          <p className="subtitle is-6">@johnsmith</p>
        </div>
      </div>
    </div> */}
      </div>
    </TownListCardLayout>
  )
}

const TownListCardLayout = styled(Link)`
  .card {
    margin: 6px;
    margin-bottom: 16px;
  }

  img {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  .card-tag-layout {
    /* height: 20px; */
    display: flex;
    justify-content: center;
  }

  .card-tag-content {
    position: absolute;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 1.2em;
    margin-top: -14px;

    display: flex;
    justify-content: center;
    justify-items: center;
    background: rgb(0, 0, 0, 0.8);
    color: white;
  }
`
