import React from "react";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageGallery from "react-image-gallery";
import moment from "moment";

import styled from "styled-components";
import HeaderContent from "../../views/widgets/HeaderContent";
import Content from "../../views/widgets/Content";
import EditorGalleryImageBox from "../editor-modal/EditorGalleryImageBox";
import EditorWithAuthHandler from "../editor/EditorWithAuthHandler";
import { useTranslation } from "react-i18next";

export default function PortalGalleryImages({ galleryImages }) {
  return (
    <EditorWithAuthHandler
      renderCustomEditor={() => (
        <GalleryImagesWithEditor galleryImages={galleryImages} />
      )}
    >
      <GalleryImages galleryImages={galleryImages} />
    </EditorWithAuthHandler>
  );
}

function GalleryImages({ galleryImages }) {
  const nowDate = moment().format("YYYY-MM-DD");
  const filterExp = _.filter(galleryImages, it => {
    if (it.expDate)
      return moment(nowDate).isSameOrBefore(
        moment(it.expDate).format("YYYY-MM-DD")
      );
    else return it;
  });
  const mainGalleryImages = [
    ..._.slice(filterExp, 0, 9),
    ..._.filter(_.slice(filterExp, 10, _.size(filterExp)), it =>
      _.isEqual(_.get(it, "type"), "video")
    )
  ];

  const moreGalleryImages = _.chain(filterExp)
    .slice(10, _.size(filterExp))
    .filter(it => !_.isEqual(_.get(it, "type"), "video"))
    .map(galleryImage => {
      return {
        original: galleryImage.imageURL,
        thumbnail: galleryImage.smallImageURL
      };
    })
    .value();

  return (
    <>
      {_.map(mainGalleryImages, (galleryImage, index) => (
        <GalleryImage key={index} galleryImage={galleryImage} index={index} />
      ))}
      {!_.isEmpty(moreGalleryImages) && (
        <ImageGallery items={moreGalleryImages} />
      )}
    </>
  );
}

function GalleryImage({ galleryImage }) {
  const { t } = useTranslation();
  const title = _.get(galleryImage, `title`);
  const description = _.get(galleryImage, `description`);
  const externalUrl = _.get(galleryImage, "externalUrl");
  const type = _.get(galleryImage, "type");
  const imageURL =
    _.get(galleryImage, "imageURL") + `?${new Date().toISOString()}`;
  return (
    <>
      {type !== "video" && (
        <div className="has-text-centered">
          <LazyLoadImage
            src={`${imageURL}`}
            alt={title || imageURL}
            effect="blur"
          />
        </div>
      )}
      {type !== "video" &&
        (!_.isEmpty(title) ||
          (type == "picture" ? false : !_.isEmpty(externalUrl))) && (
          <SectionLayOut className="section">
            <div class="columns is-gapless is-vcentered is-multiline is-mobile">
              <div class="column is-10">
                {!_.isEmpty(title) && <HeaderContent>{title}</HeaderContent>}
                {_.isEmpty(title) &&
                  !_.isEmpty(externalUrl) &&
                  type == "picture_link" && (
                    <HeaderContent>
                      {t("field.galleryImage.placeholder.label")}
                    </HeaderContent>
                  )}
              </div>
              <div class="column is-2" style={{ textAlign: "right" }}>
                {!_.isEmpty(externalUrl) && type == "picture_link" && (
                  <button
                    className="button is-link is-small"
                    style={{ width: "100%" }}
                    onClick={e => {
                      e.stopPropagation();
                      window.open(externalUrl, "_blank");
                    }}
                  >
                    <i class="fas fa-link"></i>&nbsp;Link
                  </button>
                )}
              </div>
            </div>
            {!_.isEmpty(description) && <Content>{description}</Content>}
          </SectionLayOut>
        )}
      {type == "video" && (
        <IframeLayOut
          width="100%"
          src={externalUrl}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></IframeLayOut>
      )}
    </>
  );
}

const IframeLayOut = styled.iframe`
  height: 30em !important;
`;

const SectionLayOut = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 3rem 0rem 3rem 0rem !important;
  }
`;

function GalleryImagesWithEditor({ galleryImages }) {
  return (
    <>
      {_.map(galleryImages, (galleryImage, index) => (
        <GalleryImageWithEditor
          key={index}
          galleryImage={galleryImage}
          index={index}
        />
      ))}
      <EditorGalleryImageBox isAdd />
    </>
  );
}

function GalleryImageWithEditor({ galleryImage, index }) {
  return (
    <EditorWithAuthHandler
      editorComponent={EditorGalleryImageBox}
      params={{ index: index + 1 }}
    >
      <GalleryImage galleryImage={galleryImage} />
    </EditorWithAuthHandler>
  );
}
