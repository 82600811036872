import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import InputText from './InputText'
import { getSuggestStatus, getSuggestStatusText } from './inputUtils'

const InputTextWithSuggestion = ({
  children,
  value: _value,
  suggestMinLength = 50,
  onChange,
  onBlur,
  ...props
}) => {
  const [value, setValue] = React.useState(_value)
  React.useEffect(() => {
    setValue(_value)
  }, [_value])

  function handleChange(e) {
    setValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }

  function handleBlur(e) {
    setValue(e.target.value)
    if (onBlur) {
      onBlur(e)
    }
  }

  const currentLength = _.size(value)
  const status = getSuggestStatus(currentLength, suggestMinLength)
  const statusMessage = getSuggestStatusText(
    status,
    currentLength,
    suggestMinLength,
  )

  return (
    <div className="field has-addons full-width">
      <InputText
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        status={status}
        statusMessage={statusMessage}
        {...props}
      />
    </div>
  )
}

InputTextWithSuggestion.propType = {
  suggestMinLength: PropTypes.number.isRequired,
}

export default InputTextWithSuggestion
