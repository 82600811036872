import React from 'react';
import styled from 'styled-components';

export default function Content({ children }) {
  return (
    <ContentLayout className="subtitle is-5" style={{ whiteSpace: 'pre-wrap' }}>
      &emsp;&emsp;{children}
    </ContentLayout>
  );
}

const ContentLayout = styled.p`
  font-family: 'CSPrajad';
`;
