import React from 'react';

export default function NotFoundPage() {
  return (
    <section className="hero is-fullheight is-info is-bold">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-1">404 :(</h1>
          <h2 className="subtitle is-3">PAGE NOT FOUND</h2>
        </div>
      </div>
    </section>
  );
}
