import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import usePromise from 'react-use-promise'

import { getListingByID, getPortalList } from '../services/elasticService'
import Meta from '../views/widgets/Meta'
import CardWithPlaceholder from '../views/elements/CardWithPlaceholder'
import PortalListCard from '../templates/portal-list/PortalListCard'
import ListingListImageHeaderWithSearchBar from '../templates/listing-list/ListingListImageHeaderWithSearchBar'
import ListingListTagGroup from '../templates/listing-list/ListingListTagGroup'
import useInfiniteScroll from '../hooks/useInfiniteScroll'
import { translate } from '../services/lang'
import { tranformToImageURL } from '../services/remoteUtils'

export default function ListingListPage({ location, match, language = 'th' }) {
  const [page, setPage] = React.useState(0)
  const [portals, setPortals] = React.useState([])
  const [startAt, setStartAt] = React.useState(0)
  const [listing, , state] = usePromise(
    () => getListingByID(query.listingID),
    [],
  )
  const isLoading = state === 'pending'
  const [isFetching, setIsFetching, isMore, setIsMore] = useInfiniteScroll(
    fethMore,
  )

  const query = match.params || {}

  React.useEffect(() => {
    setIsMore(true)
    setIsFetching(true)
    setPage(0)
    setStartAt(0)
    setPortals([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.tags, query.title])

  function fethMore() {
    const limit = page === 0 ? 11 : 8
    getPortalList({ listingRef: query.listingID }, { startAt, limit }).then(
      _portals => {
        if (!_.isEmpty(_portals)) {
          const portalMerged = [...portals, ..._portals]
          setPage(page + 1)
          setPortals(tranformToImageURL(translate(portalMerged, language)))
          setStartAt(_.size(portalMerged))
        } else {
          setIsMore(false)
        }
        setIsFetching(false)
      },
    )
  }

  let renderPlaceholder

  if (isFetching && isMore) {
    renderPlaceholder = _.isEmpty(portals) ? (
      <>
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
      </>
    ) : (
      <CardWithPlaceholder />
    )
  }

  if (_.isEmpty(portals) && !isLoading) {
    renderPlaceholder = (
      <div className="heading has-text-centered" style={{ margin: 16 }}>
        empty
      </div>
    )
  }

  const _listing = tranformToImageURL(translate(listing, language), 'images')

  return (
    <div className="">
      <Meta
        title={_listing.title}
        imageURL={_listing.imageURL || `/__images/logo.png`}
        url={window.location.href}
      />
      <ListingListImageHeaderWithSearchBar
        listingID={query.listingID}
        imageUrl={_listing.imageURL}
        title={_listing.title}
        description={_listing.description}
      />
      <div className="is-centered container">
        <ListingListTagGroup tags={_listing.tags} listing={listing} />
        <ListingLayout>
          {_.map(portals, portal => (
            <React.Fragment key={portal._id}>
              <PortalListCard portal={portal} />
              <br />
            </React.Fragment>
          ))}
          {renderPlaceholder}
        </ListingLayout>
      </div>
    </div>
  )
}

const ListingLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`
