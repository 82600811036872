import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ActionPanelLayout from '../../views/widgets/ActionPanelLayout'
import PortalListCardEditor from '../../templates/portal-list/PortalListCardEditor'
import { stepContentPagePropTypes } from '../../constants/prop-types'
import { tranformToImageURL } from '../../services/remoteUtils'
import { translate } from '../../services/lang'

export default function FinishPage({ initialValues, onAfterValid }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const portal = tranformToImageURL(translate(initialValues, language))

  function handleClickFinish(action) {
    onAfterValid(initialValues, action)
  }

  return (
    <FinishPageStyle>
      <h3 className="title is-3 has-text-centered">
        <Trans i18nKey="app.completed.label" defaults="Completed" />
      </h3>
      <div className="subtitle is-5 has-text-centered">
        <Trans
          i18nKey="completion.description.label"
          values={{
            title: t('app.title.label', { defaultValue: 'Glog' }),
          }}
          defaults="This is your initial {{title}}."
        />
      </div>
      <div className="__list-card-section">
        <PortalListCardEditor portal={portal} />
      </div>
      <div className="subtitle is-5 has-text-centered">
        <Trans
          i18nKey="completion.description2.label"
          values={{
            buttonName: t('actions.edit.more.label', {
              defaultValue: 'Edit More',
            }),
          }}
          defaults='To make your information more complete, choose "{{buttonName}}"'
        />
        <br />
        “<Trans i18nKey="actions.edit.more.label" defaults="Edit More" />”
      </div>
      <ActionPanelLayout>
        <div className="buttons is-hidden-mobile __is-center">
          <div
            className="button is-default"
            onClick={() => handleClickFinish('back_to_main')}
          >
            <Trans i18nKey="actions.backToMain.label" defaults="Back to Main" />
          </div>
          <div
            className="button is-warning"
            onClick={() => handleClickFinish('edit_more')}
          >
            <Trans i18nKey="actions.edit.more.label" defaults="Edit More" />
          </div>
        </div>

        <div className="is-hidden-tablet">
          <div className="columns is-gapless is-mobile">
            <div className="column">
              <div
                className="button is-medium is-default is-fullwidth"
                onClick={() => handleClickFinish('back_to_main')}
              >
                <Trans
                  i18nKey="actions.backToMain.label"
                  defaults="Back to Main"
                />
              </div>
            </div>
            <div className="column">
              <div
                className="button is-medium is-warning is-fullwidth"
                onClick={() => handleClickFinish('edit_more')}
              >
                <Trans i18nKey="actions.edit.more.label" defaults="Edit More" />
              </div>
            </div>
          </div>
        </div>
      </ActionPanelLayout>
    </FinishPageStyle>
  )
}

FinishPage.propTypes = {
  ...stepContentPagePropTypes,
}

const FinishPageStyle = styled.div`
  .card {
    width: 220px !important;
  }

  .__list-card-section {
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  .subtitle {
    color: gray;
  }
`
