import React from 'react'
import _ from 'lodash'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import styled from 'styled-components'
import logger from '../../services/logger';

function setRotate(imageElement, ctx, orientation) {
  switch (orientation) {
    case 2:
      // horizontal flip
      ctx.translate(imageElement.width, 0)
      ctx.scale(-1, 1)
      break
    case 3:
      // 180° rotate left
      ctx.translate(imageElement.width, imageElement.height)
      ctx.rotate(Math.PI)
      break
    case 4:
      // vertical flip
      ctx.translate(0, imageElement.height)
      ctx.scale(1, -1)
      break
    case 5:
      // vertical flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI)
      ctx.scale(1, -1)
      break
    case 6:
      // 90° rotate right
      ctx.rotate(0.5 * Math.PI)
      ctx.translate(0, -imageElement.height)
      break
    case 7:
      // horizontal flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI)
      ctx.translate(imageElement.width, -imageElement.height)
      ctx.scale(-1, 1)
      break
    case 8:
      // 90° rotate left
      ctx.rotate(-0.5 * Math.PI)
      ctx.translate(-imageElement.width, 0)
      break
    default:
      break
  }
}

function fixedOrientation(file, fileName) {
  return new Promise((resolve, reject) => {
    getOrientation(file, orientation => {
      logger.debug('orientation', orientation)
      if (orientation <= 1) {
        resolve(file)
      } else {
        // 90° rotate right ios device
        resolve(getRotateFile(file, fileName))
      }
    })
  })
}

function getCroppedImg(imageFile, pixelCrop, fileName) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    var imageElement = new Image()

    logger.debug(pixelCrop.width, pixelCrop.height)

    imageElement.onload = function() {
      // alert(`x,y,w,h (${pixelCrop.x},${pixelCrop.y},${pixelCrop.width},${pixelCrop.height})`)

      ctx.drawImage(
        imageElement,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      )
      ctx.restore()

      // As a blob
      if (canvas.toBlob) {
        canvas.toBlob(file => {
          file.name = fileName
          resolve(file)
        }, 'image/jpeg')
      } else {
        window.alert('No Canvas')
      }
    }
    imageElement.src = URL.createObjectURL(imageFile)
  })

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg')
  // return base64Image
}

function getRotateFile(imageFile, fileName) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    var imageElement = new Image()
    imageElement.onload = function() {
      const { width, height } = imageElement

      canvas.height = width
      canvas.width = height

      const ctx = canvas.getContext('2d')
      setRotate(imageElement, ctx, 6)
      ctx.drawImage(imageElement, 0, 0)
      ctx.restore()

      // As a blob
      canvas.toBlob(file => {
        file.name = fileName
        resolve(file)
      }, 'image/jpeg')
    }
    imageElement.src = URL.createObjectURL(imageFile)
  })
}

const defaultAspect = 4 / 3

class ImageCrop extends React.Component {
  state = {
    originalImageFile: undefined,
    croppedImageFile: undefined,
    previewImage: undefined,
    crop: {
      aspect: this.props.imageAspectRatio || defaultAspect,
    },
  }
  handleChange = crop => {
    this.setState({
      crop,
    })

    if (crop.height === 0 || crop.width === 0) {
      this.setState({
        croppedImageFile: undefined,
      })

      if (this.props.onImageCrop) {
        this.props.onImageCrop(undefined)
      }
    }
  }

  onImageLoaded = image => {
    /*
      x, y มีหน่วยเป็น % 
      โดย ตย. เป็น 16:9

      16 / 9   = aspectWidth/heightRatio
      100 / 56 = aspectWidth/heightRatio

      1) การหาขนาด height ที่ได้จากที่ crop ซึ่งมีหน่วยเป็น % (croppedPercentageHeight)
        let aspect = 16 / 9
        - (1) เริ่มด้วยหาขนาด height ที่ได้จาการ crop จริง  (croppedOriginalHeight) 
          croppedOriginalHeight / croppedOriginalWidth = 9 / 16
          croppedOriginalHeight = (9 / 16) * croppedOriginalWidth
          croppedOriginalHeight = croppedOriginalWidth / aspect

        - (2) เปรียบเทียบ ratio ระหว่างขนาด height จริงกับ หน่วย % 
              เพื่อหา height การ crop ที่เป็น % (croppedPercentageHeight)
          originalHeight / croppedOriginalHeight = percentageHeight / croppedOriginalHeight%
          croppedPercentageHeight= (percentageHeight * croppedOriginalHeight )/ originalHeight
          croppedPercentageHeight= (100 * croppedOriginalWidth)/ (aspect * originalHeight)

          let orighinalWidth = croppedOriginalWidth
          let originalAspectRatio = orighinalWidth / originalHeight

          croppedPercentageHeight = (originalAspectRatio * 100)/ aspect 

      2) หาตำแหน่ง y ค่ามากสุด 100% โดยจะทำให้ กรอบการ crop อยู่ตรงกลาง 
      - หาได้ด้วยการคำนวณจาก ครึ่งนึงของการ crop ของความสูง 100%
       ครึ่งนึงของการ crop ของความสูง 100% = croppedPercentageHeight/ 2

       ตำแหน่ง  y = ครึ่งนึ่งของ y หักด้วย ครึ่งนึงของการ crop
                y = 50  - croppedPercentageHeight/ 2
    */

    const aspect = this.props.imageAspectRatio || defaultAspect
    const { naturalHeight, naturalWidth } = image
    const originalAspectRatio = naturalWidth / naturalHeight

    // const croppedPercentageHeight = originalAspectRatio * 100 / aspect
    // const crop = {
    //   x: 0,
    //   y: 50 - croppedPercentageHeight / 2,
    //   width: 100,
    //   height: croppedPercentageHeight,
    //   aspect,
    // }

    const cropWidthByCropHeight = (cropHeight, aspect, originalAspectRatio) =>
      (cropHeight * aspect) / originalAspectRatio
    const cropHeightByCropWidth = (cropWidth, aspect, originalAspectRatio) =>
      (cropWidth * originalAspectRatio) / aspect

    let cropWidth
    let cropHeight
    if (aspect >= 1) {
      if (originalAspectRatio > aspect) {
        cropHeight = 100
        cropWidth = cropWidthByCropHeight(
          cropHeight,
          aspect,
          originalAspectRatio,
        )
      } else {
        cropWidth = 100
        cropHeight = cropHeightByCropWidth(
          cropWidth,
          aspect,
          originalAspectRatio,
        )
      }
    } else {
      if (originalAspectRatio >= aspect) {
        cropHeight = 100
        cropWidth = cropWidthByCropHeight(
          cropHeight,
          aspect,
          originalAspectRatio,
        )
      } else {
        cropWidth = 100
        cropHeight = cropHeightByCropWidth(
          cropWidth,
          aspect,
          originalAspectRatio,
        )
      }
    }
    const crop = {
      x: (100 - cropWidth) / 2,
      y: (100 - cropHeight) / 2,
      width: cropWidth,
      height: cropHeight,
      aspect,
    }

    this.setState({
      crop,
      pixelCrop: {
        x: (crop.x * image.naturalWidth) / 100,
        y: (crop.y * image.naturalHeight) / 100,
        width: (crop.width * image.naturalWidth) / 100,
        height: (crop.height * image.naturalHeight) / 100,
      },
    })
  }

  onCropComplete = (crop, pixelCrop) => {
    this.setState({ pixelCrop })
  }

  handleCropCancel = () => {
    if (this.props.onImageCropCancel) {
      this.props.onImageCropCancel()
    }
  }

  handleCropReset = () => {
    this.setState({
      croppedImageFile: undefined,
      previewImage: this.createImageURL(this.state.originalImageFile),
    })

    if (this.props.onImageCrop) {
      this.props.onImageCrop(undefined)
    }
  }

  handleRotate = async () => {
    const {
      originalImageFile,
      croppedImageFile: _croppedImageFile,
    } = this.state
    let currentImageFile = _croppedImageFile || originalImageFile
    logger.debug(
      'croppedImage, originalImageFile',
      _croppedImageFile,
      originalImageFile,
    )
    let croppedImageFile = await getRotateFile(
      currentImageFile,
      originalImageFile.name,
    )
    this.setState({
      previewImage: this.createImageURL(croppedImageFile),
      croppedImageFile,
    })

    if (this.props.onImageCrop) {
      this.props.onImageCrop(undefined)
    }
  }

  handleCrop = async () => {
    const {
      originalImageFile,
      croppedImageFile: _croppedImageFile,
      pixelCrop,
    } = this.state

    let croppedImageFile
    if (pixelCrop.height === 0 || pixelCrop.width === 0) {
      croppedImageFile = undefined
      alert('drag image to crop')
    } else {
      croppedImageFile = _croppedImageFile || originalImageFile
      croppedImageFile = await getCroppedImg(
        croppedImageFile,
        pixelCrop,
        originalImageFile.name,
      )
      this.setState({
        previewImage: this.createImageURL(croppedImageFile),
      })
    }

    this.setState({
      croppedImageFile,
    })

    if (this.props.onImageCrop) {
      this.props.onImageCrop(croppedImageFile)
    }
  }

  isCroppedImageFile = () => {
    return !_.isUndefined(this.state.croppedImageFile)
  }

  createImageURL = imageFile => {
    return URL.createObjectURL(imageFile)
  }

  componentDidMount() {
    logger.debug('imageFile', this.props.imageFile)
    this._initialImage()
  }

  compoentWillReceiveProps(nextProps) {
    if (this.props.imageFile !== nextProps.imageFile) {
      this._initialImage()
    }
  }

  _initialImage = async () => {
    const image = await fixedOrientation(
      this.props.imageFile,
      this.props.imageFile.fileName,
    )
    this.setState({
      originalImageFile: image,
      previewImage: this.createImageURL(image),
    })
  }

  render() {
    if (this.state.previewImage) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <CropController
            isCroppedImageFile={this.isCroppedImageFile()}
            onCrop={this.handleCrop}
            onCropReset={this.handleCropReset}
            onRotate={this.handleRotate}
            onCropCancel={this.handleCropCancel}
          />
          <ImagePreviewLayout>
            <ReactCrop
              imageStyle={{}}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              src={this.state.previewImage}
              crop={this.state.crop}
              onChange={this.handleChange}
            />
          </ImagePreviewLayout>
        </div>
      )
    } else return null
  }
}

const ImagePreviewLayout = styled.div`
  background: rgb(247, 247, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 100%; */
  padding: 16px;
`

const CropController = ({
  isCroppedImageFile,
  onCrop,
  onCropReset,
  onCropCancel,
  onRotate,
}) => (
  <div className="field is-grouped is-grouped-right">
    {isCroppedImageFile && (
      <React.Fragment>
        <div className="control">
          <div className="button is-light" onClick={onCropReset}>
            Reset
          </div>
        </div>
      </React.Fragment>
    )}
    <div className="control">
      <div className="button is-primary" onClick={onRotate}>
        <span className="icon is-small">
          <i className="fas fa-redo" />
        </span>
      </div>
    </div>
    <div className="control">
      <div className="button is-primary" onClick={onCrop}>
        Save/Crop
      </div>
    </div>
  </div>
)

export default ImageCrop

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function(callback, type, quality) {
      var canvas = this
      setTimeout(function() {
        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len)

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    },
  })
}

function getOrientation(file, callback) {
  logger.debug('get orientation', file)

  var reader = new FileReader()
  reader.onload = function(e) {
    var view = new DataView(e.target.result)
    if (view.getUint16(0, false) !== 0xffd8) {
      return callback(-2)
    }
    var length = view.byteLength,
      offset = 2
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
      var marker = view.getUint16(offset, false)
      offset += 2
      if (marker === 0xffe1) {
        if (view.getUint32((offset += 2), false) !== 0x45786966) {
          return callback(-1)
        }

        var little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        var tags = view.getUint16(offset, little)
        offset += 2
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    return callback(-1)
  }
  reader.readAsArrayBuffer(file)
}
