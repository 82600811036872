import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import EditorWithAuthHandler from '../editor/EditorWithAuthHandler'
import EditorOpeningHoursBox from '../editor-modal/EditorOpeningHoursBox'
import { useTranslation, Trans } from 'react-i18next'

export default function PortalOpeningHour({ openingHours }) {
  const { t } = useTranslation()
  return (
    <EditorOpeningHoursStyle>
      <EditorWithAuthHandler editorComponent={EditorOpeningHoursBox}>
        <OpeningHoursInfo
          openingHours={
            _.isEmpty(openingHours)
              ? t('actions.noInformation.label', {
                  defaultValue: 'No Information',
                })
              : openingHours
          }
        />
      </EditorWithAuthHandler>
    </EditorOpeningHoursStyle>
  )
}

function OpeningHoursInfo({ openingHours }) {
  return (
    <>
      <div className="title is-4">
        <Trans i18nKey="app.openingHours.label" defaults="Opening Hours" />
      </div>
      <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'CSPraJad' }}>
        {openingHours}
      </div>
    </>
  )
}

const EditorOpeningHoursStyle = styled.div`
  .__content {
    white-space: pre-wrap;
    font-family: CSPraJad;
  }
`
