import React from "react";
import * as typeFilter from "../../constants/typeFilter";
import styled from "styled-components";
import Filter from "../filter";
import useDropDown from "../../hooks/useDropDown";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import classNames from "classnames";
import { FilterView } from "../filter";
import { ConfirmModal, FooterFilterModal } from "../../hooks/useConfirmModal";
const FilterbarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function FilterbarLayout({
  children,
  onFilterApply,
  onFilterReset,
  resetFilterValue,
  renderDistance,
  renderTags,
  onClose,
  range,
  tags,
  onOpen
}) {
  const { t } = useTranslation();
  const _handleDropdownClose = (position, type) => {
    onClose(position, type);
  };

  const [showModal, hideModal] = useModal(() => {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <ConfirmModal
          params={{
            title: t("app.filter.label", {
              defaultValue: "Filter"
            })
          }}
          onRequestClose={() => {
            resetFilterValue();
            hideModal();
          }}
        >
          <section className="modal-card-body">
            {renderDistance}
            <hr />
            {renderTags}
          </section>
          <FooterFilterModal
            onFilterApply={onFilterApply}
            onFilterReset={onFilterReset}
            onClose={hideModal}
          />
        </ConfirmModal>
      </div>
    );
  }, [range, t, tags]);

  const renderDisplayDistance = range => {
    if (range == 0) return;
    else if (range <= 100) return `${range} ${t("unit.kilometers.label")}`;
    else return `${t("field.location.searchAll.label")}`;
  };
  const renderDisplayTags = tags => {
    return tags.toString();
  };
  return (
    <div>
      <FilterbarContainer className="is-hidden-touch">
        <FilterButtonWithDropdown
          title={t("filter.distance.label")}
          filter={renderDisplayDistance(range)}
          onOpenDropdown={onOpen}
          onCloseDropdown={position => {
            _handleDropdownClose(position, typeFilter.Distance);
          }}
          renderCloseView={
            <FilterView.SubmitButton
              onFilterApply={onFilterApply}
              onFilterReset={onFilterReset}
              type={typeFilter.Distance}
            />
          }
        >
          <div style={{ width: "20rem" }}> {renderDistance} </div>
        </FilterButtonWithDropdown>
        &nbsp;
        <FilterButtonWithDropdown
          title={t("filter.tags.label")}
          filter={renderDisplayTags(tags)}
          onOpenDropdown={onOpen}
          onCloseDropdown={position => {
            _handleDropdownClose(position, typeFilter.Tags);
          }}
          renderCloseView={
            <FilterView.SubmitButton
              onFilterApply={onFilterApply}
              onFilterReset={onFilterReset}
              type={typeFilter.Tags}
            />
          }
        >
          <div style={{ width: "25rem" }}> {renderTags} </div>
        </FilterButtonWithDropdown>
        &nbsp;
      </FilterbarContainer>
      <FilterbarContainer className="is-hidden-desktop">
        <button
          className={classNames("button is-rounded is-small", {
            "is-warning": range !== 0
          })}
          onClick={() => showModal()}
        >
          <span className="icon ">
            <i className="fas fa-filter"></i>
          </span>
          <span style={{ textAlign: "center" }}>{"filter"}</span>
        </button>
      </FilterbarContainer>

      {children}
    </div>
  );
}

const FilterButtonWithDropdown = useDropDown(Filter.Button);
