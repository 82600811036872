import React from 'react'
import classNames from 'classnames'

function handleCondition(isHorizontal, isVerticle) {
  return isHorizontal === undefined
    ? isVerticle === undefined
      ? isVerticle
      : !isVerticle
    : isHorizontal
}

const Field = ({ label, isHorizontal, isVerticle, children, ...props }) => (
  <FieldLayout isHorizontal={handleCondition(isHorizontal, isVerticle)}>
    <FieldLabel isHorizontal={handleCondition(isHorizontal, isVerticle)}>
      {label}
    </FieldLabel>
    <FieldBody>
      <FieldItem>{children}</FieldItem>
    </FieldBody>
  </FieldLayout>
)

export const FieldLayout = ({ children, isHorizontal = true }) => (
  <div className={classNames('field', { 'is-horizontal': isHorizontal })}>
    {children}
  </div>
)

export const FieldLabel = ({ children, isHorizontal = true }) => {
  const label = <label className="label has-text-grey">{children}</label>
  if (isHorizontal) return <div className="field-label is-normal">{label}</div>
  return label
}

export const FieldBody = ({ children }) => (
  <div className="field-body">{children}</div>
)

export const FieldItem = ({ children }) => (
  <div className="field">{children}</div>
)

export default Field
