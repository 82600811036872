import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'

export default function PortalTags({ tags }) {
  if (_.isEmpty(tags)) {
    return (
      <div className="has-text-centered">
        <Trans i18nKey="app.tags.label" defaults="Tags" /> :{' '}
        <Trans
          i18nKey="actions.noInformation.label"
          defaults="No Information"
        />
      </div>
    )
  }

  return (
    <div className="has-text-centered">
      <Trans i18nKey="app.tags.label" defaults="Tags" /> :
      {_.map(tags, (tag, key) => (
        <Link
          key={key}
          className="button is-small"
          style={{ marginLeft: 6, marginBottom: 6 }}
          to={`/portals?tags=${window.encodeURIComponent(tag)}`}
        >
          {tag}
        </Link>
      ))}
    </div>
  )
}
