import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import * as TYPE from "../../constants/typeGalleryImage";
import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";

import useForm from "../../hooks/useForm";
import Field from "../../views/widgets/Field";
import InputFileBox from "../../views/widgets/InputFileBox";
import { getDefaultLangText } from "../../constants/utils";
import ConfirmModalContext from "../../contexts/ConfirmModalContext";
import PortalContext from "../../contexts/PortalContext";
import { FooterConfirmModal } from "../../hooks/useConfirmModal";
import { updatePortal } from "../../services/remoteService";
import InputTextarea from "../../views/widgets/InputTextarea";
import InputText from "../../views/widgets/InputText";
import useImageCropEditModal from "../../modules/image-crop/useImageCropEditModal";
import {
  uploadPortalImage,
  removePortalImage
} from "../../services/remoteImageService";
import SelectItem from "../../views/elements/SelectItem";

export function getDefaultGalleryImage() {
  return {
    title: getDefaultLangText(),
    description: getDefaultLangText()
  };
}

export function mergeWithGalleryImages(
  galleryImages = [],
  galleryImage,
  index
) {
  if (!_.isNumber(index)) {
    galleryImages.push(galleryImage);
  } else {
    galleryImages = _.map(galleryImages, (_galleryImage, _index) => {
      if (_index === index) {
        return galleryImage;
      }
      return _galleryImage;
    });
  }
  return galleryImages;
}

export function showDisplayDate(expDate, t) {
  if (moment(expDate).diff(moment(), "hours") >= 0)
    if (moment(expDate).diff(moment(), "days") !== 0)
      return `${t("field.galleryImage.remain.label")} : ${moment(expDate).diff(
        moment(),
        "days"
      )} ${t("field.galleryImage.days.label")}`;
    else
      return `${t("field.galleryImage.remain.label")} : ${moment(expDate).diff(
        moment(),
        "hours"
      )} ${t("field.galleryImage.hours.label")}`;
  else if (moment(expDate).diff(moment(), "hours") < 0)
    return t("field.galleryImage.expired.label");
}

export default function EditorGalleryImageBox({
  children,
  isAdd,
  params = {}
}) {
  const { t } = useTranslation();
  const { showModal } = React.useContext(ConfirmModalContext);
  const portalGallery = _.get(children, "props.galleryImage");
  const nowDate = moment().format("YYYY-MM-DD");
  const expDate = moment(_.get(portalGallery, "expDate")).format("YYYY-MM-DD");
  const isExp = moment(nowDate).isSameOrBefore(expDate);

  return (
    <EditorGalleryImageBoxStyle
      isAdd={isAdd}
      onClick={() =>
        showModal({
          params: {
            title: isAdd
              ? t("actions.add.label", {
                  title: t("app.galleryImage.label", {
                    defaultValue: "Content"
                  }),
                  defaultValue: "Add {{title}}"
                })
              : t("actions.edit.label", {
                  title: t("app.galleryImage.label", {
                    defaultValue: "Content"
                  }),
                  defaultValue: "Edit {{title}}"
                }),
            isAdd,
            ...params
          },
          modalRef: "EDITOR_GALLERY_IMAGE_MODAL"
        })
      }
    >
      <ExpireDate>
        {_.get(portalGallery, "expDate") && (
          <small
            className={classNames("title is-5 ", {
              "has-text-grey": isExp,
              "has-text-danger": !isExp
            })}
          >
            {t("field.galleryImage.expDate.label")} :{"  "}
            {moment(_.get(portalGallery, "expDate")).format("L") || ""}
            <br className="is-hidden-desktop" /> ({" "}
            {showDisplayDate(_.get(portalGallery, "expDate"), t)} )
          </small>
        )}
      </ExpireDate>
      {children}

      <span className="icon">
        {isAdd ? (
          <i className="fas fa-plus-circle fa-3x" />
        ) : (
          <i className="fas fa-edit fa-lg fa-lg" />
        )}
      </span>
    </EditorGalleryImageBoxStyle>
  );
}

const ExpireDate = styled.div`
  padding-bottom: 10px;
`;
const EditorGalleryImageBoxStyle = styled.div.attrs({
  className: "__editor-box __highlight-editor-box __editor-cover-info-box"
})`
  ${props =>
    props.isAdd &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  .icon {
    ${props =>
      !props.isAdd
        ? css`
            background: #7a7a7a99;
            border-radius: 8px;
            padding: 16px;
            color: white;
          `
        : css`
            top: unset;
            right: unset;
          `}
  }
`;

/**
|--------------------------------------------------
| MODAL
|--------------------------------------------------
*/

export function EditorGalleryImageModal({ params = {}, onClose }) {
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const { portal, updateLocalData } = React.useContext(PortalContext);
  const portalID = _.get(portal, "_id");
  const galleryImage = _.get(portal, `galleryImages[${params.index}]`) || {};

  const [imageURL, setImageURL] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [typePicture, setTypePicture] = React.useState(
    TYPE.typeGalleryImageOptions
  );
  const [nowDate, setNowDate] = React.useState(moment().format("YYYY-MM-DD"));
  const { values, errors, setFieldValue, handleChange, handleSubmit } = useForm(
    {
      initialState: {
        title: getDefaultLangText(),
        description: getDefaultLangText(),
        expDate: null,
        type: typePicture[0].value,
        externalUrl: "",
        ...galleryImage
      },
      onValidate: result => {
        const errors = {};
        if (
          _.isEmpty(result.values.externalUrl) &&
          !_.isEqual(result.values.type, "picture")
        ) {
          errors.externalUrl = "required";
        }
        return errors;
      },
      onSubmit: galleryImage => {
        const galleryImages = mergeWithGalleryImages(
          _.get(portal, "galleryImages"),
          galleryImage,
          params.index
        );
        updatePortal({ _id: portalID, galleryImages })
          .then(portal => {
            updateLocalData(portal);
            handleClose();
          })
          .catch(error => window.alert(error.message));
      },
      reinitialState: true
    }
  );

  const imageId = _.get(galleryImage, `mainImageId`);

  React.useEffect(() => {
    setImageURL(imageId ? "/images/" + imageId : null);
  }, [imageId]);

  const { showModal } = useImageCropEditModal({
    config: {
      imageAspectRatio: 4 / 3
    },
    onImageCrop: result => {
      const croppedImageFile = _.get(result, "croppedImageFile");
      setIsLoading(true);
      if (croppedImageFile) {
        uploadPortalImage(portalID, croppedImageFile, imageId || "").then(
          result => {
            setFieldValue("mainImageId", result.ref);
            setFieldValue("lastChecked", result.lastChecked);
            setIsLoading(false);
          }
        );
        setImageURL(URL.createObjectURL(croppedImageFile));
      } else {
        setIsLoading(false);
      }
    }
  });

  function handleDeleteGalleryImage() {
    if (
      _.isNumber(params.index) &&
      window.confirm(
        t("actions.delete.confirm.label", {
          defaultValue: "Are you sure delete?"
        })
      )
    ) {
      const galleryImage = _.get(values, "mainImageId");

      _.pullAt(portal.galleryImages, params.index); //mutate galleryImages

      removePortalImage(portalID, _.get(galleryImage, "mainImageId"))
        .then(() => {
          return updatePortal({
            _id: portalID,
            galleryImages: portal.galleryImages
          }).then(portal => {
            updateLocalData(portal);
            handleClose();
          });
        })
        .catch(error => window.alert(error.message));
    }
  }

  function handleUploadImage(e) {
    const file = _.get(e, "target.files[0]");

    if (file) {
      showModal(file);
    }
    e.target.value = ""; //Fixed Can't upload same file twice
  }

  function handleClose() {
    if (onClose) {
      onClose(null);
    }
  }

  return (
    <>
      <EditorGalleryImageModalStyle
        className="modal-card-body"
        isImage={!_.isEmpty(imageURL)}
      >
        <Field
          label={`${t("field.galleryImage.image.label", {
            defaultValue: "Image"
          })}`}
        >
          <label htmlFor="__upload-photo">
            <div className="__editor-image">
              {imageURL && (
                <LazyLoadImage
                  src={imageURL}
                  alt={values.title}
                  effect="blur"
                />
              )}
              <InputFileBox
                onChange={handleUploadImage}
                inputProps={{ accept: "image/*" }}
              />
              <span className="icon">
                <i className="fas fa-camera fa-3x" />
              </span>
            </div>
          </label>
        </Field>
        <Field label={t("action.type.label")}>
          <SelectItem
            name="type"
            onChange={handleChange}
            value={values.type}
            options={typePicture}
          />
        </Field>
        {values.type !== "picture" && (
          <Field label={t("field.link.externalUrl.label")}>
            <InputText
              name="externalUrl"
              onChange={e => setFieldValue("externalUrl", e.target.value)}
              placeholder={t("field.link.externalUrl.placeholder", {
                defaultValue: "Enter your image title."
              })}
              value={values.externalUrl}
              options={typePicture}
              error={errors.externalUrl}
            />
            <span className="has-text-grey">
              ( Link Youtube Ex. https://www.youtube.com/embed/YbJOTdZBX1g )
            </span>
          </Field>
        )}
        <Field
          label={`${t("field.galleryImage.title.label", {
            defaultValue: "Image title"
          })} [${language}]`}
        >
          <InputText
            name={`title.${language}`}
            placeholder={t("field.galleryImage.title.label", {
              defaultValue: "Enter your image title."
            })}
            onChange={handleChange}
            value={values.title[language]}
            error={errors.title && errors.title[language]}
          />
        </Field>
        <Field
          label={`${t("field.galleryImage.description.label", {
            defaultValue: "Image description"
          })}[${language}]`}
        >
          <InputTextarea
            name={`description.${language}`}
            placeholder={t("field.galleryImage.description.label", {
              defaultValue: "Enter your image description."
            })}
            onChange={handleChange}
            value={values.description[language]}
            error={errors.description && errors.description[language]}
          />
        </Field>
        <Field
          label={`${t("field.galleryImage.expDate.label", {
            defaultValue: "Expire Date"
          })}`}
        >
          <input
            id="datePicker"
            className="input"
            type="date"
            name="expDate"
            min={nowDate}
            onChange={e =>
              setFieldValue("expDate", moment(e.target.value).toISOString())
            }
            value={moment(values.expDate).format("YYYY-MM-DD")}
          ></input>
        </Field>
      </EditorGalleryImageModalStyle>
      <FooterConfirmModal
        onSubmit={handleSubmit}
        onClose={handleClose}
        onDelete={params.index && handleDeleteGalleryImage}
        isLoading={isLoading}
      />
    </>
  );
}

const EditorGalleryImageModalStyle = styled.section.attrs({
  className: "modal-card-body"
})`
  label {
    cursor: pointer;
  }

  ${props =>
    props.isImage
      ? css`
          .__input-file-box {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }
        `
      : css`
          .__editor-image {
            display: none;
          }
          .icon {
            display: none;
          }
        `}

  .__editor-image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .__editor-image img {
    opacity: 0.8 !important;
  }

  .__image-field {
    position: relative;
    background: blue;
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    opacity: 0.6;
    color: black;
  }

  #__upload-photo {
  }
`;
