import React from 'react'
import useConfirmModal from '../hooks/useConfirmModal'

const ConfirmModalContext = React.createContext()

export function ConfirmModalContextProvider({ children }) {
  const confirmModal = useConfirmModal()
 
  return (
    <ConfirmModalContext.Provider value={confirmModal}>
      {children}
    </ConfirmModalContext.Provider>
  )
}

export default ConfirmModalContext
