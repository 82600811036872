import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputTags from '../../views/widgets/InputTags'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'

export default function EditorTagBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  return (
    <EditorTagBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('actions.edit.label', {
              title: t('app.tags.label', {
                defaultValue: 'Tags',
              }),
              defaultValue: 'Edit {{title}}',
            }),
          },
          modalRef: 'EDITOR_TAG_MODAL',
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorTagBoxStyle>
  )
}

const EditorTagBoxStyle = styled.div.attrs({
  className: '__editor-box __editor-tag-box __highlight-editor-box',
})`
  min-height: unset;
`

export function EditorTagModal({ onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, errors, setFieldValue, handleSubmit } = useForm({
    initialState: {
      tags: [],
      ..._.pick(portal, ['_id', 'tags']),
    },
    onValidate: () => {
      const errors = {}
      return errors
    },
    onSubmit: values => {
      updatePortal(values)
        .then(portal => {
          updateLocalData(portal)
          handleClose()
        })
        .catch(error => window.alert(error.message))
    },
    reinitialState: true,
  })

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <Field label={t('field.tags.label', { defaultValue: 'Tags' })}>
          {/* <InputText
            name={`tags`}
            placeholder={t('field.tags.placeholder', {defaultValue: ""})}
            onChange={e => {
              setFieldValue(
                'tags',
                _.chain(e.target.value)
                  .trim()
                  .split(',')
                  .value(),
              )
            }}
            value={values.tags}
          /> */}
          <InputTags
            name={`tags`}
            placeholder={t('field.tags.placeholder', { defaultValue:  "Enter your tags and click 'add'" })}
            onChange={value => {
              setFieldValue('tags', value)
            }}
            values={values.tags}
            error={errors.tags && errors.tags[language]}
            // renderInputText={InputTextTagSuggestion} // for suggest tags from remote
            textAction={t('actions.add.label', { defaultValue: 'Add Tags' })}
          />
        </Field>
      </section>
      <FooterConfirmModal onSubmit={handleSubmit} onClose={handleClose} />
    </>
  )
}
