import React from 'react'
import classNames from 'classnames'
import logger from '../../services/logger';

const InputText = ({
  error,
  touched = true,
  status,
  statusMessage,
  ...props
}) => {
  return (
    <div className="control is-expanded">
      <input
        className={classNames('input', {
          'is-danger': error && touched,
          'is-success': status === 'success',
          'is-warning': status === 'warning',
        })}
        type="text"
        onTouchMove={() => logger.debug('on Touse')}
        {...props}
      />
      {statusMessage && (
        <p
          className={classNames('help has-text-right', {
            'has-text-weight-bold': status === 'warning',
          })}
        >
          {statusMessage}
        </p>
      )}
      {touched && (
        <p className={classNames('help', { 'is-danger': error })}> {error} </p>
      )}
    </div>
  )
}

export default InputText
