import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Field from '../../views/widgets/Field'
import InputTags from '../../views/widgets/InputTags'
import useForm from '../../hooks/useForm'
import InputTextWithSuggestion from '../../views/widgets/InputTextWithSuggestion'
import InputTextareaWithSuggestion from '../../views/widgets/InputTextareaWithSuggestion'
import { getDefaultLangText } from '../../constants/utils'
import ActionPanelLayout from '../../views/widgets/ActionPanelLayout'
import { stepContentPagePropTypes } from '../../constants/prop-types'

const initialState = values => ({
  tags: [],
  highlightSentence: getDefaultLangText(),
  description: getDefaultLangText(),
  ...values,
})

export default function InformationPage({
  initialValues,
  onPrev,
  onAfterValid,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const {
    bindInput,
    isValues,
    values,
    errors,
    setFieldValue,
    handleSubmit,
  } = useForm({
    initialState: initialState(initialValues),
    onSubmit: onAfterValid,
    reinitialState: true,
  })

  return (
    <InformationPageStyle>
      <h3 className="title is-3 has-text-centered">
        <Trans i18nKey="app.information.label" defaults="Information" />
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <Field label={t('field.tags.label', { defaultValue: 'Tags' })}>
            <InputTags
              name={`tags`}
              placeholder={t('field.tags.placeholder', {
                defaultValue: 'Enter your tags.',
              })}
              onChange={value => {
                setFieldValue('tags', value)
              }}
              values={values.tags}
              error={errors.tags}
              textAction={t('actions.addTags.label', {defaultValue: "Add Category"})}
            />
          </Field>

          <Field
            label={`${t('field.highlightSentence.label', {
              defaultValue: 'Highlight Sentence',
            })} [${language}]`}
          >
            <InputTextWithSuggestion
              {...bindInput(`highlightSentence.${language}`)}
              suggestMinLength={60}
              placeholder={t('field.highlightSentence.placeholder', {
                defaultValue: 'Enter your highlight sentence.',
              })}
            />
          </Field>

          <Field
            label={`${t('field.description.label', {
              defaultValue: 'Description',
            })} [${language}]`}
          >
            <InputTextareaWithSuggestion
              suggestMinLength={200}
              {...bindInput(`description.${language}`)}
              placeholder={t('field.description.placeholder', {
                defaultValue: 'Enter your description.',
              })}
            />
          </Field>
        </div>

        <ActionPanelLayout>
          <div className="buttons is-hidden-mobile">
            <div className="button is-default" onClick={onPrev}>
              <Trans i18nKey="actions.back.label" defaults="Back" />
            </div>
            <input
              className="button is-primary"
              type="submit"
              value={t('actions.next.label', { defaultValue: 'Next' })}
            />
            {isValues && (
              <input
                className="button is-link"
                type="submit"
                value={t('actions.skip.label', { defaultValue: 'Skip' })}
              />
            )}
          </div>

          <div className="is-hidden-tablet">
            <div className="columns is-gapless is-mobile">
              <div className="column">
                <div
                  className="button is-medium is-default is-fullwidth"
                  onClick={onPrev}
                >
                  <Trans i18nKey="actions.back.label" defaults="Back" />
                </div>
              </div>
              <div className="column">
                <input
                  className="button is-medium is-primary is-fullwidth"
                  type="submit"
                  value={t('actions.next.label', { defaultValue: 'Next' })}
                />
              </div>
              {isValues && (
                <div className="column">
                  <input
                    className="button is-medium is-link is-fullwidth"
                    type="submit"
                    value={t('actions.skip.label', { defaultValue: 'Skip' })}
                  />
                </div>
              )}
            </div>
          </div>
        </ActionPanelLayout>
      </form>
    </InformationPageStyle>
  )
}

InformationPage.propTypes = {
  ...stepContentPagePropTypes,
}

const InformationPageStyle = styled.div.attrs({ className: '' })``
