import styled from 'styled-components'
import { cardMediaReponsive } from '../utils'
import CustomLink from './CustomLink'

const CardWithLink = styled(CustomLink).attrs({ className: 'card' })`
  ${cardMediaReponsive}
  z-index: 1;
  min-height: 320px;
`

export default CardWithLink
