import React from 'react'
import { Redirect } from 'react-router-dom'
import i18n from 'i18next'
import styled from 'styled-components'
import logger from '../../services/logger'
import { Trans } from 'react-i18next'

export default function ChooseLangPage({ history }) {
  const language = window.localStorage.getItem('language')

  function handleChangeLang(language) {
    i18n.changeLanguage(language)
    window.localStorage.setItem('language', language)
    logger.debug('choose lang', history)
    history.replace(`/creator`)
  }

  if (language) {
    return <Redirect to={`/`} replace />
  }

  return (
    <ChooseLangPageLayout className="__has-navbar">
      <div className="section has-text-centered">
        <div className="title is-3">
          <Trans
            i18nKey="app.chooseLanguage.title.label"
            defaults="What is your primary language?"
          />
        </div>
        <div className="__group-buttons-layout">
          <div className="__group-buttons">
            <button
              className="button is-medium"
              onClick={() => handleChangeLang('th')}
            >
              ภาษาไทย
            </button>
            <button
              className="button is-medium"
              onClick={() => handleChangeLang('en')}
            >
              English
            </button>
            <button
              className="button is-medium"
              onClick={() => handleChangeLang('zh')}
            >
              Chinese
            </button>
          </div>
        </div>
      </div>
    </ChooseLangPageLayout>
  )
}

const ChooseLangPageLayout = styled.div.attrs({ className: 'container' })`
  height: 90vh;
  .section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .__group-buttons-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: blue; */
  }

  .__group-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 250px;
    width: 320px;
    /* background: gray; */
  }
`
