import React from 'react'
import PropTypes from 'prop-types'
import { ColorTypes } from '../../constants/prop-types'

export default function FlashMessage({ isShow, type, onClose, children }) {
  const [_isShow, setIsShow] = React.useState(isShow)

  React.useEffect(() => {
    setIsShow(isShow)
  }, [isShow])

  function toggleShow() {
    onClose()
    setIsShow(value => !value)
  }

  if (!_isShow) {
    return null
  }

  return (
    <div
      className={`__flash-message notification ${type}`}
      style={{ width: '80%' }}
    >
      <button className="delete" onClick={toggleShow} />
      {children}
    </div>
  )
}

FlashMessage.propTypes = {
  isShow: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(ColorTypes),
}

FlashMessage.defaultProps = {
  type: '',
}
