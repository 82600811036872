import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../../contexts/AuthContext'

export default function PrivateRoute({
  component: Component,
  tokenKey,
  ...rest
}) {
  const { isAuthenticated } = React.useContext(AuthContext)
  return (
    <Route
      {...rest}
      component={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = {}
