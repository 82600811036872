import traverse from 'traverse'
import _ from 'lodash'

import config, { createRemoteURL } from '../config'

/**
|--------------------------------------------------
| How to use doProviderFetch or doFetch ?
| - doProviderFetch use for update and delete old portal from another provider 
|     especially it used in update, delete and get
| - doFetch use for used authentication and sth. else
|--------------------------------------------------
*/

export function getJWT() {
  return window.localStorage.getItem('jwt')
}

export function doFetch(url, options = {}) {
  const jwt = getJWT()
  if (jwt) {
    options.headers = {
      Authorization: `Bearer ${jwt}`,
      ...options.headers,
    }
  }
  return fetch(url, options)
}

export function doProviderFetch(url, options = {}, customFetch = fetch) {
  const _url = createRemoteURL(url)
  return customFetch(_url, options)
}

export function tranformToImageURL(
  obj,
  path = 'images',
  provider,
  imageId = 'mainImageId',
) {
  if (_.isEmpty(obj)) {
    return {}
  }
  const _obj = traverse(_.cloneDeep(obj)).forEach(function(item) {
    const mainImageId = _.get(item, imageId)
    if (_.isObject(item) && mainImageId) {
      const _provider =
        provider ||
        item.provider ||
        _.get(this.parent, 'node.provider') ||
        _.get(this.parent, 'parent.node.provider') ||
        config.providerContent
      const _serverURL = createRemoteURL('', _provider)

      item.imageURL = `${_serverURL}/${path}/${mainImageId}`
      item.smallImageURL = `${_serverURL}/small-${path}/${mainImageId}`
      this.update(item)
    }
  })
  return _obj
}
