import React from 'react'
import Helmet from 'react-helmet'

export default function Meta({ title, description, imageURL, url, link }) {
  const meta = [
    // facebook meta tags
    { name: 'og:type', content: 'article' },
    // twitter meta tags
    { name: 'twitter:card', content: 'summary' },
  ]
  // <meta name="twitter:site" content="@nytimesbits" />
  // <meta name="twitter:creator" content="@nickbilton" />

  if (title) {
    meta.push({ name: 'og:title', content: title })
  }

  if (description) {
    meta.push({ name: 'og:description', content: description })
  }

  if (imageURL) {
    meta.push({ name: 'og:url', content: imageURL })
  }
  return <Helmet title={title} meta={meta} link={link} />
}

Meta.propTypes = {}
