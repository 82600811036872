import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import SignUpForm from '../../templates/sign-up/SignUpForm'

export default function SignUpPage() {
  return (
    <div>
      <div className="container">
        <div className="section">
          <SignUpForm />
        </div>
      </div>
    </div>
  )
}
