import React from 'react'
import _ from 'lodash'
import MessengerCustomerChat from 'react-messenger-customer-chat'

import config from '../../config'

const APP_ID = config.fbApi.appID

export default function PortalContactMessengerFloatButton({ fbPageId }) {
  const messageCustomerChatRef = React.useRef(null)

  React.useEffect(() => {
    return () => {
      if (_.get(messageCustomerChatRef, 'current.removeFacebookSDK')) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        messageCustomerChatRef.current.removeFacebookSDK()
      }
    }
  }, [fbPageId])

  if (!config.isProd) {
    return null
  }

  if (!fbPageId) {
    return null
  }

  return (
    <MessengerCustomerChat
      ref={messageCustomerChatRef}
      pageId={fbPageId}
      appId={APP_ID}
      htmlRef={window.location.pathname}
    />
  )
}
