import React from "react";
import _ from "lodash";
import ImageWithPlaceholder from "../../views/elements/ImageWithPlaceholder";
import CardWithLink from "../../views/elements/CardWithLink";
import Truncate from "../../views/widgets/Truncate";
import { useTranslation } from "react-i18next";

export default function PortalListCard({
  portal,
  coords,
  cardComponent: CardComponent = CardWithLink
}) {
  const [distance, setDistance] = React.useState();
  const { t } = useTranslation();
  React.useEffect(() => {
    const latLng = _.get(portal, "__meta_search.latLng");
    setDistance(calculateDistance(coords, latLng, t));
  }, []);

  const title = _.get(portal, `title`);
  const shortDescription = _.get(portal, `shortDescription`);
  const highlightSentence = _.get(portal, `highlightSentence`);
  const tags = _.get(portal, `tags`);
  const imageURL = _.get(portal, `imageURL`);
  const promoteType = _.get(portal, "promoteType");
  const promoteWord = _.get(portal, "promoteWord");

  return (
    <CardComponent
      to={`/portals/${portal.id}?provider=${portal.provider}`}
      push
    >
      <div className="card-image">
        <figure className="image is-square">
          <ImageWithPlaceholder src={imageURL} alt={title} />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <PromoteSection type={promoteType} word={promoteWord} />
            <div className="heading has-text-grey">
              <Truncate>{_.join(tags, ",")}</Truncate>
            </div>
            <p className="title is-5">{title}</p>
            {!_.isEmpty(coords) && distance !== 'NaN' &&(
              <p className="subtitle is-6 has-text-grey">
                {t("field.location.distance.label")}:{" "}
                {distance < 1
                  ? `${distance * 1000} ${t("unit.meters.label")}`
                  : `${distance} ${t("unit.kilometers.label")}`}
              </p>
            )}
            <p className="subtitle is-6 is-hidden-mobile">
              <Truncate size={4}>
                {shortDescription || highlightSentence}
              </Truncate>
            </p>
          </div>
        </div>
      </div>
    </CardComponent>
  );
}

function calculateDistance(coords, latLng, t) {
  const lat1 = _.get(coords, "lat");
  const lon1 = _.get(coords, "lon");
  const lat2 = _.get(latLng, "lat");
  const lon2 = _.get(latLng, "lon");
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d.toFixed(2);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function PromoteSection({ type, word }) {
  if (!type) {
    return null;
  }
  return (
    <div
      class="heading"
      style={{ display: "flex", alignItems: "center", marginBotton: 8 }}
    >
      <span class="tag is-danger">{type}</span>
      <span style={{ paddingLeft: 8 }}>
        <Truncate size={2}>{word}</Truncate>
      </span>
    </div>
  );
}
