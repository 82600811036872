import React from 'react'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { tranformToImageURL } from '../../services/remoteUtils'
import { translate } from '../../services/lang'

export default function PortalListItem({
  portal: _portal,
  searchText,
  language = 'th',
}) {
  const portal = tranformToImageURL(translate(_portal, language))
  const title = portal.title
  const imageURL = portal.smallImageURL
  const tagsText = _.join(portal.tags, ' ,')

  return (
    <div className="media" style={{ overflowY: 'hidden' }}>
      <div className="media-left">
        <div className="image is-64x64">
          <LazyLoadImage src={imageURL} alt={title} effect="blur" />
        </div>
      </div>
      <div className="media-content">
        <div className="content">
          <div>
            <strong>
              <Highlighter
                highlightClassName="__text-highlight"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={title}
              />
            </strong>
            <br />
            <div className="tag is-info heading">tags</div>
            <span>
              {' '}
              <Highlighter
                highlightClassName="__text-highlight"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={tagsText}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
