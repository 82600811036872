import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "querystring";
import classNames from "classnames";
import _ from "lodash";
import PortalSearchAutocomplete from "./PortalSearchAutocomplete";
import { FilterView } from "../filter";
import FilterbarLayout from "../filter-bar/FilterbarLayout";
import { getParams } from "../../modules/utils";

function PortalSearchPanel({ query = {}, history, coords }) {
  const [range, setRange] = React.useState(initialRange(_.get(query, "range")));
  const [tags, setTags] = React.useState(initialTags(_.get(query, "tags")));
  const title = query.title;

  const listingTag = {
    id: _.get(query, "listing.id"),
    title: _.get(query, "listing.title")
  };

  const townTag = {
    id: _.get(query, "town.id"),
    title: _.get(query, "town.title")
  };

  function deleteFilter(filterKey) {
    const _query = _.omitBy(query, (v, k) => {
      return _.includes(k, filterKey) || _.isEmpty(v);
    });

    history.replace("/portals?" + queryString.stringify(_query));
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
  }

  function resetFilterValue() {
    setRange(initialRange(_.get(query, "range")));
  }

  function _handleClose(position, currentFilterType) {
    if (position !== "CLOSE_VIEW") {
      _handleFilterApply(currentFilterType);
    }
  }

  function _handleOpen() {}

  const handleInputChange = (name, value) => {
    if (_.isEqual(name, "distance")) {
      setRange(parseInt(value));
    }
    if (_.isEqual(name, "tags")) {
      if (!_.includes(tags, value)) {
        setTags(tags => [...tags, value]);
      } else {
        setTags(tags => _.clone(_.pull(tags, value)));
      }
    }
  };

  function _handleFilterApply(name) {
    switch (name) {
      case "distance":
        filterDistance();
        break;
      case "tags":
        filterTags();
        break;
      case "filterAll":
        filterDistance();
        filterTags();
        break;
      default:
        break;
    }
  }

  function filterDistance() {
    if (range == 0) {
      const parsed = _.omit(getParams(history.location.search), "range");
      history.replace("/portals?" + queryString.stringify(parsed));
      return;
    }
    const parsed = getParams(history.location.search);
    if (range > 100) parsed.range = "all";
    else parsed.range = range;
    history.replace("/portals?" + queryString.stringify(parsed));
  }

  function filterTags() {
    if (_.isEmpty(tags)) {
      const parsed = _.omit(getParams(history.location.search), "tags");
      return history.replace("/portals?" + queryString.stringify(parsed));
    }
    const parsed = getParams(history.location.search);
    parsed.tags = tags.toString();
    history.replace("/portals?" + queryString.stringify(parsed));
  }

  function _handleFilterReset(name) {
    switch (name) {
      case "distance":
        {
          setRange(0);
          const parsed = _.omit(getParams(history.location.search), "range");
          history.replace("/portals?" + queryString.stringify(parsed));
        }
        break;
      case "tags":
        {
          setTags([]);
          const parsed = _.omit(getParams(history.location.search), "tags");
          history.replace("/portals?" + queryString.stringify(parsed));
        }
        break;
      case "filterAll":
        resetAllFilter();
        break;
      default:
        break;
    }
  }

  function resetAllFilter() {
    setRange(0);
    setTags([]);
    history.replace("/portals?");
  }
  const queries = [];
  const location = {};
  if (_.get(query, "town.id")) {
    queries.push({ term: { townRefs: _.get(query, "town.id") } });
  }
  if (_.get(query, "listing.id")) {
    queries.push({ term: { listingRefs: _.get(query, "listing.id") } });
  }
  if (_.get(query, "tags")) {
    queries.push({ terms: { tags: _.split(_.get(query, "tags"), ",") } });
  }
  if (_.get(query, "range")) {
    location.range = _.get(query, "range");
    location.coords = coords;
  }
  return (
    <div className="container" style={{ padding: 16 }}>
      <PortalSearchAutocomplete
        key={JSON.stringify(query)}
        config={{ queries, nextQueries: query, location }}
        placeholder={
          "Search " +
          (_.get(query, "town.title") ||
            _.get(query, "listing.title") ||
            "Anyting...")
        }
      />
      <hr />
      <>
        <div className="title is-4" style={{ marginTop: 0, marginBottom: 0 }}>
          ผลการค้นหา {title && `"${title}"`}
        </div>
        <form onSubmit={handleSearchSubmit}>
          <div style={{ padding: 8 }}>
            <div className="heading">Filter</div>
            <div className="field is-grouped is-grouped-multiline">
              {!_.isEmpty(query) && (
                <>
                  {/* town */}
                  {townTag.id && (
                    <div key={townTag.id} className="control">
                      <div className="tags has-addons">
                        <span className="tag is-dark">เมือง</span>
                        <span className="tag is-link">{townTag.title}</span>
                        <div
                          className="tag is-delete"
                          onClick={() => deleteFilter("town")}
                        />
                      </div>
                    </div>
                  )}
                  {/* end town */}

                  {/* listing */}
                  {listingTag.id && (
                    <div key={listingTag.id} className="control">
                      <div className="tags has-addons">
                        <span className="tag is-dark">ประเภท</span>
                        <span className="tag is-link">{listingTag.title}</span>
                        <div
                          className="tag is-delete"
                          onClick={() => deleteFilter("listing")}
                        />
                      </div>
                    </div>
                  )}
                  {/* end listing */}
                </>
              )}
              <FilterbarLayout
                renderDistance={
                  <FilterView.Distance
                    onInputChange={e => {
                      handleInputChange("distance", e.target.value);
                    }}
                    value={range}
                  />
                }
                renderTags={
                  <FilterView.Tags
                    onInputChange={e => {
                      handleInputChange("tags", e.target.name);
                    }}
                    value={tags}
                  />
                }
                range={range}
                tags={tags}
                onFilterApply={_handleFilterApply}
                onFilterReset={_handleFilterReset}
                onClose={_handleClose}
                onOpen={_handleOpen}
                resetFilterValue={resetFilterValue}
                renderSubmitButton={
                  <FilterView.SubmitButton
                    onFilterApply={_handleFilterApply}
                    onFilterReset={_handleFilterReset}
                  />
                }
              />
            </div>
          </div>

          {/*end Filter Bar & Modal*/}
        </form>
      </>
    </div>
  );
}

function initialRange(range) {
  return range ? (range !== "all" ? range : 110) : 0;
}

function initialTags(tags) {
  return _.chain(tags)
    .split(",")
    .reject(_.isEmpty)
    .value();
}

export default withRouter(PortalSearchPanel);
