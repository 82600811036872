import React from 'react'
import ReactDOM from 'react-dom'

// css
import './bulma.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-lazy-load-image-component/src/effects/opacity.css' //optimize
import 'react-lazy-load-image-component/src/effects/blur.css' //optimize
import 'react-image-gallery/styles/css/image-gallery.css' //optimize
import 'slick-carousel/slick/slick.css' //optimize
import 'slick-carousel/slick/slick-theme.css' //optimize
import './index.css'

// lang
import './modules/i18n/i18n'
import 'moment/locale/zh-cn'
import 'moment/locale/th'

// modules
import * as serviceWorker from './serviceWorker'
import { initializeFirebase } from './modules/firebase'

//component
import { changeLanguage } from './modules/lang/LanguageSelector'
import App from './App'

import config from './config'

if (config.isProd) {
  const ReactGA = require('react-ga')
  ReactGA.initialize(config.googleApi.ga)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

if (typeof window !== 'undefined') {
  const language = window.localStorage.getItem('language')
  changeLanguage(language || 'th')
}

initializeFirebase()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
