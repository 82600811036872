import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import InputTextWithSuggestion from '../../views/widgets/InputTextWithSuggestion'
import { getDefaultLangText } from '../../constants/utils'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { updatePortal } from '../../services/remoteService'
import InputTextareaWithSuggestion from '../../views/widgets/InputTextareaWithSuggestion'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'

export default function EditorHeaderInfoBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)
  return (
    <EditorHeaderInfoBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('app.information.label', {
              defaultValue: 'Edit Information',
            }),
          },
          modalRef: 'EDITOR_HEADER_INFO_MODAL',
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorHeaderInfoBoxStyle>
  )
}

const EditorHeaderInfoBoxStyle = styled.div.attrs({
  className: '__editor-header-info-box __highlight-editor-box __editor-box ',
})``

export function EditorHeaderInfoModal({ onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, errors, handleChange, handleSubmit } = useForm({
    initialState: {
      title: getDefaultLangText(),
      highlightSentence: getDefaultLangText(),
      description: getDefaultLangText(),
      ..._.pick(portal, ['_id', 'title', 'highlightSentence', 'description']),
    },
    onValidate: () => {
      const errors = {}
      return errors
    },
    onSubmit: values => {
      updatePortal(values)
        .then(portal => {
          updateLocalData(portal)
          handleClose()
        })
        .catch(error => window.alert(error.message))
    },
    reinitialState: true,
  })

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <Field
          label={`${t('field.title.label', {
            defaultValue: 'Title',
          })} [${language}]`}
        >
          <InputText
            name={`title.${language}`}
            placeholder={t('field.title.placeholder', {
              defaultValue: 'Enter your title.',
            })}
            onChange={handleChange}
            value={values.title[language]}
            error={errors.title && errors.title[language]}
          />
        </Field>
        <Field
          label={`${t('field.highlightSentence.label', {
            defaultValue: 'Highlight Sentence',
          })} [${language}]`}
        >
          <InputTextWithSuggestion
            suggestMinLength={60}
            name={`highlightSentence[${language}]`}
            placeholder={t('field.highlightSentence.placeholder', {
              defaultValue: 'Enter your highlight sentence.',
            })}
            onChange={handleChange}
            value={values.highlightSentence[language]}
          />
        </Field>
        <Field
          label={`${t('field.description.label', {
            defaultValue: 'Description',
          })}[${language}]`}
        >
          <InputTextareaWithSuggestion
            suggestMinLength={200}
            name={`description[${language}]`}
            placeholder={t('field.description.placeholder', {
              defaultValue: 'Enter your description.',
            })}
            onChange={handleChange}
            value={values.description[language]}
          />
        </Field>
      </section>
      <FooterConfirmModal onSubmit={handleSubmit} onClose={handleClose} />
    </>
  )
}
