import React from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

export default function CopyToClipboard({ text, children }) {
  const { t } = useTranslation()
  function handleCopyFinish(e) {
    e.stopPropagation()
    copy(text)
    // toast('Copy to clipboard.', { type: 'default' })
  }

  if (!text) {
    return null
  }

  return (
    <div className="field has-addons is-fullwidth">
      <div className="control">
        <input className="input" type="text" value={text} disabled />
      </div>
      <div className="control">
        <button className="button" onClick={handleCopyFinish}>
          <span>
            {t('actions.copy.label', { defaultValue: 'Copy' })}
          </span>
        </button>
        {children}
      </div>
    </div>
  )
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
}
