import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import InputText from './InputText'

export default class InputTags extends React.Component {
  static propTypes = {
    renderInputText: PropTypes.func,
    textAction: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      tagName: '',
    }

    this.handleItemRemove = _.memoize(this.handleItemRemove)
  }

  handleTagUpdated = (tagName, initialValues = this.props.values) => {
    let values = _.map(initialValues, value => _.trim(value))
    let isValue = _.includes(values, tagName)

    if (isValue) {
      values = _.pull(values, tagName)
    } else {
      values = _.concat(values, tagName)
    }
    return values
  }

  handleInputTextKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleItemAdd()
    }
  }

  handleInputTextChange = ({ target: { value } }) => {
    this.setState({ tagName: value })
  }

  clearTagName = () => {
    this.setState({ tagName: '' })
  }

  handleItemAdd = () => {
    let tagName = _.trim(this.state.tagName)
    if (_.isEmpty(tagName)) return

    if (this.props.onChange) {
      this.props.onChange(this.handleTagUpdated(tagName))
    }
    this.clearTagName()
  }

  handleItemRemove = tagName => () => {
    if (this.props.onChange) {
      this.props.onChange(this.handleTagUpdated(tagName))
    }
  }

  renderTagItem = (tagName, index) => {
    let tagNameWithTrim = _.trim(tagName)
    return (
      <TagItem
        key={tagNameWithTrim}
        tagName={tagNameWithTrim}
        onItemRemove={this.handleItemRemove(tagNameWithTrim)}
      />
    )
  }

  render() {
    const { touched = true, error } = this.props
    const _values = _.reject(this.props.values, _.isEmpty)

    const Input = this.props.renderInputText
      ? this.props.renderInputText
      : InputText

    return (
      <div className="control field is-grouped is-grouped-multiline">
        {_.map(_values, this.renderTagItem)}
        <Input
          name={`tag`}
          placeholder={this.props.placeholder}
          onChange={this.handleInputTextChange}
          onKeyPress={this.handleInputTextKeyPress}
          value={this.state.tagName}
          touched={touched}
          error={error}
        />

        <div className="button" onClick={this.handleItemAdd}>
          {this.props.textAction || 'Add'}
        </div>
      </div>
    )
  }
}

const TagItem = ({ tagName, onItemRemove }) => (
  <div className="control">
    <div className="tags has-addons">
      <span className="tag">{tagName}</span>
      <div className="tag is-delete" onClick={onItemRemove} />
    </div>
  </div>
)
