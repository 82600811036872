import React from 'react'
import styled from 'styled-components'
import { useModal } from 'react-modal-hook'
import ImageEditModal from '../../modules/image-crop/ImageEditModal'
import { useTranslation } from 'react-i18next'

export default function useImageCropEditModal({ config = {}, onImageCrop }) {
  const { t } = useTranslation()
  const [imageFile, setImageFile] = React.useState(null)

  const [showModal, hideModal] = useModal(
    value => {
      return (
        <ModalStyle className="modal is-active">
          <div className="modal-background" />
          <ImageEditModal
            params={{
              title: t('field.image.label', { defaultValue: 'Image' }),
              imageFile,
              imageAspectRatio: 1,
              ...config,
            }}
            onRequestClose={imageFile => {
              hideModal()
              onImageCrop(imageFile)
            }}
          />
        </ModalStyle>
      )
    },
    [imageFile],
  )

  function handleShowModal(imageFile) {
    showModal()
    setImageFile(imageFile)
  }

  return {
    imageFile,
    showModal: handleShowModal,
  }
}

const ModalStyle = styled.div`
  @media only screen and (max-width: 768px) {
    .modal-card {
      max-height: 100vh;
      flex: 1;
    }
    .modal-card-head {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-foot {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
  }

  @media only screen and (min-width: 1023px) {
  }
`
