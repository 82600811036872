import React from "react";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { Trans } from "react-i18next";

import SocialButtonGroup from "../social-button-group/SocialButtonGroup";
import PortalContacts from "../portal-contacts/PortalContacts";
import PortalHeaderImage from "./PortalHeaderImage";
import PortalModifiedDate from "./PortalModifiedDate";
import Content from "../../views/widgets/Content";
import PortalGalleryImages from "../portal-gallery-images/PortalGalleryImages";
import MapEmbed from "../../views/widgets/MapEmbed";
import BoxWithColorGray from "../../views/elements/BoxWithColorGray";
import PortalLocationAddress from "./PortalLocationAddress";
import PortalTags from "./PortalTags";
import PortalOpeningHour from "./PortalOpeningHour";
import PortalContributor from "./PortalContributor";
import EditorHeaderInfoBox from "../editor-modal/EditorHeaderInfoBox";
import EditorLocationBox from "../editor-modal/EditorLocationBox";
import PortalExternalLink from "./PortalExternalLink";
import EditorWithAuthHandler from "../editor/EditorWithAuthHandler";
import EditorTagBox from "../editor-modal/EditorTagBox";
import EditorContactBox from "../editor-modal/EditorContactBox";
import QRCodeSection from "../qrcode/QRCodeSection";
import PortalAction from "./PortalAction";
import EditorActionBox from "../editor-modal/EditorActionBox";

export default function PortalLayout({ portal }) {
  if (_.isEmpty(portal)) {
    return null;
  }
  const headerMainImageURL = _.chain(portal)
    .get("galleryImages")
    .head()
    .get("imageURL")
    .value();

  const headerImageURL = headerMainImageURL
    ? `${headerMainImageURL}?lastChecked=${portal.imageCoverModifiedDate}`
    : `${portal.imageURL}?lastChecked=${portal.imageCoverModifiedDate}`;
  const galleryImages = _.chain(portal)
    .get("galleryImages")
    .tail()
    .value();

  const title = _.isObject(portal.title) ? "" : portal.title;
  const shortDescription = _.isObject(portal.shortDescription)
    ? ""
    : portal.shortDescription;
  const highlightSentence = _.isObject(portal.highlightSentence)
    ? ""
    : portal.highlightSentence;
  const contributors = _.get(portal, "contributors");
  const openingHours = _.isObject(portal.openingHours)
    ? ""
    : portal.openingHours;
  const links = _.chain(portal)
    .get("links")
    .filter(link => !_.isEmpty(link.externalUrl))
    .value();
  const modifiedTimestamp = _.get(portal, "modifiedDate");
  const modifiedDate = moment(modifiedTimestamp).format("D MMMM YYYY");
  const action = _.get(portal, "action");
  // const modifiedDate = moment(
  //   modifiedTimestamp ? modifiedTimestamp.toDate() : modifiedTimestamp,
  // ).format('D MMMM YYYY');

  const status =
    !_.isEqual(_.get(portal, "status"), "RELEASE") ||
    _.isEmpty(_.get(portal, "townsInfo"));

  if (portal.contacts) portal.contacts.location = portal.location;
  window.scrollTo(0, 0);
  return (
    <PortalLayoutStyle>
      {status && (
        <PortalStatus>
          <span className="title is-3 has-text-light">UNOFFICIAL</span>
        </PortalStatus>
      )}
      <PortalHeaderImage
        key={portal.imageCoverModifiedDate}
        imageURL={headerImageURL}
        title={title}
        shortDescription={shortDescription}
        highlightSentence={highlightSentence}
        contacts={portal.contacts}
      />
      <div className="container" style={{ width: "100vw" }}>
        <div>
          <div className="__header-section columns is-desktop is-gapless">
            {/* start header section */}
            <div className="column is-9-desktop is-12-tablet">
              <div className="__gallery-image-section">
                <EditorWithAuthHandler editorComponent={EditorActionBox}>
                  {action && <PortalAction action={action}></PortalAction>}
                </EditorWithAuthHandler>
              </div>
              <PortalModifiedDate modifiedDate={modifiedDate} />
              <div className="has-text-centered is-hidden-desktop">
                <SocialButtonGroup />
              </div>
              <div className="__gallery-image-section">
                <EditorWithAuthHandler editorComponent={EditorHeaderInfoBox}>
                  <HeaderInfo portal={portal} />
                </EditorWithAuthHandler>
                <PortalGalleryImages galleryImages={galleryImages} />
              </div>
            </div>
            {/* end header section */}

            {/*  start right section */}
            {/* responsive but duplicate */}
            <div className="__right-section column is-3-desktop is-12-tablet">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="is-hidden-desktop" style={{ width: "100%" }}>
                  <BoxWithColorGray>
                    <EditorWithAuthHandler editorComponent={EditorContactBox}>
                      <PortalContacts contacts={portal.contacts} />
                    </EditorWithAuthHandler>
                    <hr className="has-background-grey-lighter" />
                    <PortalOpeningHour openingHours={openingHours} />
                  </BoxWithColorGray>
                </div>
                <div className="is-hidden-touch" style={{ width: "100%" }}>
                  <BoxWithColorGray>
                    <EditorWithAuthHandler editorComponent={EditorContactBox}>
                      <PortalContacts contacts={portal.contacts} />
                    </EditorWithAuthHandler>
                    <hr className="has-background-grey-lighter" />
                    <PortalOpeningHour openingHours={openingHours} />
                    <hr className="has-background-grey-lighter" />
                    <QRCodeSection
                      params={{
                        provider: portal.provider,
                        text: window.location.href,
                        imageID: portal.mainImageId
                      }}
                    />
                  </BoxWithColorGray>
                </div>
              </div>
              <SocialButtonGroup isColumn className="is-hidden-touch" />
            </div>
            {/*  end right section */}
          </div>

          {/* start bottom section */}
          <div className="__bottom-section columns is-desktop is-gapless">
            <div className="column is-9-desktop is-12-tablet">
              <div className="">
                <EditorWithAuthHandler editorComponent={EditorLocationBox}>
                  <LocationAddressWithMap portal={portal} />
                </EditorWithAuthHandler>
                <hr />
                <PortalExternalLink links={links} />
                <br />
                <PortalModifiedDate modifiedDate={modifiedDate} />
                <br />
                <EditorWithAuthHandler editorComponent={EditorTagBox}>
                  <PortalTags tags={portal.tags} />
                </EditorWithAuthHandler>
                <br />
                <div className="has-text-centered">
                  <div className="title is-4">
                    <Trans
                      i18nKey="glog.share.content"
                      defaults="Share This Information"
                    />
                  </div>
                  <SocialButtonGroup />
                  <QRCodeSection
                    params={{
                      provider: portal.provider,
                      text: window.location.href,
                      imageID: portal.mainImageId
                    }}
                  />
                  <br />
                </div>
                <PortalContributor contributors={contributors} />
              </div>
            </div>
          </div>
          {/* end bottom section */}
        </div>
      </div>
    </PortalLayoutStyle>
  );
}

const PortalLayoutStyle = styled.div`
  .__header-section {
    margin-top: 24px;
  }

  .__bottom-section > .column {
    padding: 3rem;
  }

  .__right-section {
    margin-bottom: 24px;
  }

  .__gallery-image-section {
    padding: 3rem;
  }

  @media only screen and (max-width: 480px) {
    /* fixed hardcode for mobile */
    .columns {
      padding-left: 16px;
      padding-right: 16px;
      margin-left: unset !important;
      margin-right: unset !important;
    }
    .column {
      padding: unset !important;
    }
    .__gallery-image-section {
      padding: unset !important;
    }
  }
`;
const PortalStatus = styled.div`
  position: absolute;
  right: 16px;
  padding: 0.8em;
  top: 8em;
  border-radius: 0.8em;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CSPrajad";
  background-color: rgb(255, 221, 87, 0.5);
  text-shadow: 0px 2px 15px #0a0a0a;
`;

export function HeaderInfo({
  portal: { title, highlightSentence, description }
}) {
  return (
    <>
      <div className="title is-2 has-text-centered ">{title}</div>
      <div className="title is-3 has-text-centered ">{highlightSentence}</div>
      <Content> {description}</Content>
    </>
  );
}

function LocationAddressWithMap({ portal }) {
  return (
    <>
      <PortalLocationAddress
        streetAddress={_.get(portal, "location.streetAddress")}
        locationAdress={_.get(portal, "location")}
      />
      {portal.location && (
        <MapEmbed
          latitude={_.get(portal, "location.latitude")}
          longitude={_.get(portal, "location.longitude")}
        />
      )}
    </>
  );
}
