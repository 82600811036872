import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import InfoContext from "../../../contexts/InfoContext";

const TagsView = ({ onInputChange, value }) => {
  const { t } = useTranslation();
  const { remoteWebConfig } = React.useContext(InfoContext);
  return (
    <form>
      <TagCheckBoxStyle>
        <span className="title is-3">{t("filter.tags.label")}</span>
        <div class="columns is-multiline is-gapless is-mobile">
          {_.map(remoteWebConfig.tags, tag => (
            <div class="column is-6">
              <label className="container-box">
                {tag}
                <input
                  type="checkbox"
                  name={tag}
                  checked={_.includes(value, tag)}
                  onChange={e => {
                    e.stopPropagation();
                    onInputChange(e);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </TagCheckBoxStyle>
    </form>
  );
};

const TagCheckBoxStyle = styled.div`
  padding: 1em;
  /* The container-box */
  .container-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    border-radius: 0.3em;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container-box:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container-box input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container-box input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container-box .checkmark:after {
    left: 10px;
    top: 4px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export default TagsView;
