import React from 'react'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'

export default function SocialButtonGroup({ isColumn = false, className }) {
  const url = window.location.href
  const title = ''

  return (
    <SocialButtonGroupStyle isColumn={isColumn} className={className}>
      <div className="social-group">
        {isColumn && (
          <div className="title is-4 has-text-centered">
            <Trans i18nKey="actions.share.label" defaults="Share" />
          </div>
        )}
        <div className="social-group-body">
          {/* <!-- Sharingbutton Facebook --> */}
          <a
            className="resp-sharing-button__link"
            href={`https://facebook.com/sharer/sharer.php?u=${url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label=""
          >
            <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
              <div
                aria-hidden="true"
                className="resp-sharing-button__icon resp-sharing-button__icon--solid"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                </svg>
              </div>
            </div>
          </a>

          {/* <!-- Sharingbutton Twitter --> */}
          <a
            className="resp-sharing-button__link"
            href={`https://twitter.com/intent/tweet/?text=${title}&amp;url=${url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label=""
          >
            <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
              <div
                aria-hidden="true"
                className="resp-sharing-button__icon resp-sharing-button__icon--solid"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                </svg>
              </div>
            </div>
          </a>
          {/* <!-- Sharingbutton Line --> */}
          <a
            className="resp-sharing-button__link"
            href={`https://social-plugins.line.me/lineit/share?url=${url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label=""
          >
            <div className="resp-sharing-button_img  resp-sharing-button--small">
              <div aria-hidden="true" className="resp-sharing-button__icon ">
                <img src={require('../../assets/images/line-logo.png')} />
              </div>
            </div>
          </a>

          {/* <!-- Sharingbutton Google+ --> */}
          {/* <a
            className="resp-sharing-button__link"
            href={`https://plus.google.com/share?url=${url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label=""
          >
            <div className="resp-sharing-button resp-sharing-button--google resp-sharing-button--small">
              <div
                aria-hidden="true"
                className="resp-sharing-button__icon resp-sharing-button__icon--solid"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M11.37 12.93c-.73-.52-1.4-1.27-1.4-1.5 0-.43.03-.63.98-1.37 1.23-.97 1.9-2.23 1.9-3.57 0-1.22-.36-2.3-1-3.05h.5c.1 0 .2-.04.28-.1l1.36-.98c.16-.12.23-.34.17-.54-.07-.2-.25-.33-.46-.33H7.6c-.66 0-1.34.12-2 .35-2.23.76-3.78 2.66-3.78 4.6 0 2.76 2.13 4.85 5 4.9-.07.23-.1.45-.1.66 0 .43.1.83.33 1.22h-.08c-2.72 0-5.17 1.34-6.1 3.32-.25.52-.37 1.04-.37 1.56 0 .5.13.98.38 1.44.6 1.04 1.84 1.86 3.55 2.28.87.23 1.82.34 2.8.34.88 0 1.7-.1 2.5-.34 2.4-.7 3.97-2.48 3.97-4.54 0-1.97-.63-3.15-2.33-4.35zm-7.7 4.5c0-1.42 1.8-2.68 3.9-2.68h.05c.45 0 .9.07 1.3.2l.42.28c.96.66 1.6 1.1 1.77 1.8.05.16.07.33.07.5 0 1.8-1.33 2.7-3.96 2.7-1.98 0-3.54-1.23-3.54-2.8zM5.54 3.9c.33-.38.75-.58 1.23-.58h.05c1.35.05 2.64 1.55 2.88 3.35.14 1.02-.08 1.97-.6 2.55-.32.37-.74.56-1.23.56h-.03c-1.32-.04-2.63-1.6-2.87-3.4-.13-1 .08-1.92.58-2.5zM23.5 9.5h-3v-3h-2v3h-3v2h3v3h2v-3h3" />
                </svg>
              </div>
            </div>
          </a> */}
        </div>
      </div>
    </SocialButtonGroupStyle>
  )
}

const SocialButtonGroupStyle = styled.div`
  ${props =>
    props.isColumn &&
    css`
      /* position: fixed; */
      display: flex;
      justify-content: flex-end;
      position: -webkit-sticky;
      position: sticky;
      top: 0;

      .social-group {
        margin-top: 104px;
        display: flex;
        width: 75px;
        flex-direction: column;
        /* background: red; */
        justify-content: center;
        align-items: center;
      }

      .social-group-body {
        display: flex;
        width: 55px;
        flex-direction: column;
        /* background: green; */
      }
    `}

  .resp-sharing-button__link,
  .resp-sharing-button__icon {
    display: inline-block;
  }

  .resp-sharing-button__link {
    text-decoration: none;
    color: #fff;
    margin: 0.5em;
  }

  .resp-sharing-button_img {
    border-radius: 5px;
    transition: 25ms ease-out;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .resp-sharing-button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .resp-sharing-button__icon img {
    width: 2.6em;
  }

  .resp-sharing-button__icon img:hover {
    width: 2.6em;
    filter: brightness(0.9);
  }
  .resp-sharing-button--small img {
    margin: 0;
    vertical-align: middle;
  }
  .resp-sharing-button__icon svg {
    width: 1em;
    height: 1em;
    margin-right: 0.4em;
    vertical-align: top;
  }

  .resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle;
  }

  /* Non solid icons get a stroke */
  .resp-sharing-button__icon {
    stroke: #fff;
    fill: none;
  }

  /* Solid icons get a fill */
  .resp-sharing-button__icon--solid,
  .resp-sharing-button__icon--solidcircle {
    fill: #fff;
    stroke: none;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
  }

  .resp-sharing-button--twitter:hover {
    background-color: #2795e9;
  }

  .resp-sharing-button--pinterest {
    background-color: #bd081c;
  }

  .resp-sharing-button--pinterest:hover {
    background-color: #8c0615;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover {
    background-color: #2d4373;
  }

  .resp-sharing-button--tumblr {
    background-color: #35465c;
  }

  .resp-sharing-button--tumblr:hover {
    background-color: #222d3c;
  }

  .resp-sharing-button--reddit {
    background-color: #5f99cf;
  }

  .resp-sharing-button--reddit:hover {
    background-color: #3a80c1;
  }

  .resp-sharing-button--google {
    background-color: #dd4b39;
  }

  .resp-sharing-button--google:hover {
    background-color: #c23321;
  }

  .resp-sharing-button--line {
    background-color: #31c75a;
  }

  .resp-sharing-button--line:hover {
    background-color: #22b337;
  }

  .resp-sharing-button--linkedin {
    background-color: #0077b5;
  }

  .resp-sharing-button--linkedin:hover {
    background-color: #046293;
  }

  .resp-sharing-button--email {
    background-color: #777;
  }

  .resp-sharing-button--email:hover {
    background-color: #5e5e5e;
  }

  .resp-sharing-button--xing {
    background-color: #1a7576;
  }

  .resp-sharing-button--xing:hover {
    background-color: #114c4c;
  }

  .resp-sharing-button--whatsapp {
    background-color: #25d366;
  }

  .resp-sharing-button--whatsapp:hover {
    background-color: #1da851;
  }

  .resp-sharing-button--hackernews {
    background-color: #ff6600;
  }
  .resp-sharing-button--hackernews:hover,
  .resp-sharing-button--hackernews:focus {
    background-color: #fb6200;
  }

  .resp-sharing-button--vk {
    background-color: #507299;
  }

  .resp-sharing-button--vk:hover {
    background-color: #43648c;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover,
  .resp-sharing-button--facebook:active {
    background-color: #2d4373;
    border-color: #2d4373;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
    border-color: #55acee;
  }

  .resp-sharing-button--twitter:hover,
  .resp-sharing-button--twitter:active {
    background-color: #2795e9;
    border-color: #2795e9;
  }

  .resp-sharing-button--google {
    background-color: #dd4b39;
    border-color: #dd4b39;
  }

  .resp-sharing-button--google:hover,
  .resp-sharing-button--google:active {
    background-color: #c23321;
    border-color: #c23321;
  }

  .resp-sharing-button--line {
    background-color: #31c75a;
    border-color: #dd4b39;
  }

  .resp-sharing-button--line:hover,
  .resp-sharing-button--line:active {
    background-color: #22b337;
    border-color: #c23321;
  }
`
