import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import PortalListLinkCard from '../portal-list-link-card/PortalListLinkCard'
import EditorExternalLinkBox from '../editor-modal/EditorExternalLinkBox'
import EditorWithAuthHandler from '../editor/EditorWithAuthHandler'
import { Trans } from 'react-i18next'

export default function PortalExternalLink({ links }) {
  return (
    <>
      <EditorWithAuthHandler
        renderCustomEditor={() => (
          <PortalExternalLinkListWithEditor links={links} />
        )}
      >
        <PortalExternalLinkList links={links} />
      </EditorWithAuthHandler>
    </>
  )
}

function PortalExternalLinkList({ links }) {
  //component for edit button is in PortalListLinkCard;
  if (_.isEmpty(links)) {
    return null
  }

  return (
    <>
      <div className="title is-2 has-text-centered ">
        <Trans i18nKey="app.externalLink.label" defaults="External Link" />
      </div>
      <ListingLayout>
        <PortalExternalLinkInfo links={links} />
      </ListingLayout>
    </>
  )
}

function PortalExternalLinkListWithEditor({ links }) {
  //component for add button editor

  return (
    <>
      <div className="title is-2 has-text-centered ">
        <Trans i18nKey="app.externalLink.label" defaults="External Link" />
      </div>
      <ListingLayout>
        <PortalExternalLinkInfo links={links} />
        <EditorExternalLinkBox isAdd />
      </ListingLayout>
    </>
  )
}

function PortalExternalLinkInfo({ links }) {
  return _.map(links, (link, index) => (
    <React.Fragment key={index}>
      <PortalListLinkCard link={link} index={index} />
    </React.Fragment>
  ))
}

const ListingLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.5rem;
`
