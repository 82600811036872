import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import useForm from '../../hooks/useForm'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'
import FormLocationContainer from '../form-location/FormLocationContainer'

const locationModalStyle = css`
  .modal-card {
    @media screen and (min-width: 769px) {
      min-width: 920px;
    }
  }
`

export default function EditorLocationBox({ children }) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  return (
    <EditorLocationBoxStyle
      onClick={() =>
        showModal({
          params: {
            title: t('actions.edit.label', {
              title: t('app.location.label', {
                defaultValue: 'Location',
              }),
              defaultValue: 'Edit {{title}}',
            }),
          },
          modalRef: 'EDITOR_LOCATION_MODAL',
          options: { modalStyle: locationModalStyle },
        })
      }
    >
      {children}
      <span className="icon">
        <i className="fas fa-edit fa-lg" />
      </span>
    </EditorLocationBoxStyle>
  )
}

const EditorLocationBoxStyle = styled.div.attrs({
  className: '__editor-box __editor-location-box __highlight-editor-box',
})``

export function EditorLocationModal({ onClose }) {
  const {
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)

  const { values, handleSubmit, setValues, setFieldValue } = useForm({
    initialState: {
      ..._.get(portal, 'location'),
      streetAddress: {
        ..._.get(portal, 'location.streetAddress'),
      },
    },
    onValidate: () => {
      const errors = {}
      return errors
    },
    onSubmit: location => {
      updatePortal({ _id: portal._id, location })
        .then(portal => {
          updateLocalData(portal)
          handleClose()
        })
        .catch(error => window.alert(error.message))
    },
    reinitialState: true,
  })

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <section className="modal-card-body">
        <FormLocationContainer
          key={values._id}
          language={language}
          locationInfo={values}
          onLatLngChange={(lat, lng) => {
            setValues(prevValues => ({
              ...prevValues,
              latitude: lat,
              longitude: lng,
            }))
          }}
          onLocationChange={({ name, value }) => {
            setFieldValue(name, value)
          }}
        />
      </section>
      <FooterConfirmModal onSubmit={handleSubmit} onClose={handleClose} />
    </>
  )
}
