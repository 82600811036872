import React from 'react'
import _ from 'lodash'
import usePromise from 'react-use-promise'
import { Redirect } from 'react-router-dom'

import PortalListTagGroup from '../templates/portal-list/PortalListTagGroup'
import HomeBannerWithSearchBar from '../templates/home/HomeBannerWithSearchBar'
import SliderWithLayout from '../views/widgets/SliderWithLayout'
import TownListCard from '../templates/town-list/TownListCard'
import ListingListCard from '../templates/listing-list/ListingListCard'
import PortalListNearBy from '../templates/portal-list/PortalListNearBy'
import { getTownList, getListingList } from '../services/elasticService'
import { tranformToImageURL } from '../services/remoteUtils'
import { translate } from '../services/lang'
import { useTranslation } from 'react-i18next'
import Meta from '../views/widgets/Meta'
import InfoContext from '../contexts/InfoContext'

export default function HomePage() {
  const { remoteWebConfig } = React.useContext(InfoContext)
  

  const community = process.env.REACT_APP_COMMUNITY
  if (!_.isEmpty(community)) {
    return <Redirect to={`/towns/${community}`} />
  }

  return (
    <>
      <Meta
        title={remoteWebConfig.title || 'Glog'}
        imageURL={remoteWebConfig.logoImageURL || `/__images/logo.png`}
        link={[
          {
            rel: 'shortcut icon',
            href: remoteWebConfig.logoImageURL || `/__images/logo.png`,
          },
        ]}
        url={window.location.href}
      />
      <div>
        <HomeBannerWithSearchBar />
        <div className="has-background-white">
          <br />
          <div className="container">
            <div className="section">
              <HomeTownList />
              <br />
              <HomeListingList />
              <PortalListNearBy />
              <hr />
              {_.map(remoteWebConfig.tags, tag => (
                <PortalListTagGroup tag={tag} key={tag} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function HomeTownList() {
  const {
    i18n: { language },
  } = useTranslation()
  const [towns] = usePromise(getTownList, [])
  const _towns = tranformToImageURL(translate(towns, language), 'images')

  return (
    <SliderWithLayout>
      {_.map(_towns, town => (
        <TownListCard town={town} key={town._id} />
      ))}
    </SliderWithLayout>
  )
}

function HomeListingList() {
  const {
    i18n: { language },
  } = useTranslation()
  const [listings] = usePromise(() => getListingList({}, { limit: 8 }), [])
  const _listings = tranformToImageURL(
    translate(listings, language),
    'images',
  )

  return (
    <SliderWithLayout
      settings={{ slidesToShow: 5 }}
      mSettings={{ slidesToShow: 3 }}
    >
      {_.map(_listings, listing => (
        <ListingListCard listing={listing} key={listing._id} />
      ))}
    </SliderWithLayout>
  )
}
