import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputFileBox from '../../views/widgets/InputFileBox'
import { getDefaultLangText } from '../../constants/utils'
import ConfirmModalContext from '../../contexts/ConfirmModalContext'
import PortalContext from '../../contexts/PortalContext'
import { FooterConfirmModal } from '../../hooks/useConfirmModal'
import { updatePortal } from '../../services/remoteService'
import InputTextarea from '../../views/widgets/InputTextarea'
import InputText from '../../views/widgets/InputText'
import useImageCropEditModal from '../../modules/image-crop/useImageCropEditModal'
import {
  uploadPortalImage,
  removePortalImage,
} from '../../services/remoteImageService'
import { cardMediaReponsive } from '../../views/utils'
import { createRemoteURL } from '../../config'

export default function EditorExternalLinkBox({
  children,
  isAdd,
  params = {},
}) {
  const { t } = useTranslation()
  const { showModal } = React.useContext(ConfirmModalContext)

  function handleShowModal(e) {
    if (e) {
      e.stopPropagation()
    }
    showModal({
      params: {
        title: isAdd
          ? t('actions.add.label', {
              title: t('app.galleryImage.label', {
                defaultValue: 'Content',
              }),
              defaultValue: 'Add {{title}}',
            })
          : t('actions.edit.label', {
              title: t('app.galleryImage.label', {
                defaultValue: 'Content',
              }),
              defaultValue: 'Edit {{title}}',
            }),
        isAdd,
        ...params,
      },
      modalRef: 'EDITOR_EXTERNAL_LINK_MODAL',
    })
  }

  return (
    <EditorExternalLinkBoxStyle isAdd={isAdd}>
      {children}

      {isAdd ? (
        <div className="card" onClick={handleShowModal}>
          <span className="icon">
            <i className="fas fa-plus-circle fa-4x" />
          </span>
        </div>
      ) : (
        <span className="icon" onClick={handleShowModal}>
          <i className="fas fa-edit fa-lg fa-lg" />
        </span>
      )}
    </EditorExternalLinkBoxStyle>
  )
}

const EditorExternalLinkBoxStyle = styled.div.attrs({
  className: '__editor-cover-info-box __highlight-editor-box',
})`
  ${props =>
    props.isAdd
      ? css`
          ${cardMediaReponsive}
          .card {
            height: 100%;
            min-height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `
      : css`
          height: 100%;
          width: 100%;

          .icon {
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 20;
            color: white;
          }
        `}
`

/**
|--------------------------------------------------
| MODAL
|--------------------------------------------------
*/

export function EditorExternalLinkModal({ params = {}, onClose }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { portal, updateLocalData } = React.useContext(PortalContext)
  const portalID = _.get(portal, '_id')
  const link = _.get(portal, `links[${params.index}]`) || {}

  const [imageURL, setImageURL] = React.useState()
  const { values, errors, setFieldValue, handleChange, handleSubmit } = useForm(
    {
      initialState: {
        title: getDefaultLangText(),
        description: getDefaultLangText(),
        externalUrl: undefined,
        mainImageId: undefined,
        ...link,
      },
      onValidate: ({ values }) => {
        const errors = {}
        if (_.isEmpty(values.externalUrl)) {
          errors.externalUrl = t('form.require.label', {
            title: t('field.link.externalUrl.label', {
              defaultValue: 'External Url',
            }),
            defaultValue: 'Require {{title}} field',
          })
        }

        return errors
      },
      onSubmit: link => {
        const links = mergeWithLinks(link, params.index)
        updatePortal({ _id: portalID, links })
          .then(portal => {
            updateLocalData(portal)
            handleClose()
          })
          .catch(error => window.alert(error.message))
      },
      reinitialState: true,
    },
  )

  const imageId = _.get(link, `mainImageId`)

  React.useEffect(() => {
    setImageURL(
      imageId ? createRemoteURL('/images/' + imageId, portal.provider) : null,
    )
  }, [imageId, portal.provider])

  const { showModal } = useImageCropEditModal({
    config: {
      imageAspectRatio: 4 / 3,
    },
    onImageCrop: result => {
      const croppedImageFile = _.get(result, 'croppedImageFile')

      if (croppedImageFile) {
        uploadPortalImage(portalID, croppedImageFile, imageId || '').then(
          result => {
            setFieldValue('mainImageId', result.ref)
          },
        )
        setImageURL(URL.createObjectURL(croppedImageFile))
      }
    },
  })

  function handleDeleteLink() {
    if (
      _.isNumber(params.index) &&
      window.confirm(
        t('actions.delete.confirm.label', {
          defaultValue: 'Are you sure delete?',
        }),
      )
    ) {
      const link = _.get(values, 'mainImageId')

      _.pullAt(portal.links, params.index) //mutate links

      removePortalImage(portalID, link.mainImageId)
        .then(() => {
          return updatePortal({
            _id: portalID,
            links: portal.links,
          }).then(portal => {
            updateLocalData(portal)
            handleClose()
          })
        })
        .catch(error => window.alert(error.message))
    }
  }

  function mergeWithLinks(link, index) {
    let links = _.get(portal, 'links') || []
    if (!_.isNumber(index)) {
      links.push(link)
    } else {
      links = _.map(links, (_link, _index) => {
        if (_index === index) {
          return link
        }
        return _link
      })
    }
    return links
  }

  function handleUploadImage(e) {
    const file = _.get(e, 'target.files[0]')

    if (file) {
      showModal(file)
    }
    e.target.value = '' //Fixed Can't upload same file twice
  }

  function handleClose() {
    if (onClose) {
      onClose(null)
    }
  }

  return (
    <>
      <EditorLinkModalStyle
        className="modal-card-body"
        isImage={!_.isEmpty(imageURL)}
      >
        <Field
          label={`${t('field.imageLink.label', {
            defaultValue: 'Image Link',
          })}`}
        >
          <label htmlFor="__upload-photo">
            <div className="__editor-image">
              {imageURL && (
                <LazyLoadImage
                  src={imageURL}
                  alt={values.title}
                  effect="blur"
                />
              )}
              <InputFileBox
                onChange={handleUploadImage}
                inputProps={{ accept: 'image/*' }}
              />
              <span className="icon">
                <i className="fas fa-camera fa-3x" />
              </span>
            </div>
          </label>
        </Field>
        <Field
          label={`${t('field.link.externalUrl.label', {
            defaultValue: 'External link',
          })}`}
        >
          <InputText
            name={`externalUrl`}
            placeholder={t('field.link.externalUrl.placeholder', {
              defaultValue: 'Enter your external url.',
            })}
            onChange={handleChange}
            value={values.externalUrl}
            error={errors.externalUrl && errors.externalUrl}
          />
        </Field>
        <Field
          label={`${t('field.link.title.label', {
            defaultValue: 'link title',
          })} [${language}]`}
        >
          <InputText
            name={`title.${language}`}
            placeholder={t('field.link.title.placeholder', {
              defaultValue: 'Enter your link title.',
            })}
            onChange={handleChange}
            value={values.title[language]}
            error={errors.title && errors.title[language]}
          />
        </Field>

        <Field
          label={`${t('field.link.description.link.label', {
            defaultValue: 'link description',
          })} [${language}]`}
        >
          <InputTextarea
            name={`description.${language}`}
            placeholder={t('field.link.description.placeholder', {
              defaultValue: 'Enter your link description.',
            })}
            onChange={handleChange}
            value={values.description[language]}
            error={errors.description && errors.description[language]}
          />
        </Field>
      </EditorLinkModalStyle>
      <FooterConfirmModal
        onSubmit={handleSubmit}
        onClose={handleClose}
        onDelete={_.isNumber(params.index) ? handleDeleteLink : undefined}
      />
    </>
  )
}

const EditorLinkModalStyle = styled.section.attrs({
  className: 'modal-card-body',
})`
  label {
    cursor: pointer;
  }

  ${props =>
    props.isImage
      ? css`
          .__input-file-box {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }
        `
      : css`
          .__editor-image {
            display: none;
          }
          .icon {
            display: none;
          }
        `}

  .__editor-image {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .__editor-image img {
    opacity: 0.8 !important;
  }

  .__image-field {
    position: relative;
    background: blue;
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    opacity: 0.6;
    color: black;
  }

  #__upload-photo {
  }
`
