import React from 'react'
import _ from 'lodash'
import traverse from 'traverse'
import PropTypes from 'prop-types'

import usePrevious from './usePreviouse'
import logger from '../services/logger'
import { setIn } from '../modules/utils'

export default function useForm({
  initialState = {},
  onValidate,
  onSubmit,
  reinitialState = false,
}) {
  const [_reinitialState] = React.useState(reinitialState)
  const [values, setValues] = React.useState(initialState)
  const [errors, setErrors] = React.useState({})
  const prevProps = usePrevious(initialState)

  React.useEffect(() => {
    if (
      _reinitialState &&
      !_.isEqual(prevProps, initialState) &&
      !_.isUndefined(prevProps)
    ) {
      // logger.debug('_reinitialState', _reinitialState, initialState, values)
      logger.info('[useForm] reinitialState', values._id, initialState._id)
      //initialState value
      setValues(initialState)
    }
  }, [values, initialState, _reinitialState, prevProps])

  // logger.debug(
  //   'values (values, initialState, prevProps)',
  //   values._id,
  //   initialState._id,
  //   _.get(prevProps, '_id'),
  // )

  const handleSubmit = event => {
    if (event) event.preventDefault()
    const errors = onValidate ? onValidate({ values }) || {} : {}

    if (_.isEmpty(errors)) {
      if (onSubmit) {
        onSubmit(values)
      }
    } else {
      setErrors(errors)
    }
  }

  const getFieldValue = event => {
    return {
      name: event.target.name,
      value: event.target.value,
    }
  }

  const handleChange = event => {
    event.persist()
    const { name, value } = getFieldValue(event)
    setErrors(setIn(name, undefined, errors))
    setValues(prevValues=> setIn(name, value, prevValues))
  }

  const handleSetFieldValue = (name, value) => {
    setErrors(setIn(name, undefined, errors))
    setValues(prevValues=> setIn(name, value, prevValues))
  }

  const onBindInput = name => {
    return {
      name,
      value: _.get(values, name),
      error: _.get(errors, name),
      onChange: handleChange,
    }
  }

  const handleResetError = () => {
    setErrors({})
  }

  return {
    bindInput: onBindInput,
    setFieldValue: handleSetFieldValue,
    setValues,
    resetErrors: handleResetError,
    handleChange,
    handleSubmit,
    isValues: checkIsValues(values),
    errors,
    values,
  }
}

useForm.propTypes = {
  initialState: PropTypes.object,
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func,
  reinitialState: PropTypes.bool,
}

export function checkIsValues(values) {
  return traverse(values).reduce(function(acc, x) {
    if (this.isLeaf) {
      acc = acc || !_.isEmpty(x)
    }
    return acc
  }, false)
}
