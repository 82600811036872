import _ from 'lodash'
import queryString from 'querystring'
import { doProviderFetch, doFetch } from './remoteUtils'
import { createRemoteURL } from '../config'

export function fetchUser(data) {
  return doProviderFetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw Error(`Error: ${res.status}`)
    })
    .then(res => {
      window.localStorage.setItem('jwt', res.jsonWebToken)
      return res
    })
}

export function fetchLoginState() {
  return doProviderFetch(
    '/api/auth/login',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    doFetch,
  ).then(res => {
    if (res.ok) {
      return res.json()
    }
    throw Error(`Error: ${res.status}`)
  })
}

//dynamic provider from portal
export function fetchPortal(portalID, provider) {
  return doFetch(createRemoteURL(`/api/portal/${portalID}`, provider))
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw Error(`Error: ${res.status}`)
    })
    .then(res => {
      return res.data
    })
}

export function fetchPortals() {
  return doProviderFetch(`/api/portal`, undefined, doFetch)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw Error(`Error: ${res.status}`)
    })
    .then(res => {
      return res.data
    })
}

export const createPortalWizard = portal =>
  doProviderFetch(
    `/api/wizard`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: portal,
      }),
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw new Error(result.reason)
      }
    })

export const updatePortalWizard = portal =>
  doProviderFetch(
    `/api/wizard/${portal._id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: portal }),
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw Error(result.reason)
      }
    })

export const createPortal = portal =>
  doProviderFetch(
    `/api/portal`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: portal,
      }),
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw new Error(result.reason)
      }
    })

export const createUser = user =>
  doProviderFetch(
    `/api/user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: user,
      }),
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw new Error(result.reason)
      }
    })

export const existedUsername = username =>
  doFetch(createRemoteURL(`/api/user?username=${username}`, {}))
    .then(response => {
      if (response.ok) return response.json()
      throw new Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw new Error(result.reason)
      }
    })

export const updatePortal = portal =>
  doProviderFetch(
    `/api/portal/${portal._id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: portal }),
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => {
      if (result.status === 'ok') {
        return result.data
      } else {
        throw Error(result.reason)
      }
    })

export const deletePortal = portal =>
  doProviderFetch(
    `/api/portal/${portal._id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(result => result.data)

export const providerGenerateQRcode = (provider, text, imageID, imagePath) => {
  const query = _.omitBy({ text, imageID, imagePath }, _.isEmpty)
  const queryStr = queryString.stringify(query)
  return doFetch(createRemoteURL(`/api/generateQRCode?${queryStr}`, provider), {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(json => {
      if (json.result === 'ok') {
        return json.data
      }
      throw new Error(json.reason)
    })
}

export const generateQRCode = (text, imageID, imagePath) => {
  const query = _.omitBy({ text, imageID, imagePath }, _.isEmpty)
  const queryStr = queryString.stringify(query)
  return doProviderFetch(
    `/api/generateQRCode?${queryStr}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
    doFetch,
  )
    .then(response => {
      if (response.ok) return response.json()
      throw Error(`${response.statusText} (${response.status})`)
    })
    .then(json => {
      if (json.result === 'ok') {
        return json.data
      }
      throw new Error(json.reason)
    })
}

export function fetchWebConfig() {
  return doProviderFetch(`/api/web-admin/`)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw Error(`Error: ${res.status}`)
    })
    .then(res => {
      return res.data
    })
}
