import * as querystring from 'querystring'
import _ from 'lodash'
import { doFetch, doProviderFetch } from './remoteUtils'
import logger from './logger'

export const uploadPortalImages = (id, files, longerWidth) => {
  const body = new FormData()
  const query = longerWidth ? `?${querystring.stringify({ longerWidth })}` : ''
  body.append('files', files)
  return doProviderFetch(
    `/api/portal/${id}/images` + query,
    {
      header: {
        'content-type': 'multipart/form-data',
      },
      method: 'POST',
      body,
    },
    doFetch,
  ).then(result => {
    if (result.status === 200) return result.json()
    throw Error(result)
  })
}

export const uploadImage = (
  file,
  replacingImageId = '',
  originalImageId,
  longerWidth,
) => {
  const body = new FormData()
  const query = longerWidth ? `?${querystring.stringify({ longerWidth })}` : ''
  body.append('file', file)

  if (originalImageId) {
    body.append('originalImageId', originalImageId)
  }

  return doProviderFetch(
    `/api/image/${replacingImageId}` + query,
    {
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      body,
    },
    doFetch,
  ).then(result => {
    if (result.status === 200) return result.json()
    throw new Error(result.statusText + ' | ' + result.status)
  })
}

export const uploadMainImage = (
  file,
  replacingImageId = '',
  originalImageId,
  longerWidth,
) => {
  const body = new FormData()
  const query = longerWidth ? `?${querystring.stringify({ longerWidth })}` : ''
  body.append('file', file)
  body.append('id', 'xxxx')

  if (originalImageId) {
    body.append('originalImageId', originalImageId)
  }

  return doProviderFetch(
    `/api/main-image/${replacingImageId}` + query,
    {
      method: 'POST',
      body,
    },
    doFetch,
  ).then(result => {
    if (result.status === 200) return result.json()
    throw new Error(result.statusText + ' | ' + result.status)
  })
}

export const uploadPortalImage = (
  id,
  file,
  replacingImageId = '',
  originalImageId,
  longerWidth,
) => {
  const body = new FormData()
  const query = longerWidth ? `?${querystring.stringify({ longerWidth })}` : ''

  body.append('file', file)

  if (originalImageId) {
    body.append('originalImageId', originalImageId)
  }

  return doProviderFetch(
    `/api/portal/${id}/image/${replacingImageId}` + query,
    {
      method: 'POST',
      body,
    },
    doFetch,
  ).then(result => {
    if (result.status === 200) return result.json()
    throw new Error(result.statusText + ' | ' + result.status)
  })
}

export const removePortalImages = (id, imageIds) =>
  Promise.all(_.map(imageIds, imageId => removePortalImage(id, imageId)))

export const removeImage = imageId => {
  return doProviderFetch(
    `/api/image/${imageId}`,
    {
      method: 'DELETE',
    },
    doFetch,
  )
    .then(result => {
      logger.debug('delete ', result)
      if (result.status === 200) return result.json()
      throw new Error(result.statusText + ' | ' + result.status)
    })
    .then(result => {
      logger.debug(result)
      if (result.status === 'ok') {
        return result
      } else {
        throw new Error(result.reason || '')
      }
    })
    .catch(error => {
      logger.debug(error)
      throw new Error('Remove Image error \n' + error.message || '')
    })
}

export const removePortalImage = (id, imageId) => {
  return doProviderFetch(
    `/api/portal/${id}/image/${imageId}`,
    {
      method: 'DELETE',
    },
    doFetch,
  )
    .then(result => {
      logger.debug('delete ', result)
      if (result.status === 200) return result.json()
      throw new Error(result.statusText + ' | ' + result.status)
    })
    .then(result => {
      logger.debug(result)
      if (result.status === 'ok') {
        return result
      } else {
        throw new Error(result.reason || '')
      }
    })
    .catch(error => {
      logger.debug(error)
      throw new Error('Remove Image error \n' + error.message || '')
    })
}

export const getImageFile = imageId =>
  doProviderFetch('/images/' + imageId, undefined, doFetch)
    .then(res => res.blob())
    .then(blob => new File([blob], imageId))

export const fetchImage = id => {
  return doProviderFetch(`/api/image/data/${id}`, undefined, doFetch).then(
    result => {
      if (result.status === 200) return result.json()
      throw Error(result)
    },
  )
}
