import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import IntroductionPage from '../wizard-form/IntroductionPage'
import NamingPage from '../wizard-form/NamingPage'
import UploadThumbnailPage from '../wizard-form/UploadThumbnailPage'
import UploadCoverPage from '../wizard-form/UploadCoverPage'
import InformationPage from '../wizard-form/InformationPage'
import ContactPage from '../wizard-form/ContactPage'
import FinishPage from '../wizard-form/FinishPage'

import FormDataContext from '../../contexts/FormDataContext'
import { fetchPortal } from '../../services/remoteService'
import logger from '../../services/logger'

const STEPS = {
  0: 'introduction',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: 'finish',
}

export function getStepIndexByPath(path) {
  return _.chain(STEPS)
    .findKey(step => step === path)
    .parseInt(0)
    .value()
}

export function getHashByStepIndex(stepIndex) {
  return STEPS[stepIndex]
}

export default function WizardPage({ location, history }) {
  const lastStep = _.size(STEPS) - 1
  const [step, setStep] = React.useState(getStepIndexByPath(getHash()))
  const [isRedirect, setIsRedirect] = React.useState(false)
  const {
    data,
    clearData,
    updateRemoteData,
    updateLocalData,
  } = React.useContext(FormDataContext)
  const currentPath = STEPS[step]
  const restoreId = _.get(location, 'state.id')

  React.useEffect(() => {
    if (!data._id && restoreId) {
      logger.info('[WizardPage] Restore portal')
      fetchPortal(restoreId)
        .then(portal => {
          setStep(portal.stepIndex)
          // logger.debug('portal', portal)
          updateLocalData(portal)
        })
        .catch(error => {
          logger.debug(error)
          setIsRedirect(true)
        })
    }

    return () => {}
  }, [data._id, restoreId, updateLocalData])

  if (
    isRestoreIdAndCurrentIdNotSame() ||
    (data.stepIndex === 0 && !_.isEmpty(data))
  ) {
    //check if restoreId and data id is not same id. -> clear
    clearData()
  }

  //case: not found data
  if (!restoreId && !data._id && step > 1 && !isRedirect) {
    setIsRedirect(true)
  }

  //case: pass > 2 step
  if ((!getHashByStepIndex(step) || step > data.stepIndex + 2) && !isRedirect) {
    setIsRedirect(true)
  }

  function isRestoreIdAndCurrentIdNotSame() {
    return (
      !_.isEmpty(data._id) &&
      !_.isEmpty(restoreId) &&
      !_.isEqual(data._id, restoreId)
    )
  }

  function getHash() {
    return decodeURI(window.location.hash).replace(/^#/, '')
  }

  function setHash(idx) {
    logger.debug('setHash', idx)
    history.replace({
      pathname: `/creator/wizard`,
      hash: `#${STEPS[idx]}`,
      state: {},
    })
  }

  function nextStep(_data, action) {
    let nextStep = step + 1
    logger.debug('next', step, _data._id, nextStep)

    if(step === 0){
      setStep(nextStep)
      setHash(nextStep)
      return;
    }

    const result = updateRemoteData(_data, nextStep)
    if (result) {
      result
        .then(() => {
          if (action === 'back_to_main') {
            history.replace('/creator')
          }

          if (action === 'edit_more') {
            history.push('/portals/' + _data._id)
          }

          if (nextStep > lastStep) {
            setIsRedirect(true)
          } else {
            setStep(nextStep)
            setHash(nextStep)
          }
        })
        .catch(error => {
          alert("can't next step " + error.message)
        })
    }
  }

  function prevStep() {
    let prevStep = step - 1 > 0 ? step - 1 : 0
    setStep(prevStep)
    setHash(prevStep)
  }

  if (isRedirect) {
    return <Redirect to="/creator" />
  }

  return (
    <WizardPageStyle className="__has-navbar">
      <div className="hero is-fullheight-with-navbar">
        <div className="steps has-text-centered">
          <StepItems currentPath={currentPath} />
        </div>
        <div className="steps-content">
          <StepContentHandler
            path={`introduction`}
            component={IntroductionPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`1`}
            component={NamingPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`2`}
            component={UploadThumbnailPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`3`}
            component={UploadCoverPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`4`}
            component={InformationPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`5`}
            component={ContactPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
          <StepContentHandler
            path={`finish`}
            component={FinishPage}
            currentPath={currentPath}
            onAfterValid={nextStep}
            onPrev={prevStep}
            initialValues={data}
          />
        </div>
      </div>
    </WizardPageStyle>
  )
}

const StepItems = ({ currentPath }) =>
  _.map(STEPS, (title, marker) => (
    <StepItem
      key={marker}
      marker={_.parseInt(marker)}
      title={title}
      currentPath={currentPath}
    />
  ))

const StepItem = ({ marker, title, currentPath }) => {
  const currentIndex = getStepIndexByPath(currentPath)

  return (
    <div
      className={classNames('step-item', {
        'is-active': currentIndex === marker,
        'is-completed': currentIndex > marker,
        'is-success': currentIndex > marker,
      })}
    >
      <div className="step-marker">{marker}</div>
      {!title && (
        <div className="step-details">
          <p className="step-title">{title}</p>
        </div>
      )}
    </div>
  )
}

const StepContentHandler = ({
  currentPath,
  path,
  component: Component,
  ...props
}) => {
  if (_.includes(path, currentPath)) {
    return <Component {...props} />
  } else {
    return null
  }
}

const WizardPageStyle = styled.div.attrs({ className: '__wizard-page-style' })`
  display: flex;
  height: 100%;
  width: 100%;

  max-width: 840px;
  margin: 0 auto;

  .hero {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    width: 100%;
  }

  .steps {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-right: 8px;
    padding-left: 8px;
    height: 32px;
  }
  .steps-content {
  }
  .form-group {
    width: 100%;
    margin: 0 auto;
    padding: 32px;
    max-width: 720px;
  }
`
