import React, { Component } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import _ from 'lodash'

export default function(ComposedComponent) {
  return class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
    }

    componentDidMount() {
      window.addEventListener('online', this.handleConnectionChange)
      window.addEventListener('offline', this.handleConnectionChange)
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange)
      window.removeEventListener('offline', this.handleConnectionChange)
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline'
      if (condition === 'online') {
        const webPing = setInterval(() => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing)
              })
            })
            .catch(() => this.setState({ isDisconnected: true }))
        }, 2000)
        return
      }

      return this.setState({ isDisconnected: true })
    }

    render() {
      const { isDisconnected } = this.state

      return (
        <div>
          {isDisconnected && (
            <InterNerErrorStyled className={'animated slideInUp'}>
              <p>Internet connection lost</p>
            </InterNerErrorStyled>
          )}
          <ComposedComponent {...this.props} />
        </div>
      )
    }
  }

}

const InterNerErrorStyled = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #ff8100;
  margin-top: 0;
  font-size: 20px;
  text-align: center;
  z-index: 1000;
  > p {
    font-size: 25px;
    line-height: 50px;
    color: #fff;
    margin: 0;
  }
`
