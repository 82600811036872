import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import AuthContext from '../../contexts/AuthContext'
import PortalContext from '../../contexts/PortalContext'

export default function EditorWithAuthHandler({
  renderCustomEditor,
  editorComponent: Editor,
  children,
  params = {},
}) {
  const { user } = React.useContext(AuthContext)
  const { portal } = React.useContext(PortalContext)

  if (!_.includes(portal.editors, user.uid)) {
    return children ? children : null
  } else {
    if (!_.isUndefined(renderCustomEditor)) {
      return renderCustomEditor()
    }

    if (Editor) {
      return <Editor params={params}>{children}</Editor>
    } else {
      return null
    }
  }
}

EditorWithAuthHandler.propsType = {
  editorComponent: PropTypes.any,
}
