import React from 'react'
import styled from 'styled-components'
import className from 'classnames'

const ButtonContainer = styled.div`
    position: relative;
    z-index: ${props => props.isActiveDropdown? '20':'0'}
`
const BackgroudDropdown = styled.div`
    position:fixed;
    top:0px;
    left:0px;
    background:rgba(255,255,255,.75);
    width:100%;
    height:100%;
    z-index:6;
    display: ${props => props.isActiveDropdown? 'block':'none'}
`

export default function useDropDown(WrappedComponent){
    return class extends React.Component{

        state = {
            isActiveDropdown: false
        }

        _handleOpenDropdown = () => {
            this.setState({ isActiveDropdown: true})

            if(this.props.onOpenDropdown){
                this.props.onOpenDropdown()
            }
        }

        _handleCloseDropdown = (position) => {
            this.setState({ isActiveDropdown: false})

            if(this.props.onCloseDropdown){
                this.props.onCloseDropdown(position)
            }
        }

            
        render(){

            //console.log(this.state.isActiveDropdown);
            return (
                <div >
                    <BackgroudDropdown 
                        onClick = {this._handleCloseDropdown}
                        isActiveDropdown={this.state.isActiveDropdown} />
                    <ButtonContainer isActiveDropdown={this.state.isActiveDropdown} >
                        <Dropdown 
                            isActiveDropdown={this.state.isActiveDropdown}
                            button={
                                <WrappedComponent 
                                    {...this.props} 
                                    onClick={this._handleOpenDropdown}
                                    isActive={this.state.isActiveDropdown}
                                    />
                            }
                            content={this.props.children}
                            onClose={() => this._handleCloseDropdown('CLOSE_VIEW')}
                            renderCloseView={this.props.renderCloseView}
                        />
                    </ButtonContainer>
                </div>
            )
        }
    }
}

const Dropdown = ({ isActiveDropdown, button, content, renderCloseView, onClose }) => (
    <div className={
        className(
            'dropdown',
            { 'is-active': isActiveDropdown }
        )
    }
        style={{width:'100%'}}
    >
        <div className="dropdown-trigger"  style={{width:'100%'}}>
            {button}
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ width: 'auto'}}>
            <div className="dropdown-content" style={{padding: '.5rem'}}>
                {content}
                { 
                    renderCloseView && <div onClick={onClose}>
                        { renderCloseView }
                    </div>
                }   
            </div>
        </div>
    </div>
)