import React from 'react'
import _ from 'lodash'
import firebase from 'firebase/app'

import { fetchUser, fetchLoginState } from '../services/remoteService'
import { getJWT } from '../services/remoteUtils'

const useAuthHandler = () => {
  const [userAuth, setUserAuth] = React.useState({})
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !_.isEmpty(getJWT()),
  )

  React.useEffect(() => {
    //register authState
    fetchLoginState()
      .then(userAuth => {
        setUserAuth(userAuth)
        setIsAuthenticated(true)
      })
      .catch(error => {
        setUserAuth({})
        setIsAuthenticated(false)
        console.error(error)
      })
  }, [])

  // data: {
  //   provider: 'facebook',
  //   token,
  // }
  async function loginWithRemote(data) {
    const userAuth = await fetchUser(data).catch(error => {
      alert('Please Check Your Username Or Password Is Incorrect')
    })
    if (!_.isEmpty(userAuth)) {
      setUserAuth(userAuth)
      setIsAuthenticated(true)
    } else setIsAuthenticated(false)
  }

  function logout() {
    firebase
      .auth()
      .signOut()
      .then(result => {
        window.localStorage.removeItem('jwt')
        setUserAuth({})
        setIsAuthenticated(false)
      })
  }

  return {
    isAuthenticated,
    user: userAuth,
    loginWithRemote,
    logout,
  }
}

export default useAuthHandler
