import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Highlighter from "react-highlight-words";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { tranformToImageURL } from "../../services/remoteUtils";
import { translate } from "../../services/lang";

export default function TownListItem({
  town: _town,
  searchText,
  language = "th"
}) {
  const town = tranformToImageURL(translate(_town, language));
  return (
    <div className="media">
      <div className="media-left">
        <ThumbnailImageLayout className="image">
          {_.get(town, "imageURL") && (
            <LazyLoadImage
              src={_.get(town, "imageURL")}
              alt={_.get(town, "title")}
              effect="blur"
            />
          )}
          {!_.get(town, "imageURL") && (
            <LazyLoadImage
              src={require('../../assets/images/placeholder-image.png')}
              alt={'no-image'}
              effect="blur"
            />
          )}
        </ThumbnailImageLayout>
      </div>
      <div className="media-content">
        <div className="content">
          <div>
            <div className="title is-6">
              <Highlighter
                highlightClassName="__text-highlight"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={_.get(town, `title`)}
              />
            </div>
            <div className="subtitle is-6 has-text-grey-light">
              <Highlighter
                highlightClassName="__text-highlight"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={_.get(town, `title`)}
              />
            </div>
            {/* <br /> */}
            {/* <div className="tag is-primary heading">tags</div> {tagsText} */}
          </div>
        </div>
      </div>
    </div>
  );
}

const ThumbnailImageLayout = styled.div`
  img {
    /* width: 64px;
    height: 64px; */
    height: 48px;
    width: 112px;
    object-fit: cover;
    object-position: center;
  }
`;
