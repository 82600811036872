import React from 'react'
import ReactGA from 'react-ga'

const withTracker = (WrappedComponent, options = {}) => {
  if (process.env.NODE_ENV !== 'development') {
    const trackPage = page => {
      ReactGA.set({
        page,
        ...options,
      })
      ReactGA.pageview(page)
    }

    const HOC = props => {
      React.useEffect(() => trackPage(props.location.pathname), [
        props.location.pathname,
      ])

      return <WrappedComponent {...props} />
    }

    return HOC
  } else {
    return WrappedComponent
  }
}

export default withTracker
