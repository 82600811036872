import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useForm from '../../hooks/useForm'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import InputPassWord from '../../views/widgets/InputPassWord'
import { createUser, existedUsername } from '../../services/remoteService'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function SignUpForm() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [user, setUser] = React.useState({})
  const [isRegister, setIsRegister] = React.useState(false)
  const { values, errors, setFieldValue, handleChange, handleSubmit } = useForm(
    {
      initialState: {
        roles: ['portal_creator'],
        availableTowns: [],
        markAsDeleted: false,
        displayName: '',
        username: '',
        password: '',
        confirmPassword: '',
      },
      onValidate: user => {
        const errors = {}

        if (_.isEmpty(user.values.username)) {
          errors.username = 'required'
        }

        if (_.isEmpty(user.values.password)) {
          errors.password = 'required'
        }

        if (_.isEmpty(user.values.confirmPassword)) {
          errors.confirmPassword = 'required'
        } else if (
          !_.isEqual(user.values.password, user.values.confirmPassword)
        ) {
          errors.confirmPassword = 'Password Not Match'
        }

        if (_.isEmpty(user.values.displayName)) {
          errors.displayName = 'required'
        }

        return errors
      },
      onSubmit: async user => {
        const result = _.omit(user, 'confirmPassword')
        const existed = await existedUsername(user.username)
        if (_.isEmpty(existed)) {
          createUser(result)
            .then(() => setIsRegister(true))
            .catch(error => {
              return window.alert(error.message)
            })
        } else {
          toast.error('Username already exists please use another Username')
        }
      },
      reinitialState: true,
    },
  )

  if (isRegister) {
    toast.success('Create User Success')
    return <Redirect to={`/login`} replace />
  }
  return (
    <SignUpContainer>
      <SignUpHeader t={t} />
      <ActionPanel onSubmit={handleSubmit} t={t} />
      <Field
        label={t('field.signUp.username.label', { defaultValue: 'Username' })}
      >
        <InputText
          name={`username`}
          placeholder={t('field.signUp.username.placeholder', {
            defaultValue: 'Enter your username.',
          })}
          onChange={e => setFieldValue('username', e.target.value)}
          value={values.username}
          error={errors.username}
        />
      </Field>
      <Field
        label={t('field.signUp.password.label', { defaultValue: 'Password' })}
      >
        <InputPassWord
          name={`password`}
          placeholder={t('field.signUp.password.placeholder', {
            defaultValue: 'Enter your password.',
          })}
          onChange={e => setFieldValue('password', e.target.value)}
          value={values.password}
          error={errors.password}
        />
      </Field>
      <Field
        label={t('field.signUp.confirmPassword.label', {
          defaultValue: 'Confirm Password',
        })}
      >
        <InputPassWord
          name={`confirmPassword`}
          placeholder={t('field.signUp.confirmPassword.placeholder', {
            defaultValue: 'Enter your confirmPassword.',
          })}
          onChange={e => setFieldValue('confirmPassword', e.target.value)}
          value={values.confirmPassword}
          error={errors.confirmPassword}
        />
      </Field>
      <Field
        label={t('field.signUp.displayName.label', {
          defaultValue: 'Display Name',
        })}
      >
        <InputText
          name={`displayName`}
          placeholder={t('field.signUp.displayName.placeholder', {
            defaultValue: 'Enter your displayName.',
          })}
          onChange={e => setFieldValue('displayName', e.target.value)}
          value={values.displayName}
          error={errors.displayName}
        />
      </Field>
    </SignUpContainer>
  )
}

const SignUpContainer = styled.section`
  margin-top: 20px;
`

export function ActionPanel({ onSubmit, t }) {
  return (
    <div style={{ textAlign: 'right', marginBottom: '2em' }}>
      <button className="button is-primary" onClick={onSubmit}>
        {t('actions.save.label')}
      </button>
    </div>
  )
}

export function SignUpHeader({ t }) {
  return <div className="title">{t('field.signUp.label')}</div>
}
