import React from 'react'
import _ from 'lodash'

import FormLocation from './FormLocation'
import { getDefaultLangText } from '../../constants/utils'
import config from '../../config'

export const LOCATION_STATUS = {
  PENDING: 'PENDING',
  COMPLATED: 'COMPALTED',
  ERROR: 'ERROR',
}

const initialInformation = (locationInfo = {}) => {
  return {
    streetAddress: getDefaultLangText(),
    ...locationInfo,
  }
}

const isStreetAddressEmpty = locationInfo => {
  return _.reduce(
    config.supportedLanguages,
    (prev, lang) =>
      prev && _.isEmpty(_.get(locationInfo, 'streetAddress.' + lang)),
    true,
  )
}

const isLatLngEmpty = (locationInfo = {}) => {
  return !(
    _.isNumber(locationInfo.latitude) && _.isNumber(locationInfo.longitude)
  )
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          resolve(location)
        },
        error => {
          reject(error)
        },
      )
    } else {
      reject(new Error('Geolocation is not supported by this browser.'))
    }
  })
}

const getStreetAddress = (latitude, longitude) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${
      config.googleApi.mapKey
    }&language=th`,
  )
    .then(res => res.json())
    .then(result => {
      if (result.status === 'OK') {
        return result.results[0].formatted_address
      } else if (result.error_message) {
        throw new Error(result.error_message)
      } else {
        throw new Error("Can't get street Address")
      }
    })
    .catch(error => {
      this.setState({ errorStreetAdressMsg: error.message })
    })
}

const getLatLng = location => {
  return _.get(location, 'coords')
}

export default function FormLocationContainer({
  locationInfo,
  isShowMap,
  onLocationChange,
  onLatLngChange,
}) {
  const [status, setStatus] = React.useState()
  const [center, setCenter] = React.useState({})

  //componentDidMount
  React.useEffect(() => {
    if (isLatLngEmpty(locationInfo)) {
      initDefaultLocation()
    } else {
      setCenter({ lat: locationInfo.latitude, lng: locationInfo.longitude })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function initDefaultLocation() {
    try {
      const location = await getLocation()
      const latLng = getLatLng(location)

      if (isLatLngEmpty(locationInfo)) {
        onLatLngChange(latLng.latitude, latLng.longitude)
        setCenter({ lat: latLng.latitude, lng: latLng.longitude })
      }

      if (isStreetAddressEmpty(locationInfo)) {
        const streetAddress = await getStreetAddress(
          latLng.latitude,
          latLng.longitude,
        )
        onLocationChange({
          name: `streetAddress.th`,
          value: streetAddress,
        })
      }

    } catch (error) {
      setStatus(LOCATION_STATUS.ERROR)
    }
  }

  async function handleDefaultLocationClick() {
    try {
      const location = await getLocation()
      const latLng = getLatLng(location)

      onLatLngChange(latLng.latitude, latLng.longitude)
      setCenter({ lat: latLng.latitude, lng: latLng.longitude })

      if (isStreetAddressEmpty(locationInfo)) {
        const streetAddress = await getStreetAddress(
          latLng.latitude,
          latLng.longitude,
        )
        onLocationChange({
          name: `streetAddress.th`,
          value: streetAddress,
        })

      }
    } catch (error) {
      setStatus(LOCATION_STATUS.ERROR)
    }
  }

  function handleChange(e) {
    let { name, value } = e.target

    if (name === 'latitude' || name === 'longitude') {
      value = value ? parseFloat(value) : ''
    }

    if (name === 'latitude') {
      setCenter({ ...center, lat: value })
    }

    if (name === 'longitude') {
      setCenter({ ...center, lng: value })
    }

    onLocationChange({ name: `${name}`, value })
  }

  function handleMarkerChange(latLng) {
    // {lat, lng})
    onLatLngChange(latLng.lat, latLng.lng)
    setCenter(latLng)
  }

  return (
    <FormLocation
      values={initialInformation(locationInfo)}
      isShowMap={isShowMap}
      status={status}
      center={center}
      onChange={handleChange}
      onMarkerChange={handleMarkerChange}
      onDefaultLocationClick={handleDefaultLocationClick}
    />
  )
}
