import React from 'react'
export default function useInfiniteScroll(callback) {
  const [isFetching, setIsFetching] = React.useState(false)
  const [isMore, setIsMore] = React.useState(true)

  React.useEffect(() => {
    setIsFetching(true)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // console.log(isMore, isFetching);
    if (!isMore) return

    if (!isFetching) return
    callback(() => {
      // console.log('called back')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isMore])

  function handleScroll() {
    // console.log(
    //   'handleScroll',
    //   window.innerHeight + document.documentElement.scrollTop !==
    //     document.documentElement.offsetHeight,
    //   isFetching,
    // );
    if (
      window.innerHeight +
        (window.pageYOffset || document.documentElement.scrollTop) +
        200 <
        document.documentElement.offsetHeight ||
      isFetching
    )
      return
    setIsFetching(true)
  }

  return [isFetching, setIsFetching, isMore, setIsMore]
}
