import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ActionPanelLayout from '../../views/widgets/ActionPanelLayout'
import { stepContentPagePropTypes } from '../../constants/prop-types'

export default function IntroductionPage({ onAfterValid }) {
  const { t } = useTranslation()

  return (
    <IntroductionPageStyle>
      <div className="__introduction-form-group ">
        <div className="__topic header">
          <h3 className="title is-3">
            <Trans
              i18nKey="wizard.introduce.title.label"
              values={{ title: t('app.title.label', { defaultValue: 'Glog' }) }}
              defaults="Welcome {{title}}!"
            />
          </h3>
        </div>
        <div className="__box-content custom-scroll">
          <span>
            <Trans i18nKey="wizard.introduce.content.label" />
          </span>
        </div>
      </div>
      <ActionPanelLayout>
        <div className="is-hidden-mobile __is-center">
          <button
            className="button is-primary"
            onClick={() => onAfterValid({})}
          >
            <Trans i18nKey="actions.create.label" />
          </button>
        </div>
        <div className="is-hidden-tablet __is-center">
          <button
            className="button is-medium is-fullwidth is-primary"
            onClick={() => onAfterValid({})}
          >
            <Trans i18nKey="actions.create.label" />
          </button>
        </div>
      </ActionPanelLayout>
    </IntroductionPageStyle>
  )
}

IntroductionPage.propTypes = {
  ...stepContentPagePropTypes,
}

const IntroductionPageStyle = styled.div.attrs({
  className: '__introduction-page-style',
})`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;

  .__introduction-form-group {
    display: flex;
    overflow: hidden;
    flex-direction: column;

    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .__topic {
    height: 50px;
  }

  .__box-content {
    background: #eee;
    flex-grow: 1;
    max-height: 60vh;
    display: flex;
    overflow: auto;
    border-radius: 16px;
    padding: 16px;
  }
  .__action-panel-layout {
    height: 80px;
  }
`
