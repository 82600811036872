import _ from "lodash";
import logger from "./logger";
import config from "../config";

const indexes = config.elasticIndexes;

function executeElasticSearch({ index, body }) {
  // console.log('index=', index, JSON.stringify(body, null, 2));
  return fetch(`${config.elasticSearchEndpoint}/${index}/_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then(res => res.json())
    .then(result => {
      const hits = _.map(_.get(result, "hits.hits"), r => ({
        _id: r._id,
        id: r._id,
        _index: r._index,
        ...r._source
      }));
      return hits;
    });
}

function injectSmartSearchLangText({ field = "title", searchText }) {
  let queries = [];
  const texts = _.chain(searchText)
    .trim()
    .split(" ")
    .value();

  _.each(texts, text => {
    const _text = _.toLower(text);
    queries = [
      ...queries,
      { wildcard: { [`${field}.th`]: `*${_text}*` } },
      { wildcard: { [`${field}.en`]: `*${_text}*` } },
      { wildcard: { [`${field}.zh`]: `*${_text}*` } }
    ];
  });

  return queries;
}

export function getPortalList(query = {}, { limit = 10, startAt = 0 }) {
  const searchSchema = {
    from: startAt,
    size: limit,
    sort: [{ modifiedDate: "desc" }],
    _source: {
      includes: [
        "title.*",
        "modifiedDate",
        "mainImageId",
        "shortDecsription.*",
        "highlightSentence.*",
        "tags",
        "provider"
      ]
    },
    query: {
      bool: {
        must: []
      }
    }
  };

  if (query.title) {
    searchSchema.sort.push({ "title.th.keyword": { order: "asc" } });
    searchSchema.query.bool.must = [
      {
        bool: {
          should: injectSmartSearchLangText({
            field: "title",
            searchText: query.title
          })
        }
      }
    ];
  }

  if (query.town || _.get(query, "town.id")) {
    searchSchema.query.bool.must = searchSchema.query.bool.must = [
      ...searchSchema.query.bool.must,
      {
        term: {
          townRefs: query.town || _.get(query, "town.id")
        }
      }
    ];
  }

  if (query.listingRef || _.get(query, "listing.id")) {
    searchSchema.query.bool.must = searchSchema.query.bool.must = [
      ...searchSchema.query.bool.must,
      {
        term: {
          listingRefs: query.listingRef || _.get(query, "listing.id")
        }
      }
    ];
  }

  if (query.tags) {
    const tags = _.split(query.tags, ",");
    _.each(tags, tag => {
      searchSchema.query.bool.must.push({
        term: {
          "tags.keyword": tag
        }
      });
    });
  }
  if (query.location) {
    const coords = _.get(query, "location.coords");
    searchSchema.sort.splice(0, 0, {
      _geo_distance: {
        "__meta_search.latLng": {
          lat: _.get(coords, "lat"),
          lon: _.get(coords, "lon")
        },
        order: "asc"
      }
    });
    searchSchema._source.includes.push("__meta_search");
    searchSchema.query.bool.must = searchSchema.query.bool.must = [
      ...searchSchema.query.bool.must,
      {
        exists: {
          field: "__meta_search.latLng"
        }
      }
    ];
    if (
      _.get(query, "location.range") &&
      _.get(query, "location.range") !== "allkm"
    ) {
      const range = _.get(query, "location.range");
      searchSchema.query.bool.filter = searchSchema.query.bool.filter = [
        {
          ...searchSchema.query.bool.filter,
          geo_distance: {
            distance: range,
            "__meta_search.latLng": {
              lat: _.get(coords, "lat"),
              lon: _.get(coords, "lon")
            },
            validation_method: "IGNORE_MALFORMED"
          }
        }
      ];
    }
  }
  
  return executeElasticSearch({
    index: indexes.portalIndex,
    body: searchSchema
  }).then(result => {
    return result;
  });
}

export function getPortalByID(portalID, townRef = "hatyai") {
  return executeElasticSearch({
    index: indexes.portalIndex,
    body: {
      query: {
        terms: {
          _id: [portalID]
        }
      }
    }
  }).then(result => {
    return _.head(result);
  });

  // return firebase
  //   .firestore()
  //   .collection('portals')
  //   .doc(portalID)
  //   .get()
  //   .then(doc => {
  //     if (doc.exists) {
  //       return { id: doc.id, ...doc.data() };
  //     }
  //     return null;
  //   })
  //   .catch(error => {
  //     throw error;
  //   });
}

export function getListingByID(listingID) {
  const searchSchema = {
    sort: [{ modifiedDate: "desc" }],
    _source: {
      includes: [
        "title.*",
        "modifiedDate",
        "mainImageId",
        "shortDecsription.*",
        "highlightSentence.*",
        "tags",
        "portals",
        "provider"
      ]
    },
    query: {
      bool: {
        must: [{ term: { _id: listingID } }]
      }
    }
  };

  return executeElasticSearch({
    index: indexes.listingIndex,
    body: searchSchema
  }).then(result => _.head(result));
}

export function getListingList(
  query = {},
  options = {},
  sort = [{ score: "desc" }, { modifiedDate: "desc" }]
) {
  const searchSchema = {
    sort,
    _source: {
      includes: [
        'title.*',
        'modifiedDate',
        'mainImageId',
        'shortDecsription.*',
        'highlightSentence.*',
        'tags',
        'portals',
        'provider',
        'appearSeq',
      ],
    },
    query: {
      bool: {
        must: []
      }
    }
  };

  if (query.town) {
    searchSchema.query.bool.must = searchSchema.query.bool.must = [
      ...searchSchema.query.bool.must,
      {
        term: {
          "town.keyword": query.town
        }
      }
    ];
  }

  searchSchema.size = _.get(options, "limit") ? _.get(options, "limit") : 999;

  return executeElasticSearch({
    index: indexes.listingIndex,
    body: searchSchema
  });
}

export function searchSuggestion(
  {
    index = `${indexes.portalIndex},${indexes.townIndex}`,
    size = 6,
    queries = [],
    location = {}
  } = {},
  searchText
) {
  logger.debug(queries);
  const searchSchema = {
    size,
    sort: [
      {
        modifiedDate: {
          order: "desc",
          unmapped_type: "keyword"
        }
      }
    ],
    _source: {
      includes: [
        "title.*",
        "modifiedDate",
        "mainImageId",
        "shortDecsription.*",
        "highlightSentence.*",
        "tags",
        "portals",
        "provider"
      ]
    },
    query: {
      bool: {
        must: [
          ...queries,
          {
            bool: {
              should: [
                ...injectSmartSearchLangText({ field: "title", searchText }),
                {
                  wildcard: { "tags.keyword": `*${searchText}*` }
                }
              ]
            }
          }
        ]
      }
    }
  };

  if (!_.isEmpty(location)) {
    searchSchema._source.includes.push("__meta_search");
    searchSchema.query.bool.must = searchSchema.query.bool.must = [
      ...searchSchema.query.bool.must,
      {
        exists: {
          field: "__meta_search"
        }
      }
    ];
  }

  return executeElasticSearch({
    index,
    body: searchSchema
  }).then(result => {
    return result;
  });
}

export function getTownByID(townID) {
  const searchSchema = {
    sort: [{ modifiedDate: "desc" }],
    query: {
      bool: {
        must: [{ term: { _id: townID } }]
      }
    }
  };

  return executeElasticSearch({
    index: indexes.townIndex,
    body: searchSchema
  }).then(result => _.head(result));
}

export function getTownList() {
  const searchSchema = {
    size: 12,
    sort: [
      {
        score: { order: "desc" },
        modifiedDate: {
          order: "desc",
          unmapped_type: "keyword"
        }
      }
    ],
    query: {
      bool: {
        should: {
          exists: { field: "mainImageId" }
        }
      }
    }
  };

  return executeElasticSearch({ index: indexes.townIndex, body: searchSchema });
}

// export function getPortalListNearBy(
//   query = {},
//   { limit = 10, startAt = 0 },
//   coords = {},
//   range = "10km"
// ) {
//   const searchSchema = {
//     from: startAt,
//     size: limit,
//     query: {
//       bool: {
//         filter: {
//           geo_distance: {
//             distance: range,
//             __meta_search: {
//               lat: _.get(coords, "lat"),
//               lon: _.get(coords, "lon")
//             },
//             validation_method: "IGNORE_MALFORMED"
//           }
//         }
//       }
//     },
//     _source: {
//       includes: [
//         "title.*",
//         "modifiedDate",
//         "mainImageId",
//         "shortDecsription.*",
//         "highlightSentence.*",
//         "tags",
//         "portals",
//         "provider",
//         "__meta_search"
//       ]
//     },
//     sort: {
//       _geo_distance: {
//         __meta_search: {
//           lat: _.get(coords, "lat"),
//           lon: _.get(coords, "lon")
//         },
//         order: "asc"
//       }
//     }
//   };

//   return executeElasticSearch({
//     index: indexes.portalIndex,
//     body: searchSchema
//   });
// }

// export function getPortalListNearByWithOutRange(
//   query = {},
//   { limit = 10, startAt = 0 },
//   coords = {}
// ) {
//   const searchSchema = {
//     from: startAt,
//     size: limit,
//     query: {
//       bool: {
//         must: {
//           exists: {
//             field: "__meta_search"
//           }
//         }
//       }
//     },
//     _source: {
//       includes: [
//         "title.*",
//         "modifiedDate",
//         "mainImageId",
//         "shortDecsription.*",
//         "highlightSentence.*",
//         "tags",
//         "portals",
//         "provider",
//         "__meta_search"
//       ]
//     },
//     sort: {
//       _geo_distance: {
//         __meta_search: {
//           lat: _.get(coords, "lat"),
//           lon: _.get(coords, "lon")
//         },
//         order: "asc"
//       }
//     }
//   };

//   return executeElasticSearch({
//     index: indexes.portalIndex,
//     body: searchSchema
//   });
// }
