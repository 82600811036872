import React from "react";
import _ from "lodash";
import styled from "styled-components";

import BoxWithColorGray from "../../views/elements/BoxWithColorGray";
import { Trans } from "react-i18next";

export default function PortalLocationAddress({ streetAddress, locationAdress }) {
  const [currentLocation, setCurrentLocation] = React.useState();

  React.useEffect(() => {
    getMap().then(location => setCurrentLocation(location))
  }, []);

  if (_.isEmpty(streetAddress)) {
    return (
      <BoxWithColorGray style={{ marginBottom: 8 }}>
        <div>
          <Location className="title is-4">
            <Trans i18nKey="app.location.label" defaults="Location" />
            {locationAdress && <button
              className="button is-info"
              onClick={e => {
                e.stopPropagation();
                window.open(
                  `https://www.google.com/maps/dir/${currentLocation.latitude},${currentLocation.longitude}/${locationAdress.latitude},${locationAdress.longitude}`,
                  "_blank"
                );
              }}
            >
              <i class="fas fa-route"></i>&nbsp;<Trans i18nKey="app.navigation.label" defaults="Navigate" />
            </button>}
          </Location>
          <ContentAddress>
            <Trans
              i18nKey="actions.noInformation.label"
              defaults="No Information"
            />
          </ContentAddress>
        </div>
      </BoxWithColorGray>
    );
  }

  return (
    <BoxWithColorGray>
      <div>
        <Location className="title is-4">
          <Trans i18nKey="app.location.label" defaults="Location" />
          {locationAdress && <button
            className="button is-info"
            onClick={e => {
              e.stopPropagation();
              window.open(
                `https://www.google.com/maps/dir/${currentLocation.latitude},${currentLocation.longitude}/${locationAdress.latitude},${locationAdress.longitude}`,
                "_blank"
              );
            }}
          >
            <i class="fas fa-route"></i>&nbsp;<Trans i18nKey="app.navigation.label" defaults="Navigate" />
          </button> }
        </Location>
        <ContentAddress>{streetAddress}</ContentAddress>
      </div>
    </BoxWithColorGray>
  );
}

const ContentAddress = styled.span`
  font-family: "CSPrajad";
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


const getMap = async () => {
  const location =  await getLocation();
  return _.get(location, "coords")
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          resolve(location)
        },
        error => {
          reject(error)
        },
      )
    } else {
      reject(new Error('Geolocation is not supported by this browser.'))
    }
  })
}