import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styled from 'styled-components'
import { getTownByID, getListingList } from '../services/elasticService'
import PortalListListingGroup from '../templates/portal-list/PortalListListingGroup'
import TownImageHeaderWithSearchBar from '../templates/town/TownImageHeaderWithSearchBar'
import SliderWithLayout from '../views/widgets/SliderWithLayout'
import ListingListCard from '../templates/listing-list/ListingListCard'
import Loading from '../views/elements/Loading'
import usePromise from 'react-use-promise'
import { tranformToImageURL } from '../services/remoteUtils'
import { translate } from '../services/lang'
import Meta from '../views/widgets/Meta'
import { getImageFile } from '../services/remoteImageService'
import config from '../config'
import { useModal } from 'react-modal-hook'

export default function TownPage({ match }) {
  const [townID, setTownID] = React.useState(_.get(match, 'params.townID'))
  React.useEffect(() => {
    setTownID(_.get(match, 'params.townID'))
  }, [])
  return (
    <div>
      <TownQueryWithSearchBar townID={townID} />
      <div className="container">
        <div className="section">
          <TownListingList townID={townID} />
        </div>
      </div>
    </div>
  )
}

function TownQueryWithSearchBar({ townID }) {
  const {
    i18n: { language },
  } = useTranslation()
  const [_town, , state] = usePromise(() => getTownByID(townID), [])
  const isLoading = state === 'pending'

  if (isLoading) {
    return <Loading />
  }
  const town = tranformToImageURL(translate(_town, language), 'images')

  return (
    <TownImageHeaderWithSearchBar
      title={town.title}
      imageUrl={town.imageURL}
      townID={townID}
    />
  )
}

function TownListingList({ townID }) {
  const [imageFile, setImageFile] = React.useState({})
  const {
    i18n: { language },
  } = useTranslation()
  const [_listings, , state] = usePromise(
    () =>
      getListingList(
        {
          town: townID,
        },
        undefined,
        [{ appearSeq: 'asc' }, { modifiedDate: 'desc' }],
      ),
    [],
  )
  const [_town] = usePromise(
    () =>
      getTownByID(townID).then(async town => {
        await getImageFile(town.profileImageId).then(imageFile => {
          setImageFile(imageFile)
        })
        return town
      }),
    [],
  )
  const town = tranformToImageURL(translate(_town, language), 'images')
  const townProfileImageId = tranformToImageURL(
    translate(_town, language),
    'images',
    undefined,
    'profileImageId',
  )

  const [showModal, hideModal] = useModal(() => {
    _.delay(() => {
      hideModal()
    }, 2000)
    return (
      <>
        <div className="modal is-active is-hidden-mobile">
          <div className="modal-background" />
          <ModalContent className="modal-content is-flex">
            <ModalFigure className="image">
              <img
                style={{ borderRadius: '10%' }}
                src={`${townProfileImageId.imageURL}`}
                alt={imageFile.name}
              />
            </ModalFigure>
          </ModalContent>
        </div>
        <div className="modal is-active is-hidden-tablet">
          <div
            className="modal-background"
            style={{ backgroundColor: 'white' }}
          />
          <ModalContent className="modal-content is-flex">
            <div className="image is-128x128 animated">
              <img
                style={{ borderRadius: '10%' }}
                src={`${townProfileImageId.imageURL}`}
                alt={imageFile.name}
              />
            </div>
          </ModalContent>
        </div>
      </>
    )
  }, [townProfileImageId, imageFile])

  React.useEffect(() => {
    if (_.get(town, 'isShowDialog')) {
      showModal()
    }
  }, [_.get(town, 'isShowDialog')])

  if (_.isEmpty(_town)) return null
  const isLoading = state === 'pending'

  if (isLoading) {
    return <Loading />
  } else {
    const listings = tranformToImageURL(
      translate(_listings, language),
      'images',
    )
    return (
      <>
        <Meta
          title={_.get(town, `title`)}
          imageURL={town.imageURL}
          url={window.location.href}
        />
        <SliderWithLayout
          settings={{ slidesToShow: 5 }}
          mSettings={{
            slidesToShow: 3,
            arrows: true,
          }}
        >
          {_.map(listings, listing => (
            <ListingListCard listing={listing} key={listing.id} size="16by9" />
          ))}
        </SliderWithLayout>
        {_.map(listings, listing => {
          return <PortalListListingGroup listing={listing} key={listing.id} />
        })}
      </>
    )
  }
}

const ModalContent = styled.div`
  justify-content: center;
  align-items: center;
`

const ModalFigure = styled.figure`
  width: 300px;
  height: 300px;
`
