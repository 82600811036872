import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Link, Redirect } from 'react-router-dom'

import Meta from '../../views/widgets/Meta'
import PortalLayout from '../../templates/portal/PortalLayout'
import PortalContext from '../../contexts/PortalContext'
import { translate } from '../../services/lang'
import { useTranslation, Trans } from 'react-i18next'
import EditorWithAuthHandler from '../../templates/editor/EditorWithAuthHandler'
import { tranformToImageURL } from '../../services/remoteUtils'
import { getParams } from '../../modules/utils'

export default function TemplateEditingPage({ match, location }) {
  const {
    i18n: { language },
  } = useTranslation()
  const { portal, loadPortalByID } = React.useContext(PortalContext)
  const [isRedirect, setIsRedirect] = React.useState(false)
  const portalID = _.get(match, 'params.id')
  const provider = _.get(getParams(location.search), 'provider')

  React.useEffect(() => {
    loadPortalByID(portalID, provider).then(result => {
      if (_.isEmpty(result)) {
        setIsRedirect(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalID])

  if (isRedirect) {
    return <Redirect to="/" />
  }

  const _portal = tranformToImageURL(translate(portal, language))

  return (
    <TemplateEditingPageStyle>
      {_portal && (
        <Meta
          title={_.get(_portal, `title`)}
          description={_.get(_portal, `highlightSentence`)}
          imageURL={_.get(_portal, `imageURL`)}
          url={window.location.href}
        />
      )}
      <PortalLayout portal={_portal} />
      <EditorWithAuthHandler editorComponent={BackButton} />
    </TemplateEditingPageStyle>
  )
}

function BackButton() {
  return (
    <Link
      id="__back-button"
      className="button is-outlined __has-navbar"
      to="/creator"
    >
      <Trans i18nKey="actions.back.label" default="Back" />
    </Link>
  )
}

const TemplateEditingPageStyle = styled.div.attrs({
  className: '__template-edit-page',
})`
  display: flex;

  #__back-button {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 20;
    font-weight: bold;
    color: white;
    background: #ffffff50;
  }
`
