import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import usePromise from 'react-use-promise'
import { useTranslation } from 'react-i18next'

import { getPortalList } from '../../services/elasticService'
import PortalListCard from '../portal-list/PortalListCard'
import CardWithPlaceholder from '../../views/elements/CardWithPlaceholder'
import { portalListTagGroupMediaResponsive } from '../../views/utils'
import { tranformToImageURL } from '../../services/remoteUtils'
import { translate } from '../../services/lang'

export default function PortalListListingGroup({ listing }) {
  const title = listing.title
  const {
    i18n: { language },
  } = useTranslation()
  const [_portals, , state] = usePromise(
    () => getPortalList({ listingRef: listing._id }, { limit: 4 }),
    [],
  )

  const isLoading = state === 'pending'

  const portals = tranformToImageURL(translate(_portals, language))

  if (!isLoading && _.isEmpty(_portals)) {
    return null
  }

  return (
    <>
      <PortalListTagGroupLayout>
        <div className="title">{title}</div>
        {isLoading ? (
          <PortalListLayout>
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
          </PortalListLayout>
        ) : (
          <PortalList portals={portals} />
        )}
        <br />
        <Link
          className="button is-white is-hidden-mobile subtitle is-5"
          to={`/listings/${listing._id}`}
        >
          <span>แสดงทั้งหมด</span>
          <span className="icon is-small has-text-grey">
            <i className="fas fa-chevron-right" />
          </span>
        </Link>
        <Link
          className="button is-outlined is-fullwidth is-hidden-tablet"
          to={`/listings/${listing._id}`}
        >
          แสดงทั้งหมด
        </Link>
      </PortalListTagGroupLayout>
      <hr />
    </>
  )
}

function PortalList({ portals }) {
  return (
    <PortalListLayout>
      {_.map(portals, portal => {
        return (
          <PortalListCard
            key={_.get(portal, '_id')}
            portal={portal}
            cardComponent={CustomCardWithLink}
          />
        )
      })}
    </PortalListLayout>
  )
}

const CustomCardWithLink = styled(Link).attrs({ className: 'card' })`
  ${portalListTagGroupMediaResponsive}
`

const PortalListLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PortalListTagGroupLayout = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`
