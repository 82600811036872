import React from "react";
import { useModal } from "react-modal-hook";
import styled from "styled-components";
import classNames from "classnames";

import { EditorActionModal } from "../templates/editor-modal/EditorActionBox";
import { EditorCoverInfoModal } from "../templates/editor-modal/EditorCoverInfoBox";
import { EditorHeaderInfoModal } from "../templates/editor-modal/EditorHeaderInfoBox";
import { useTranslation } from "react-i18next";
import { EditorContactModal } from "../templates/editor-modal/EditorContactBox";
import { EditorOpeningHoursModal } from "../templates/editor-modal/EditorOpeningHoursBox";
import { EditorGalleryImageModal } from "../templates/editor-modal/EditorGalleryImageBox";
import { EditorLocationModal } from "../templates/editor-modal/EditorLocationBox";
import { EditorExternalLinkModal } from "../templates/editor-modal/EditorExternalLinkBox";
import { EditorTagModal } from "../templates/editor-modal/EditorTagBox";

import LanguageSelector from "../modules/lang/LanguageSelector";

export default function useConfirmModal() {
  const MODALS = {
    EDITOR_ACTION_MODAL: EditorActionModal,
    EDITOR_CONTACT_MODAL: EditorContactModal,
    EDITOR_COVER_INFO_MODAL: EditorCoverInfoModal,
    EDITOR_GALLERY_IMAGE_MODAL: EditorGalleryImageModal,
    EDITOR_HEADER_INFO_MODAL: EditorHeaderInfoModal,
    EDITOR_LOCATION_MODAL: EditorLocationModal,
    EDITOR_OPENING_HOURS_MODAL: EditorOpeningHoursModal,
    EDITOR_EXTERNAL_LINK_MODAL: EditorExternalLinkModal,
    EDITOR_TAG_MODAL: EditorTagModal
  };
  const [modalRef, setModalRef] = React.useState(null);
  const [params, setParams] = React.useState({});
  const [options, setOptions] = React.useState({});
  const [showModal, hideModal] = useModal(
    () => (
      <ModalConfirmStyle modalStyle={options.modalStyle}>
        <div className="modal-background" />
        <ConfirmModal
          params={params}
          component={MODALS[modalRef]}
          onRequestClose={result => {
            hideModal();
          }}
        />
      </ModalConfirmStyle>
    ),
    [params, modalRef]
  );

  function handleShowModal({ params = {}, modalRef, options = {} }) {
    setModalRef(modalRef);
    setParams(params);
    setOptions(options);
    showModal();
  }

  return {
    showModal: handleShowModal
  };
}

const ModalConfirmStyle = styled.div.attrs({
  className: "modal is-active __modal-confirm"
})`
  ${props => props.modalStyle}
`;

export function ConfirmModal({
  params = {},
  component: ModalBody,
  onRequestClose,
  children
}) {
  return (
    <ModalStyle className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{params.title}</p>
        <LanguageSelector />
        <button
          className="delete"
          aria-label="close"
          onClick={onRequestClose}
        />
      </header>
      {children}
      {ModalBody && <ModalBody params={params} onClose={onRequestClose} />}
    </ModalStyle>
  );
}

const ModalStyle = styled.div`
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    .modal-card-head {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-foot {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

export function FooterConfirmModal({
  onSubmit,
  onDelete,
  onClose,
  isLoading = false
}) {
  const { t } = useTranslation();

  return (
    <footer className="modal-card-foot">
      <button
        className={classNames("button is-success", { "is-loading": isLoading })}
        onClick={onSubmit}
      >
        {t("actions.save.label", { defaultValue: "Save" })}
      </button>
      {onDelete && (
        <button className="button is-danger" onClick={onDelete}>
          {t("actions.delete.label", { defaultValue: "Delete" })}
        </button>
      )}
      <button className="button" onClick={onClose}>
        {t("actions.cancel.label", { defaultValue: "Cancel" })}
      </button>
    </footer>
  );
}

export function FooterFilterModal({ onFilterApply, onFilterReset, onClose }) {
  const { t } = useTranslation();

  return (
    <footer className="modal-card-foot" style={{ justifyContent: 'space-between'}}>
      <button className="button" onClick={() => (onFilterReset("filterAll"), onClose())}>
        {t("actions.reset.label", { defaultValue: "Reset" })}
      </button>

      <button
        className="button is-success"
        onClick={() => (onFilterApply("filterAll"), onClose())}
      >
        {t("actions.accept.label", { defaultValue: "Accept" })}
      </button>
    </footer>
  );
}
