import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import usePromise from "react-use-promise";

import {
  getPortalListNearBy,
  getPortalListNearByWithOutRange,
  getPortalList
} from "../../services/elasticService";
import PortalListCard from "../portal-list/PortalListCard";
import CardWithPlaceholder from "../../views/elements/CardWithPlaceholder";
import { portalListTagGroupMediaResponsive } from "../../views/utils";
import { translate } from "../../services/lang";
import { tranformToImageURL } from "../../services/remoteUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const LOCATION_STATUS = {
  PENDING: "PENDING",
  COMPLATED: "COMPALTED",
  ERROR: "ERROR"
};

export default function PortalListNearBy() {
  const {
    i18n: { language }
  } = useTranslation();
  const [_portals, setPortal] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [coords, setCoords] = React.useState({});

  React.useEffect(() => {
    setPortal({});
    fetchPortalListNearBy().then(result => {
      setLoading(true);
      setPortal(result);
    });
    setLoading(false);
  }, []);

  const portals = tranformToImageURL(translate(_portals, language), "images");

  function fetchPortalListNearBy() {
    return getCurrentLocation().then(coords => {
      setCoords(coords);
      return getPortalList({ location: { coords: coords } }, { limit: 4 });
    });
  }

  if (_.isEmpty(portals)) {
    return null;
  }
  return (
    <>
      <PortalListTagGroupLayout>
        <div className="title">สถานที่ใกล้เคียง</div>
        {!loading ? (
          <PortalListLayout>
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
            <CardWithPlaceholder
              mediaResponsive={portalListTagGroupMediaResponsive}
            />
          </PortalListLayout>
        ) : (
          <PortalList portals={portals} coords={coords} />
        )}
        <br />
        <Link
          className="button is-white is-hidden-mobile subtitle is-5"
          to={`/portals?range=all`}
        >
          <span>แสดงทั้งหมด</span>
          <span className="icon is-small has-text-grey">
            <i className="fas fa-chevron-right" />
          </span>
        </Link>
        <Link
          className="button is-outlined is-fullwidth is-hidden-tablet"
          to={`/portals?range=all`}
        >
          แสดงทั้งหมด
        </Link>
      </PortalListTagGroupLayout>
    </>
  );
}

function PortalList({ portals, coords }) {
  return (
    <PortalListLayout>
      {_.map(portals, portal => {
        return (
          <PortalListCard
            key={_.get(portal, "_id")}
            portal={portal}
            coords={coords}
            cardComponent={CustomCardWithLink}
          />
        );
      })}
    </PortalListLayout>
  );
}

async function getCurrentLocation() {
  try {
    const location = await getLocation();
    const latLng = getLatLng(location);
    return { lat: latLng.latitude, lon: latLng.longitude };
  } catch (error) {
    toast.error(LOCATION_STATUS.ERROR);
  }
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          resolve(location);
        },
        error => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

const getLatLng = location => {
  return _.get(location, "coords");
};

const CustomCardWithLink = styled(Link).attrs({ className: "card" })`
  ${portalListTagGroupMediaResponsive}
`;

const PortalListLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PortalListTagGroupLayout = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

