export function getDefaultLangText() {
  return {
    th: '',
    en: '',
    zh: '',
    lang: true,
  }
}

export const langOptions = [
  { text: 'ภาษาไทย', value: 'th' },
  { text: 'English', value: 'en' },
  { text: '汉语', value: 'zh' },
]
