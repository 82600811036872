import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { providerGenerateQRcode } from '../../services/remoteService'
import CopyToClipboard from '../../views/widgets/CopyToClipboard'

export default function QRCodeSection({ params }) {
  const [imageURL, setImageURL] = React.useState(null)

  React.useEffect(() => {
    providerGenerateQRcode(
      params.provider,
      params.text,
      params.imageID,
      'imageStore',
    )
      .then(imageURL => {
        setImageURL(imageURL)
      })
      .catch(error => {
        console.error(error.message)
      })
  })

  return (
    <QRCodeSectionStyle>
      <figure className="image">
        {!imageURL ? 'loading...' : <img src={imageURL} alt={params.text} />}
      </figure>
      <CopyToClipboard text={params.text} />
    </QRCodeSectionStyle>
  )
}

QRCodeSection.propTypes = {
  params: PropTypes.object.isRequired,
}

const QRCodeSectionStyle = styled.div.attrs({ className: '__qrcode-section' })`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .image {
    max-width: 256px;
    max-height: 256px;
  }

  .input {
    border: 1px solid;
  }

  .button {
    border: 1px solid;
  }
`
