import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function ListingListCard({
  listing,
  size = '1by1',
}) {
  return (
    <ListingListLayout to={`/listings/${listing._id}`}>
      <div className="card">
        <div className="card-image">
          <figure className={`image is-${size}`}>
            <img src={listing.imageURL} alt={listing.title} />
          </figure>
          <BackgroundOverlay />
        </div>
        <div className="card-listing-layout">
          <div className="card-listing-content has-text-centered title is-5">
            {listing.title}
          </div>
        </div>
      </div>
    </ListingListLayout>
  );
}

const BackgroundOverlay = styled.div`
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: 0;
  top: 0;
`;

const ListingListLayout = styled(Link)`
  .card {
    margin: 6px;
    margin-bottom: 16px;
  }

  img {
    border-radius: 8px;
    object-fit: cover;
  }

  .card-listing-layout {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-listing-content {
    padding: 16px;
  }

  .card-listing-content,
  .title {
    color: white;
    text-shadow: 0px 2px 15px #0a0a0a;
  }
`;
