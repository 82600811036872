import React from 'react'
import styled from 'styled-components'

const DivControl = styled.div`
  margin: 0.5em;
`

const SelectItem = ({ name, value, onChange, options }) => {
  return (
    <DivControl className="control __select-item">
      <div className="select">
        <select name={name} value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </DivControl>
  )
}

export default SelectItem
