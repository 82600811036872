import React from 'react'
import { ModalProvider } from 'react-modal-hook'

import { AuthContextProvider } from './contexts/AuthContext'
import AppRouter from './AppRouter'
import { FormDataContextProvider } from './contexts/FormDataContext'
import { ConfirmModalContextProvider } from './contexts/ConfirmModalContext'
import { PortalContextProvider } from './contexts/PortalContext'
import { InfoContextProvider } from './contexts/InfoContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NetworkDetector from './hoc/NetworkDetector'
function App() {
  return (
    <InfoContextProvider>
      <AuthContextProvider>
        <PortalContextProvider>
          <ModalProvider>
            <ConfirmModalContextProvider>
              <FormDataContextProvider>
                <AppRouter />
              </FormDataContextProvider>
            </ConfirmModalContextProvider>
            <ToastContainer />
          </ModalProvider>
        </PortalContextProvider>
      </AuthContextProvider>
    </InfoContextProvider>
  )
}

export default NetworkDetector(App)
