import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { getPortalList } from "../services/elasticService";
import CardWithPlaceholder from "../views/elements/CardWithPlaceholder";
import Meta from "../views/widgets/Meta";
import PortalListCard from "../templates/portal-list/PortalListCard";
import PortalSearchPanel from "../templates/portal-search/PortalSearchPanel";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import logger from "../services/logger";
import { tranformToImageURL } from "../services/remoteUtils";
import { translate } from "../services/lang";
import { getParams } from "../modules/utils";
import { toast } from "react-toastify";

export const LOCATION_STATUS = {
  PENDING: "PENDING",
  COMPLATED: "COMPALTED",
  ERROR: "ERROR"
};

export default function PortalListPage({ match, location, ...props }) {
  const {
    i18n: { language }
  } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [coords, setCoords] = React.useState();
  const [portals, setPortals] = React.useState([]);
  const [startAt, setStartAt] = React.useState(0);
  const [isFetching, setIsFetching, isMore, setIsMore] = useInfiniteScroll(
    fethMore
  );

  const query = getParams(location.search) || {};
  React.useEffect(() => {
    setIsMore(true);
    setIsFetching(true);
    setPage(0);
    setStartAt(0);
    setPortals([]);
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.tags,
    query.title,
    query.range,
    _.get(query, "range"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    _.get(query, "listing.id"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    _.get(query, "town.id")
  ]);

  function fethMore() {
    logger.debug("fetch more...");
    const limit = page === 0 ? 11 : 8;
    prepareQuery(query).then(result => {
      const _query = result;
      setCoords(_.get(_query, 'location.coords'))
      getPortalList(_query, { startAt, limit }).then(_portals => {
        if (!_.isEmpty(_portals)) {
          const portalMerged = [...portals, ..._portals];
          setPage(page + 1);
          setPortals(tranformToImageURL(translate(portalMerged, language)));
          setStartAt(_.size(portalMerged));
        } else {
          setIsMore(false);
        }
        setIsFetching(false);
      });
    });
  }

  let renderPlaceholder;

  if (isFetching && isMore) {
    renderPlaceholder = _.isEmpty(portals) ? (
      <>
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
        <CardWithPlaceholder />
      </>
    ) : (
      <CardWithPlaceholder />
    );
  }

  return (
    <div className="container" style={{ marginTop: "3.25rem" }}>
      <Meta
        title={"Glog"}
        imageURL={`/__images/logo.png`}
        url={window.location.href}
      />
      <div className="is-centered">
        <PortalSearchPanel query={query} coords={coords}/>
        <ListingLayout>
          {_.map(portals, portal => (
            <React.Fragment key={portal._id}>
              <PortalListCard portal={portal} coords={coords} />
              <br />
            </React.Fragment>
          ))}

          {renderPlaceholder}
        </ListingLayout>
      </div>
    </div>
  );
}

const ListingLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

async function prepareQuery(query) {
  if (_.get(query, "range")) {
    return getCurrentLocation().then(coords => {
      return {
        ..._.omit(query, "range"),
        location: { range: `${_.get(query, "range")}km`, coords: coords }
      };
    });
  } else {
    return query;
  }
}

async function getCurrentLocation() {
  try {
    const location = await getLocation();
    const latLng = getLatLng(location);
    return { lat: latLng.latitude, lon: latLng.longitude };
  } catch (error) {
    toast.error(LOCATION_STATUS.ERROR);
  }
}

const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        location => {
          resolve(location);
        },
        error => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

const getLatLng = location => {
  return _.get(location, "coords");
};
