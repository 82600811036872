import styled from 'styled-components'
const ActionPanelLayout = styled.div.attrs({
  className: '__action-panel-layout',
})`
  .__is-center {
    justify-content: center !important;
  }

  .is-hidden-mobile {
    /* position: fixed; */
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    padding-right: 60px;
    padding-left: 60px;

    bottom: 16px;
    left: 0;
    .buttons {
      margin-right: 32px;
    }
  }

  .is-hidden-tablet {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 30;

    .button {
      /* border: 1px; */
      border-radius: 0px;
    }
  }
`

export default ActionPanelLayout
