import React from 'react'
import i18n from 'i18next'
import moment from 'moment'
import _ from 'lodash'

import SelectItem from '../../views/elements/SelectItem'
import config from '../../config'
import { langOptions } from '../../constants/utils'

export function changeLanguage(lang) {
  window.localStorage.setItem('language', lang)
  i18n.changeLanguage(lang)
  moment.locale(lang === 'zh' ? 'zh-cn' : lang)
}

function LanguageSelector({ availableLangs = [] }) {
  const [language, setLanguage] = React.useState(
    availableLangs[0] || window.localStorage.getItem('language'),
  )

  const options = langOptions

  React.useEffect(() => {
    const lang = window.localStorage.getItem('language')
    if (_.includes(availableLangs, lang)) {
      setLanguage(lang)
    }
  }, [availableLangs])

  function handleLangChange(e) {
    const lang = e.target.value
    changeLanguage(lang)
    setLanguage(lang)
  }

  return (
    <SelectItem
      name="language"
      onChange={handleLangChange}
      value={language}
      options={options}
    />
  )
}

LanguageSelector.defaultProps = {
  availableLangs: config.supportedLanguages || ['th', 'en'],
}

export default LanguageSelector
