import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import AuthContext from '../../contexts/AuthContext'
import LanguageSelector from '../../modules/lang/LanguageSelector'
import { Trans, useTranslation } from 'react-i18next'
import InfoContext from '../../contexts/InfoContext'
// import useHistoryListener from '../../hooks/useHistoryListener'

export default function Navbar() {
  const { t } = useTranslation()
  const [isMenuActive, setMenuActive] = React.useState(false)
  const [pathname, setPathname] = React.useState('')
  const { remoteWebConfig } = React.useContext(InfoContext)
  function toggleMenu() {
    setMenuActive(prevValue => !prevValue)
  }

  const _pathname = _.get(window, 'location.pathname')

  React.useEffect(() => {
    setPathname(_.get(window, 'location.pathname'))
  }, [_pathname])

  const isWizardPage = _.includes(pathname, 'wizard')

  if (_.includes(pathname, 'login')) {
    return null
  }

  return (
    <NavbarStyle className="navbar is-dark is-fixed-top">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/" replace>
          <span className="icon">
            <img
              src={remoteWebConfig.logoImageURL || '/__images/logo.png'}
              alt={remoteWebConfig.title || 'Glog'}
              width="28"
              height="28"
              style={{ marginLeft: 8, marginRight: 16 }}
            />
          </span>
          <span> {remoteWebConfig.title || 'Glog'}</span>
        </Link>

        <div
          className={classNames('navbar-burger burger', {
            'is-active': isMenuActive,
          })}
          onClick={toggleMenu}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>
      <div className={classNames('navbar-menu', { 'is-active': isMenuActive })}>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="control buttons">
              {!isWizardPage && <LanguageSelector />}
              <Link
                className="button"
                to={'/contact'}
                push
              >
                Contact Us
              </Link>
            </div>
          </div>
          <DropdownMenu />
        </div>
      </div>
    </NavbarStyle>
  )
}

const DropdownMenu = () => {
  const { user, isAuthenticated, logout } = React.useContext(AuthContext)
  const [isActive, setActive] = React.useState(false)

  function toggleDropdown() {
    setActive(prev => !prev)
  }

  if (!isAuthenticated) {
    return null
  }
  return (
    <div
      className={classNames('navbar-item has-dropdown', {
        'is-active': isActive,
      })}
      onClick={toggleDropdown}
    >
      <div className="navbar-link">
        {' '}
        <Trans i18nKey="app.hi.label" defaults="Hi" /> ! {user.displayName}
      </div>

      <div className="navbar-dropdown">
        <div className="navbar-item" onClick={logout}>
          <Trans i18nKey="actions.logout.label" defaults="Logout" />
        </div>
      </div>
    </div>
  )
}

const NavbarStyle = styled.nav.attrs({ className: '___navbar' })`
  background: rgba(10, 10, 10, 0.7) !important;

  .__select-item {
    margin-top: 0;
  }
`
