import React from 'react';

export default function Loading() {
  return (
    <section className="hero is-fullheight is-bold">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-4 has-text-centered has-text-grey">Loading...</h1>
          <div style={{ width: 'auto', margin: 'auto'}} className="has-text-centered">
            <progress className="progress is-small is-primary" max="100">
              15%
            </progress>
          </div>
        </div>
      </div>
    </section>
  );
}
