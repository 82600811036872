import traverse from 'traverse'
import _ from 'lodash'

var langs = ['en', 'th', 'zh']

export const translate = (obj, lang) => {
  lang = lang || langs[0]

  if(_.isEmpty(obj)){
    return {}
  }

  const _obj = traverse(_.cloneDeep(obj)).forEach(function(item) {
    if (_.isObject(item) && isInLangs(item)) {
      if (!_.isEmpty(item[lang])) {
        this.update(item[lang])
        return
      } else {
        //else select one lang
        this.update(item[_.find(langs, lang => item[lang])] || '')
      }
    }
  })

  return _obj
}

const isInLangs = object => {
  const keys = _.keys(object)
  return object.lang || _.some(langs, lang => _.includes(keys, lang))
}
