import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './templates/nav/Navbar'
import PrivateRoute from './modules/router/PrivateRoute'
import withTracker from './modules/google-analytics/withTracker'

// creator
import LoginPage from './pages/LoginPage'
import MainListPage from './pages/creator/MainListPage'
import WizardPage from './pages/creator/WizardPage'
import ChooseLangPage from './pages/creator/ChooseLangPage'
import TemplateEditingPage from './pages/creator/TemplateEditingPage'
import SignUpPage from './pages/creator/SignUpPage'

//website
import NotFoundPage from './pages/404'
import HomePage from './pages/HomePage'
import TownPage from './pages/TownPage'
import ListingListPage from './pages/ListingListPage'
import PortalListPage from './pages/PortalListPage'
import ContactPage from './pages/ContactPage'
import Footer from './templates/footer/Footer'
import AdminPage from './pages/AdminPage'
import PortalListNearByPage from './pages/PortalListNearByPage'

function AppRouter() {
  const routerRef = React.useRef({})

  return (
    <Router ref={routerRef}>
      <Navbar routerRef={routerRef} />
      <div className="__app-body">
        <Switch>
          {/* website */}
          <Route exact path="/" component={withTracker(HomePage)} />
          <Route
            exact
            path="/towns/:townID"
            component={withTracker(TownPage)}
          />
          <Route
            key="listing"
            exact
            path="/listings/:listingID"
            component={withTracker(ListingListPage)}
          />
          <Route
            exact
            path="/portals"
            component={withTracker(PortalListPage)}
          />
          <Route exact path="/contact" component={withTracker(ContactPage)} />
          <Route exact path="/login" component={LoginPage} />

          {/* creator */}
          <PrivateRoute path="/creator/wizard" component={WizardPage} />
          <PrivateRoute
            exact
            path="/creator/choose-lang"
            component={ChooseLangPage}
          />

          <Route exact path="/portals/:id" component={TemplateEditingPage} />
          <PrivateRoute exact path="/creator" component={MainListPage} />
          <Route exact path="/signUp" component={SignUpPage} />

          {/* <Route exact path="/admin" component={AdminPage} /> */}

          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}

export default AppRouter
