import React from 'react';
import classNames from 'classnames'
import _ from 'lodash'
const Button = ({ title, onClick, onBlur, type, isActive = false, filter }) => {
    const btnClass = classNames(
        'button',
        'is-rounded',
        'is-small',
        {'is-warning': !_.isEmpty(filter)},
        {'is-primary': isActive}
    )
    return (
        <div  >
            <a className={btnClass} onClick={()=> onClick(type)}  >
                { title }  {filter? (': '+filter):null }
            </a>
        </div>
)}

export default Button;

