import React from 'react';

const Modal = ({ type }) => (
    <div>
       Modal
    </div>
)

export default Modal;

