import React from 'react'
import useAuthHandler from '../hooks/useAuthHandler'

const AuthContext = React.createContext()

export const AuthContextProvider = ({ children }) => {
  const authHandler = useAuthHandler()
  return (
    <AuthContext.Provider value={authHandler}>{children}</AuthContext.Provider>
  )
}
export default AuthContext
