export const colorActionOptions = [
  {
    text: 'GhostWite',
    value: { background: '#F8F8FF', text: 'black' },
  },
  {
    text: 'Goldenrod',
    value: { background: '#FFC125', text: 'white' },
  },
  {
    text: 'LightSeaGreen',
    value: { background: '#20B2AA', text: 'white' },
  },
  {
    text: 'Red',
    value: { background: '#CD0000', text: 'white' },
  },
  {
    text: 'DodgerBlue',
    value: { background: '#1E90FF', text: 'white' },
  },
  {
    text: 'Black',
    value: { background: '#1C1C1C', text: 'white' },
  },
  {
    text: 'Gray',
    value: { background: '#CFCFCF', text: 'black' },
  },
  {
    text: 'Green',
    value: { background: '#00CC00', text: 'black' },
  },
]
