import React from "react";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import classNames from "classnames";

import useForm from "../../hooks/useForm";
import useImageCropEditModal from "../../modules/image-crop/useImageCropEditModal";
import { uploadPortalImage } from "../../services/remoteImageService";
import config from "../../config";
import ActionPanelLayout from "../../views/widgets/ActionPanelLayout";
import {
  cardMediaReponsive,
  cardLoadingPlaceholder,
  imageOverlay
} from "../../views/utils";
import FlashMessage from "../../views/widgets/FlashMessage";

export default function UploadThumbnailPage({
  initialValues,
  onPrev,
  onAfterValid
}) {
  const { t } = useTranslation();
  const { values, errors, handleSubmit, setFieldValue, resetErrors } = useForm({
    initialState: { galleryImages: [], ...initialValues },
    onValidate: ({ values }) => {
      const errors = {};

      if (_.isEmpty(values.mainImageId)) {
        errors.mainImageId = t("form.require.label", {
          title: t("field.mainImageId.label", {
            defaultValue: "Thumbnail Image"
          }),
          defaultValue: "Require {{title}} field"
        });
      }

      return errors;
    },
    onSubmit: onAfterValid,
    reinitialState: true
  });
  const [imageURL, setImageURL] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setImageURL(
      values.mainImageId
        ? config.imageURLPrefix + "/" + values.mainImageId
        : null
    );
  }, [values.mainImageId]);

  const { showModal } = useImageCropEditModal({
    onImageCrop: result => {
      const croppedImageFile = _.get(result, "croppedImageFile");
      if (croppedImageFile) {
        setIsLoading(true);
        uploadPortalImage(
          values._id,
          croppedImageFile,
          values.mainImageId || ""
        ).then(result => {
          setFieldValue("mainImageId", result.ref);
          setIsLoading(false);
        });
        setImageURL(URL.createObjectURL(croppedImageFile));
      }
    }
  });

  function handleUploadImage(e) {
    const file = _.get(e, "target.files[0]");
    if (file) {
      showModal(file);
    }
    e.target.value = ""; //Fixed Can't upload same file twice
  }
  return (
    <UploadThumbnailPageStyle className="has-text-centered">
      <h3 className="title is-3">
        <Trans
          i18nKey="actions.upload.label"
          values={{
            title: t("field.mainImageId.label", {
              defaultValue: "Thumbnail Image"
            })
          }}
          defaults="Upload {{title}}"
        />
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="__content-layout">
          <FlashMessage
            type="is-warning"
            isShow={!_.isEmpty(errors.mainImageId)}
            onClose={resetErrors}
          >
            {errors.mainImageId}
          </FlashMessage>
          <CardWithFileUploadFile
            imageURL={imageURL}
            onChange={handleUploadImage}
          />
        </div>
        <ActionPanelLayout>
          <div className="is-hidden-mobile">
            <div className="buttons">
              <div className="button is-default" onClick={onPrev}>
                <Trans i18nKey="actions.back.label" defaults="Back" />
              </div>
              <button
                className={classNames("button is-primary", {
                  "is-loading": isLoading
                })}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {t("actions.next.label", { defaultValue: "Next" })}
              </button>
            </div>
          </div>

          <div className="is-hidden-tablet">
            <div className="columns is-gapless is-mobile">
              <div className="column">
                <div
                  className="button is-medium is-default is-fullwidth"
                  onClick={onPrev}
                >
                  <Trans i18nKey="actions.back.label" defaults="Back" />
                </div>
              </div>
              <div className="column">
                <button
                  className={classNames(
                    "button is-medium is-primary is-fullwidth",
                    { "is-loading": isLoading }
                  )}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {t("actions.next.label", { defaultValue: "Next" })}
                </button>
              </div>
            </div>
          </div>
        </ActionPanelLayout>
      </form>
    </UploadThumbnailPageStyle>
  );
}

const UploadThumbnailPageStyle = styled.div.attrs({ className: "" })`
  .__content-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const CardWithFileUploadFile = ({ onChange, imageURL }) => (
  <CardWithPlaceholderStyle
    mediaResponsive={cardMediaReponsive}
    imageURL={imageURL}
  >
    <label className="card-image" htmlFor="__upload-photo">
      <div className="image is-square">
        <div className="__image-placeholder">
          <i className="fas fa-camera fa-3x is-large" />
          <input
            id="__upload-photo"
            name="file"
            type="file"
            accept="image/*"
            onChange={onChange}
          />
        </div>
      </div>
    </label>
    <div className="card-content">
      <div className="media">
        <div className="media-content">
          <div className="__tags-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__title-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__subtitle-placeholder">
            <div className="__not-animated-background" />
          </div>
          <div className="__subtitle-placeholder">
            <div className="__not-animated-background" />
          </div>
        </div>
      </div>
    </div>
  </CardWithPlaceholderStyle>
);

const CardWithPlaceholderStyle = styled.div.attrs({ className: "card" })`
  ${props => props.mediaResponsive}
  ${cardLoadingPlaceholder}
  width: 220px !important;

  label {
    cursor: pointer;
  }

  #__upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .__image-placeholder {
    ${imageOverlay}

    ::before {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    ::after {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
`;
