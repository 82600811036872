import React from 'react'
import _ from 'lodash'

export default function PortalContributor({ contributors }) {
  if (_.isEmpty(contributors)) {
    return null
  }
  return (
    <div style={{ paddingBottom: 36 }}>
      <div
        className="title is-4 has-text-centered"
        style={{ marginBottom: '0.5em' }}
      >
        CONTRIBUTORS
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{ border: '2px solid grey', width: 50, marginBottom: 16 }}
        />
      </div>
      <div
        className="has-text-centered"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {_.map(contributors, contributor => (
          <>
            <div>
              <figure className="image is-96x96">
                <img
                  key={contributor.mainImageId}
                  className="is-rounded"
                  src={contributor.smallImageURL}
                  alt={contributor.name}
                />
              </figure>
            </div>

            <br />
            <div className="title is-4">{contributor.name}</div>
            <div className="subtitle is-6">ผู้รวบรวมข้อมูล</div>
          </>
        ))}
      </div>
    </div>
  )
}
