import React from 'react';

export default function MapEmbed({ latitude, longitude }) {
  return (
    <div style={{ width: '100%', height: 350 }}>
      <iframe
        title="google-embed"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0, width: '100%', height: '100%' }}
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDMqFgvXGC3lPZ-1ZL4iBOTnomoIWMImn4&q=${latitude},${longitude}`}
        allowFullScreen
      />
    </div>
  );
}
