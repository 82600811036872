import React from 'react'
import { Redirect } from 'react-router-dom'

import _ from 'lodash'
import styled from 'styled-components'
import PortalContactMessengerFloatButton from '../templates/portal-contacts/PortalContactMessengerFloatButton'
import PortalContactPhoneFloatButton from '../templates/portal-contacts/PortalContactPhoneFloatButton'
import InfoContext from '../contexts/InfoContext'

export default function ContactPage() {
  const {
    remoteWebConfig: { logoImageURL, contacts = {}, isContactWithMe },
  } = React.useContext(InfoContext)

  if(!isContactWithMe) {
    window.location.href = 'https://glog.allly.co/creator'
    return null
  }

  return (
    <>
      <div
        className="container"
        style={{ marginTop: '4.8rem', marginBottom: '3.8rem' }}
      >
        <CustomCard className="card" style={{ width: 720, margin: '0 auto' }}>
          <div className="columns">
            <div className="column">
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {_.isEmpty(logoImageURL) && (
                  <figure className="image is-128x128">
                    <img
                      src={logoImageURL || '/__images/logo.png'}
                      alt="Placeholder"
                    />
                  </figure>
                )}

                <div className="title is-2">Contact Us</div>
              </div>
            </div>
          </div>
          <hr />
          <div className="columns">
            <div className="column">
              <ContactSection contacts={contacts} />
            </div>
            {contacts.email && (
              <div className="column">
                <hr style={{ margin: '16px 0' }} className="is-hidden-tablet" />
                <MailSection email={contacts.email} />
              </div>
            )}
          </div>
        </CustomCard>
      </div>
      {!_.isEmpty(contacts.fbPageId) && (
        <PortalContactMessengerFloatButton
          fbPageId={contacts.fbPageId || '2304818326503841'}
        />
      )}
      {!_.isEmpty(contacts.phone) && (
        <PortalContactPhoneFloatButton
          phoneContact={contacts.phone || '083-190-0250'}
          isHavePageID
        />
      )}
    </>
  )
}

function ContactSection({ contacts }) {
  return (
    <div className="card-content">
      <div className="title is-4" style={{ marginBottom: 0 }}>
        ติดต่อเจ้าหน้าที่
      </div>

      <div style={{ margin: '16px' }}>
        <ContactItem
          classContainer="is-hidden-tablet"
          classIcon="fab fa-line"
          linkName={contacts.lineId || 'alllyglog'}
          linkURL={
            contacts.lineId
              ? `http://line.me/ti/p/~${contacts.lineId}`
              : 'http://line.me/ti/g/66r5vdAzp5'
          }
        />
        <ContactItem
          classIcon="fas fa-phone"
          linkName={contacts.phone || '083-190-0250'}
          linkURL={`tel:${contacts.phone || '083-190-0250'}`}
          iconStyle={{ fontSize: '1.6em' }}
        />
      </div>
      {!_.isEmpty(contacts.googleFormLink) && (
        <>
          <div className="title is-4">สนใจลงข้อมูลกับเรา</div>
          <div style={{ margin: '16px' }}>
            <ContactItem
              classIcon="far fa-file-alt"
              linkName="กรอกข้อมูลผ่าน google form"
              linkURL={
                contacts.googleFormLink || 'https://forms.gle/3VWxD1VT8UxESEfn9'
              }
            />
          </div>
        </>
      )}
    </div>
  )
}

function ContactItem({
  classContainer = '',
  classIcon,
  linkURL,
  linkName,
  iconStyle = {},
}) {
  return (
    <div className={`columns is-mobile is-vcentered ${classContainer}`}>
      <div className="column is-2 has-text-right ">
        <i className={classIcon} style={{ fontSize: '2em', ...iconStyle }} />
      </div>
      <div className="column">
        <a
          href={window.decodeURIComponent(linkURL)}
          rel="noopener noreferrer"
          style={{ fontSize: '1.2em' }}
          target="_blank"
        >
          {linkName}
        </a>
      </div>
    </div>
  )
}

function MailSection({ email }) {
  const [topic, setTopic] = React.useState('')
  const [content, setContent] = React.useState('')

  function onTopicChange(e) {
    setTopic(e.target.value)
  }

  function onContentChange(e) {
    setContent(e.target.value)
  }

  return (
    <div className="card-content">
      <div className="field">
        <label className="label">ชื่อ</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="ชื่อ"
            onChange={onTopicChange}
            value={topic}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">เรื่องที่คุณอยากให้เราช่วยหรือแนะนำ</label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="เรื่องที่คุณอยากให้เราช่วยหรือแนะนำ"
            onChange={onContentChange}
            value={content}
          />
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control" style={{ margin: '0 auto' }}>
          <a
            className="button is-link"
            href={`mailto:${email ||
              'alllyglog@gmail.com'}?subject=${topic}&body=${content}`}
          >
            ส่งข้อความ
          </a>
        </div>
      </div>
      {/* <hr />
  <div className="title">
    OR{' '}
    <div
      className="line-it-button"
      data-lang="en"
      data-type="friend"
      data-lineid="@66r5vdAzp5"
      // data-count="true"
      // data-home="true"
      style={{ display: 'none' }}
    />
  </div> */}
    </div>
  )
}

const CustomCard = styled.div`
  box-shadow: 7px 3px 20px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0);
`
