import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

export default function PortalContactPhoneFloatButton({
  isHavePageID = false,
  phoneContact,
}) {
  
  const phoneNumber = _.chain(phoneContact)
    .split(',')
    .head()
    .value();

  if (!phoneNumber) {
    return null;
  }

  return (
    <PhoneFloatButtonLayout
      href={`tel:${phoneNumber}`}
      isHavePageID={isHavePageID}
    >
      <i className="fas fa-phone" />
    </PhoneFloatButtonLayout>
  );
}

const PhoneFloatButtonLayout = styled.a.attrs({
  className: 'phone-float-button-layout has-text-primary',
})`
  border-radius: 50%;
  bottom: 18pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  padding: 0px;
  position: fixed;
  right: 18pt;
  height: 45pt;
  width: 45pt;
  z-index: 2147483646;
  background: white;
  cursor: pointer;
  box-shadow: 0px 2px 10px #666;

  ${props =>
    props.isHavePageID &&
    css`
      z-index: 2;
      position: absolute;
      bottom: 81pt;
    `}

  animation: pop 0.3s linear 1;
  @keyframes pop {
    50% {
      transform: scale(0.8);
    }
  }
`;
