import React from 'react'
import _ from 'lodash'
import MapView from './MapView'

export default class MapComponent extends React.Component {
  constructor(props){
    super(props)
    this.handlePlaceChange = _.debounce(this.handlePlaceChange, 500)
  }
  
  state = {
    isMarkerShown: true,
    markers: this.props.markers,
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.markers, this.props.markers)) {
      this.setState({
        markers: nextProps.markers,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.center, prevProps.center) &&
      !_.isEmpty(this.props.center)
    ) {
      this.setState({ center: this.props.center })
    }
  }

  handleMarkerDragEnd = event => {
    this.handleMarkerChange(event)
  }

  handleMapClick = event => {
    const { lat, lng } = this.getLatLng(event)
    this.setState(
      {
        markers: [this.createMarker(lat, lng)],
      },
      () => this.handleMarkerChange(event),
    )
  }

  handleMarkerChange = event => {
    const latLng = this.getLatLng(event)

    this.setState({
      center: latLng,
    })

    if (this.props.onMarkerChange) {
      this.props.onMarkerChange(latLng)
    }
  }

  getLatLng = event => {
    const latLng = event.latLng
    let lat = latLng.lat()
    let lng = latLng.lng()
    return { lat, lng }
  }

  createMarker = (lat, lng) => {
    return {
      position: { lat, lng },
    }
  }

  handlePlaceChange = () => {
    const places = this.searchBoxRef.getPlaces()
    const bounds = new window.google.maps.LatLngBounds()

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    })
    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }))
    const nextCenter = _.get(nextMarkers, '0.position', this.state.center)

    this.setState({
      center: nextCenter,
      markers: nextMarkers,
    })

    if (this.props.onTextSearchChange) {
      this.props.onTextSearchChange(_.get(places, '0.formatted_address'))
    }
    if (this.props.onMarkerChange) {
      this.props.onMarkerChange({
        lat: nextCenter.lat(),
        lng: nextCenter.lng(),
      })
    }
    // refs.map.fitBounds(bounds);
  }

  handleSearchBoxMouted = ref => {
    this.searchBoxRef = ref
  }

  render() {
    return (
      <MapView
        {...this.props}
        center={this.state.center}
        markers={this.state.markers}
        isMarkerShown={this.state.isMarkerShown}
        onMarkerDragEnd={this.handleMarkerDragEnd}
        onMapClick={this.handleMapClick}
        bounds={this.state.bounds}
        onPlacesChange={this.handlePlaceChange}
        onSearchBoxMounted={this.handleSearchBoxMouted}
      />
    )
  }
}
