import { css } from 'styled-components'

export const portalListTagGroupMediaResponsive = css`
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin: 1%;
    width: 48%;
    margin-bottom: 16px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 1%;
    width: 23%;
  }

  @media only screen and (min-width: 1023px) {
    margin: 1%;
    width: 23%;
  }
`

export const cardMediaReponsive = css`
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 48%;
    margin: 1%;
    /* margin-bottom: 16px; */
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    margin: 1%;
    width: 31.25%;
  }

  @media only screen and (min-width: 1023px) {
    margin: 1%;
    width: 23%;
  }
`

export const cardLoadingPlaceholder = css`
  .__image-placeholder {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: #eeee;
  }

  .__tags-placeholder {
    width: 40%;
    height: 8px;
    margin-bottom: 8px;
  }

  .__title-placeholder {
    width: 100%;
    height: 16px;
    margin-bottom: 8px;
  }

  .__subtitle-placeholder {
    width: 100%;
    height: 10px;
    margin-bottom: 6px;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .__animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(
      to right,
      #eeeeee 10%,
      #dddddd 18%,
      #eeeeee 33%
    );
    background-size: 800px 104px;
    height: 100%;
    position: relative;
  }

  .__not-animated-background {
    animation-duration: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(
      to right,
      #eeeeee 10%,
      #dddddd 18%,
      #eeeeee 33%
    );
    background-size: 800px 104px;
    height: 100%;
    position: relative;
  }
`

export const imageOverlay = css`
  ${props =>
    props.imageURL
      ? css`
          display: flex;
          background-size: cover;
          justify-content: center;
          align-items: center;
          /* justify-content: flex-end;
            align-items: flex-end; */

          ::before {
            background-size: cover;
            background-image: ${props => `url(${props.imageURL})`};
            background-position: center;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }

          ::after {
            background-color: gray;
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0.4;
          }

          .fas {
            color: white;
            /* margin: 16px; */
            z-index: 3;
            opacity: 0.8;
          }
        `
      : css`
          display: flex;
          background-size: cover;
          justify-content: center;
          align-items: center;

          .fas {
            color: gray;
          }
        `}
`
export const searchBarMediaResponsive = css`
  .search-bar {
    box-shadow: 0 10px 18px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0);
  }

  .search-panel {
    position: absolute;
    width: 64vw;
  }

  @media only screen and (max-width: 768px) {
    .search-panel {
      width: 84vw;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .search-panel {
      width: 64vw;
    }
  }

  @media only screen and (min-width: 1023px) {
    .search-panel {
      width: 48vw;
    }
  }
`;
