import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import _ from 'lodash'
import styled from 'styled-components'

import useForm from '../../hooks/useForm'
import { getDefaultLangText } from '../../constants/utils'
import Field from '../../views/widgets/Field'
import InputText from '../../views/widgets/InputText'
import ActionPanelLayout from '../../views/widgets/ActionPanelLayout'
import { stepContentPagePropTypes } from '../../constants/prop-types'

const initialState = values => ({ title: getDefaultLangText(), ...values })
// const initialState = values => ({ title: { th: 'hello' }, ...values })

export default function NamingPage({ initialValues, onAfterValid }) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { bindInput, handleSubmit } = useForm({
    initialState: initialState(initialValues),
    onValidate: ({ values }) => {
      const errors = {}

      const name = `title.${language}`
      const title = _.get(values, name)

      if (_.isEmpty(title)) {
        errors[name] = t('form.require.label', {
          title: t('field.title.label', { defaultValue: 'Title' }),
          defaultValue: 'Require {{title}} field',
        })
      }

      return errors
    },
    onSubmit: onAfterValid,
    reinitialState: true,
  })

  return (
    <NamingPageStyle>
      <h3 className="title is-3 has-text-centered">
        <Trans
          i18nKey="actions.create.label"
          values={{ title: t('app.title.label', { defaultValue: 'Glog' }) }}
          defaults="Create {{title}}"
        />
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <Field
            isHorizontal={false}
            label={`${t(`field.title.label`, {
              defaultValue: 'Title',
            })} [${language}]`}
          >
            <InputText
              {...bindInput(`title.${language}`)}
              placeholder={t('field.title.placeholder', {
                defaultValue: 'Enter your title',
              })}
            />
          </Field>
        </div>
        <ActionPanelLayout>
          <div className="buttons is-hidden-mobile">
            <input
              className="button is-primary"
              type="submit"
              value={t('actions.next.label', { defaultValue: 'Next' })}
            />
          </div>

          <div className="is-hidden-tablet">
            <div className="columns is-gapless is-mobile">
              <div className="column">
                <input
                  className="button is-medium is-primary is-fullwidth"
                  type="submit"
                  value={t('actions.next.label', { defaultValue: 'Next' })}
                />
              </div>
            </div>
          </div>
        </ActionPanelLayout>
      </form>
    </NamingPageStyle>
  )
}

NamingPage.propTypes = {
  ...stepContentPagePropTypes,
}

const NamingPageStyle = styled.div.attrs({ className: '__naming-page' })``
